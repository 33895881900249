import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload, Stack, Typography } from 'libs/ui';

import {
  useFormContext,
  Field,
  MAX_FILES,
} from '../../../forms/onboardingRegistrationAddressForm';

import { RegistrationAddressFields } from './RegistrationAddressFields';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  enabledFiles?: boolean;
  enabledPlaceOfBirth?: boolean;
  enabledRegistrationAddress?: boolean;
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  countriesLoading,
  countries,
  enabledFiles,
  files,
  enabledPlaceOfBirth = true,
  enabledRegistrationAddress = true,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      {enabledPlaceOfBirth && (
        <Stack spacing={24} spacingMobile={16}>
          <Typography variant="subtitle" variantMobile="bodyBold">
            {t('onboarding.registrationAddress.titleOfBirth')}
          </Typography>
          <Field.Autocomplete
            label={t('onboarding.registrationAddress.country.label')}
            loading={countriesLoading}
            name="countryOfBirth"
            optionLabelKey="name"
            options={countries}
            optionValueKey="id"
            placeholder={t(
              'onboarding.registrationAddress.country.placeholder'
            )}
          />
          <Field.Text
            label={t('onboarding.registrationAddress.locality.label')}
            name="localityOfBirth"
            placeholder={t(
              'onboarding.registrationAddress.locality.placeholder'
            )}
          />
        </Stack>
      )}

      {enabledRegistrationAddress && (
        <Stack spacing={24} spacingMobile={16}>
          <Typography variant="subtitle" variantMobile="bodyBold">
            {t('onboarding.registrationAddress.titleRegistration')}
          </Typography>
          <RegistrationAddressFields
            countries={countries}
            countriesLoading={countriesLoading}
            variant="Registration"
          />
        </Stack>
      )}

      <Stack spacing={24} spacingMobile={16}>
        <Stack
          direction="row-exceptMobile"
          flexWrap="wrap"
          justifyContent="space-between"
          spacing={16}
        >
          <Typography variant="subtitle" variantMobile="bodyBold">
            {t('onboarding.registrationAddress.titleResidence')}
          </Typography>
          {enabledRegistrationAddress && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={16}
            >
              <Typography variant="bodyMedium">
                {t('onboarding.registrationAddress.residencelabel')}
              </Typography>
              <Field.Switch name="residentialAddressIsSame" />
            </Stack>
          )}
        </Stack>

        {(!enabledRegistrationAddress || !values.residentialAddressIsSame) && (
          <RegistrationAddressFields
            countries={countries}
            countriesLoading={countriesLoading}
            variant="Residence"
          />
        )}
      </Stack>
      {enabledFiles && (
        <Field.FilesUpload
          files={files}
          fileTypes={[FileType.pdf]}
          maxFiles={MAX_FILES}
          name="proofOfResidenceFileIds"
          title={t('onboarding.registrationAddress.documentTitle')}
          onAddFiles={onAddFiles}
          onRemoveFile={onRemoveFile}
        />
      )}
    </Stack>
  );
};
