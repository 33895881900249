// eslint-disable-next-line no-restricted-imports
import { Menu as MenuBase } from '@mui/material';

import { Stack } from '../Stack';

import { MenuItemStyled } from './Menu.styles';
import { MenuProps } from './types';

/**
 * Menu component.
 */
export const Menu = <T extends string | number>({
  isOpen,
  options,
  anchorEl,
  onClose,
  onClickOption,
  ...popoverProps
}: MenuProps<T>) => (
  <MenuBase
    {...popoverProps}
    anchorEl={anchorEl}
    open={isOpen}
    onBackdropClick={(e) => {
      e.stopPropagation();
    }}
    onClose={onClose}
  >
    {options.map((option) => (
      <MenuItemStyled
        key={option.value}
        color={option.color}
        onClick={(e) => {
          e.stopPropagation();
          onClickOption(option);
        }}
      >
        <Stack alignItems="center" direction="row" spacing={12}>
          {option.Icon && (
            <option.Icon className="menu-item-icon" color="inherit" size={20} />
          )}
          <span className="menu-item-label">{option.label}</span>
        </Stack>
      </MenuItemStyled>
    ))}
  </MenuBase>
);
