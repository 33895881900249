import {
  BusinessRelationshipGoalsType,
  BusinessRelationshipInvestmentGoalType,
} from 'api/types/entity';
import { ClientSaveBusinessRelationshipParam } from 'api/types/params';

import { BusinessRelationshipFormType } from '../../forms/businessRelationshipForm';

export const getSubmitData = (
  values: BusinessRelationshipFormType
): ClientSaveBusinessRelationshipParam => {
  const businessRelationshipGoals = values.businessRelationshipGoals.map(
    (v) => v.value
  );

  const investmentGoals = values.investmentGoals.map((v) => v.value);

  const data: ClientSaveBusinessRelationshipParam = {
    businessRelationshipGoals,
    investmentGoals,

    otherBusinessRelationshipGoal: businessRelationshipGoals.includes(
      BusinessRelationshipGoalsType.Other
    )
      ? values.otherBusinessRelationshipGoal
      : undefined,

    otherInvestmentGoal: investmentGoals.includes(
      BusinessRelationshipInvestmentGoalType.Other
    )
      ? values.otherInvestmentGoal
      : undefined,
  };
  return data;
};
