import { ClientEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { BusinessRelationshipFormType } from '../../forms/businessRelationshipForm';

export const getInitialData = (client: ClientEntity, t: TFunc) => {
  const values: BusinessRelationshipFormType | undefined =
    client.businessRelationship
      ? {
          ...client.businessRelationship,

          businessRelationshipGoals:
            client.businessRelationship.businessRelationshipGoals?.map((v) => ({
              value: v,
              label: t(
                `onboarding.businessRelationship.businessRelationshipGoals.types.${v}`
              ),
            })),

          investmentGoals: client.businessRelationship.investmentGoals?.map(
            (v) => ({
              value: v,
              label: t(
                `onboarding.businessRelationship.investmentGoals.types.${v}`
              ),
            })
          ),
        }
      : undefined;

  return values;
};
