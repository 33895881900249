import { Body } from 'components';
import { GlobalClasses } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Link as LinkNavigation, ROUTES } from 'libs/navigation';
import { Button, Typography, Stack, Link } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { isGlobalProject } from '../../../../../config';
import { useIsMobileSize } from '../../../../../hooks';

import {
  ButtonsStyled,
  FormLayoutStyled,
  HintStyled,
} from './RegistrationSelectType.styles';

interface Props {
  email: string;
  onSelectPersonal?: () => void;
  onSelectLegal?: () => void;
}

export const RegistrationSelectType = ({
  onSelectPersonal,
  onSelectLegal,
  email,
}: Props) => {
  const { t } = useTranslation();

  const isMobileSize = useIsMobileSize();
  const isGlobal = isGlobalProject();

  return (
    <FormLayoutStyled center variant="filled-all">
      <Stack spacing={16}>
        <Body padding="small">
          <Stack alignItems="start">
            <LinkNavigation
              datax="registrationSelectType-backButton"
              params={{}}
              to={ROUTES.login.fullPath}
            >
              <Stack
                alignItems="center"
                className={GlobalClasses.hoveredChild}
                direction="row"
                spacing={4}
              >
                <Icon.ArrowLeft size={20} />
                <Typography variant="bodyBold" variantMobile="captionBold">
                  {t('auth.registration.selectType.goBack')}
                </Typography>
              </Stack>
            </LinkNavigation>
          </Stack>
        </Body>

        <Body className="registrationSelectType-content">
          <Stack spacing={32} spacingMobile={24}>
            <Stack as="header" spacing={20}>
              <Typography
                align="center"
                as="h1"
                datax="registrationSelectType-title"
                variant="title"
                variantMobile="subtitle"
              >
                {t('auth.registration.selectType.title')}
              </Typography>
              <Typography
                align="center"
                as="p"
                datax="registrationSelectType-subtitle"
              >
                {t('auth.registration.selectType.subtitle')}
              </Typography>
            </Stack>
            {isGlobal ? (
              <HintStyled>
                <Stack direction="row" spacing={12}>
                  <Icon.Info
                    className="hint-info"
                    size={isMobileSize ? 24 : 32}
                  />
                  <Typography variant="body" variantMobile="caption">
                    {t('auth.registration.selectType.hint')}{' '}
                    <Link href={`mailto: ${email}`} mode="text">
                      {email}
                    </Link>
                    .
                  </Typography>
                </Stack>
              </HintStyled>
            ) : null}

            <ButtonsStyled>
              <Button
                fullWidth
                datax="registrationSelectType-personalButton"
                label={t('auth.registration.selectType.personalButton')}
                onClick={onSelectPersonal}
              />
              <Button
                fullWidth
                datax="registrationSelectType-legalButton"
                disabled={isGlobal}
                label={t('auth.registration.selectType.legalButton')}
                mode="outlined"
                onClick={onSelectLegal}
              />
            </ButtonsStyled>
          </Stack>
        </Body>
      </Stack>
    </FormLayoutStyled>
  );
};
