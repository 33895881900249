import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OpenAccount_Const,
  useClientGetDocumentGroupQuery,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientDocumentGroupName } from 'api/types/data';
import { ClientDocumentGroupData } from 'api/types/data/clientDocumentGroupData';
import { lodash } from 'helpers';

import { parseFileEntity } from '../helpers';

export const useClientDocumentGroup = (
  id: string,
  documentGroupName: ClientDocumentGroupName
) => {
  const {
    data,
    loading,
    error: apolloError,
  } = useClientGetDocumentGroupQuery({
    variables: { id, documentGroupName: OpenAccount_Const[documentGroupName] },
  });

  const error = useError(apolloError);

  const documentGroup: ClientDocumentGroupData | undefined = useMemo(
    () =>
      data?.documentGroup
        ? {
            id: data?.documentGroup.id,
            name: ClientDocumentGroupName[data?.documentGroup.name],
            files: lodash
              .compact(data?.documentGroup.files)
              .map(parseFileEntity),
          }
        : undefined,
    [data?.documentGroup]
  );

  return {
    documentGroup,
    loading,
    error,
  };
};
