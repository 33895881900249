import { RiskProfile } from 'modules/onboarding/common/components';

import { useRiskProfile } from '../hooks';
import { PageComponentProps } from '../types';

export const RiskProfileStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } = useRiskProfile();
  return (
    <RiskProfile
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
