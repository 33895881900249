import {
  ClientOnboardingStatusType,
  OnboardingProcessStepNameType,
} from 'api/types/entity';
import { LoadingLayout } from 'components';
import { useInterval } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';
import {
  OnboardingLayout,
  OnboardingStatus,
} from 'modules/onboarding/common/components';
import { STATUS_UPDATE_INTERVAL } from 'modules/onboarding/common/constants';
import { useClientOnboardingStatus } from 'modules/onboarding/common/hooks';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { useOnboarding } from '../hooks/useOnboarding';
import { pagesMap } from '../route';

interface Props {
  clientId: string;
}

export const ClientContainer = ({ clientId }: Props) => {
  const {
    currentClient,
    loading,
    refetch: refetchClient,
    totalStepCount,
    onGoBack,
  } = useOnboarding(clientId);

  const { t } = useTranslation();

  usePageTitle(t('onboarding.pageTitle'));

  const onboardingStatus = useClientOnboardingStatus(currentClient, 'client');

  useInterval(
    () => {
      refetchClient();
    },
    onboardingStatus ? STATUS_UPDATE_INTERVAL : null
  );

  if (!currentClient || loading) {
    return <LoadingLayout />;
  }

  if (onboardingStatus) {
    return <OnboardingStatus onboardingStatus={onboardingStatus} />;
  }

  if (
    currentClient.onboarding?.process?.currentStep.name ===
      OnboardingProcessStepNameType.ClientSigningDocuments &&
    currentClient?.onboardingStatus !==
      ClientOnboardingStatusType.SigningDocuments
  ) {
    return <LoadingLayout />;
  }

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={currentClient}
      pagesMap={pagesMap}
      process={currentClient.onboarding?.process!}
      renderChild={({ route }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout
            key={route.pageKey}
            countSteps={totalStepCount}
            currentStepIndex={route.pageNumber}
          >
            <Component {...route.page.componentProps} />
          </OnboardingLayout>
        );
      }}
      onGoBack={onGoBack}
    />
  );
};
