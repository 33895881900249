import { useRef } from 'react';

import { useClientSaveFinancialPosition } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { FinancialPositionFormType } from '../forms/financialPositionForm';
import { getInitialData } from '../helpers/financialPosition/getInitialData';
import { getSubmitData } from '../helpers/financialPosition/getSubmitData';

export const useClientFinancialPosition = (client: ClientEntity) => {
  const {
    error,
    loading: submitLoading,
    saveFinancialPosition: saveFinancialPositionApi,
  } = useClientSaveFinancialPosition();
  const { t } = useTranslation();

  const initialValues = useRef(getInitialData(client, t)).current;

  const saveFinancialPosition = async (values: FinancialPositionFormType) => {
    const submitData = getSubmitData(values);
    await saveFinancialPositionApi(client.id, submitData);
  };

  useServerErrorNotify(error);

  return {
    saveFinancialPosition,
    submitLoading,
    initialValues,
  };
};
