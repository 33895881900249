import { useState } from 'react';

import { AgreementsClassificationType } from 'api/types/entity/agreementsEntity';
import { OnboardingStatusDefault } from 'components';
import { config } from 'config';
import { Agreement } from 'containers/agreement';
import { useTranslation } from 'libs/i18n';
import {
  useNavigate,
  RegistrationState,
  usePageTitle,
  ROUTES,
} from 'libs/navigation';

import { RegistrationStepper } from '../components';
import { RegistrationSelectType } from '../components/RegistrationSelectType';
import { useSteps } from '../hooks/useSteps';
import { RegistrationType } from '../types';

export const RegistrationContainer = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigate<{}, RegistrationState>();

  usePageTitle(t('auth.registration.pageTitle'));

  const {
    initialActiveIndex,
    needAgreements,
    steps,
    registrationType,
    needSelectType,
    hideStepper,
    agreementIds: initialAgreementIds,
    onSelectLegal,
    onSelectPersonal,
    setNeedSelectType,
  } = useSteps();

  const [agreementIds, setAgreementIds] = useState(initialAgreementIds);
  const [rejected, setReject] = useState(false);

  const onGoBack = () => {
    navigate(ROUTES.login.fullPath, {});
  };

  const onEnd = () => {
    // need to do a replace to update the user data
    window.location.replace(ROUTES.onboardingInfo.fullPath);
  };

  if (needSelectType) {
    return (
      <RegistrationSelectType
        email={config.EMAIL_ONBOARDING ?? ''}
        onSelectLegal={onSelectLegal}
        onSelectPersonal={onSelectPersonal}
      />
    );
  }

  if (needAgreements && !agreementIds) {
    return (
      <Agreement
        canGoBack
        classificationType={
          registrationType === RegistrationType.legal
            ? AgreementsClassificationType.Legal
            : AgreementsClassificationType.Offer
        }
        onAgreement={setAgreementIds}
        onGoBack={() => setNeedSelectType(true)}
      />
    );
  }

  if (rejected) {
    return (
      <OnboardingStatusDefault
        email={config.EMAIL_ONBOARDING ?? ''}
        text={t(`onboarding-status.rejected.text`)}
        title={t(`onboarding-status.rejected.title`)}
      />
    );
  }

  return (
    <RegistrationStepper
      agreementIds={agreementIds ?? []}
      hideStepper={hideStepper}
      initialActiveIndex={initialActiveIndex}
      registrationType={registrationType}
      steps={steps}
      onBack={onGoBack}
      onEnd={onEnd}
      onRejected={() => setReject(true)}
    />
  );
};
