import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { DialogProps, ListTypography } from 'libs/ui';

import { ContentStyled } from './styles';

interface Props extends DialogProps {
  loading?: boolean;
  texts: string[];
  onConfirm: () => void;
  onBack: () => void;
}

export const OnboardingConfirmationDataDialog = ({
  texts,
  ...dialogProps
}: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      fullWidth
      confirmButtonLabel={t('onboarding.confirmationDataDialog.confirmButton')}
      maxWidth="lg"
      spacingContent={32}
      spacingMobileContent={16}
      title={t('onboarding.confirmationDataDialog.title')}
      {...dialogProps}
    >
      <ContentStyled>
        <ListTypography
          datax="confirmationDataDialog-text"
          listType="ul"
          spacing={16}
          texts={texts}
          variantMobile="caption"
        />
      </ContentStyled>
    </ConfirmationDialog>
  );
};
