import { OnboardingPepConnectionFormType } from 'modules/onboarding/common/forms/onboardingPepConnectionForm';
import { useClientPepConnection } from 'modules/onboarding/common/hooks';

import { RelationType } from '../../../../api/types/entity';
import { isGlobalProject } from '../../../../config';
import { TranslationPath } from '../../../../libs/i18n';

import { useOnboardingContext } from './useOnboardingContext';

export const ownerPepConnectionRelationPath: Record<
  RelationType,
  TranslationPath
> = {
  [RelationType.not]: 'onboarding.pepConnection.relationNo',
  [RelationType.yesIAm]: isGlobalProject()
    ? 'onboarding.pepConnection.ownerRelationYesIAm'
    : 'onboarding.pepConnection.relationYesIAm',
  [RelationType.yesSpouses]: 'onboarding.pepConnection.relationYesSpouses',
};

export const useOwnerPepConnection = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { initialValues, onSubmit, submitLoading } = useClientPepConnection(
    data.ownerClient!,
    ownerPepConnectionRelationPath
  );

  const handleSubmit = async (values: OnboardingPepConnectionFormType) => {
    if (values) {
      await onSubmit(values);
      goToNext();
    }
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
