import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientRepresentativeController_CreateClient_Input_Gender as GenderApi,
  OnboardingClientRepresentativeDocument,
  useClientRepresentativeCreateClientMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingClientRepresentativeCreateClientParam } from 'api/types/params/onboarding/clientRepresentativeCreateClientParam';
import { formatToISODate } from 'helpers';

export const useClientRepresentativeCreateClient = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientRepresentativeCreateClientMutation({
      refetchQueries: [OnboardingClientRepresentativeDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data,
    loading,
    error,
    createClient: useCallback(
      async (
        id: string,
        params: OnboardingClientRepresentativeCreateClientParam
      ) => {
        const res = await handle({
          variables: {
            id,
            input: {
              ...params,
              gender: params.gender ? GenderApi[params.gender] : undefined,
              dateOfBirth: formatToISODate(params.dateOfBirth),
            },
          },
        });
        return (
          res.data?.clientRepresentativeCreateClient?.clientId || undefined
        );
      },
      [handle]
    ),
  };
};
