import { isGlobalProject } from 'config';
import {
  ClientType,
  OnboardingPepConnection,
} from 'modules/onboarding/common/components';

import { useTranslation } from '../../../../libs/i18n';
import {
  ownerPepConnectionRelationPath,
  useOwnerPepConnection,
} from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerPepConnectionStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } =
    useOwnerPepConnection();

  const { t } = useTranslation();

  return (
    <OnboardingPepConnection
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      customPepConnectionRelationPath={ownerPepConnectionRelationPath}
      initialValues={initialValues}
      notRequestInformation={isGlobalProject()}
      relationLabel={t('onboarding.pepConnection.relation.ownerLabel')}
      submitLoading={submitLoading}
      subTitle={
        isGlobalProject()
          ? t('onboarding.pepConnection.ownerSubtitle')
          : undefined
      }
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
