import { PepConnectionEntity, RelationType } from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { OnboardingPepConnectionFormType } from '../../forms/onboardingPepConnectionForm';

export const pepConnectionRelationPath: PepConnectionRelationPath = {
  [RelationType.not]: 'onboarding.pepConnection.relationNo',
  [RelationType.yesIAm]: 'onboarding.pepConnection.relationYesIAm',
  [RelationType.yesSpouses]: 'onboarding.pepConnection.relationYesSpouses',
};

export type PepConnectionRelationPath = Record<RelationType, TranslationPath>;

export const getInitialData = (
  pepConnection: PepConnectionEntity | undefined,
  t: TFunc,
  customPepConnectionRelationPath?: PepConnectionRelationPath
) => {
  const values: OnboardingPepConnectionFormType = {
    ...pepConnection,
    relation: pepConnection?.relation
      ? {
          value: pepConnection?.relation,
          label: t(
            customPepConnectionRelationPath
              ? customPepConnectionRelationPath[pepConnection.relation]
              : pepConnectionRelationPath[pepConnection.relation]
          ),
        }
      : null,
  };

  return values;
};
