import {
  ClientType,
  SourcesOfIncome,
} from 'modules/onboarding/common/components';

import { isGlobalProject } from '../../../../config';
import { useTranslation } from '../../../../libs/i18n';
import { useOwnerSourcesOfIncome } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerSourcesOfIncomeStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, submitLoading, onSubmit, goToBack } =
    useOwnerSourcesOfIncome();

  const { t } = useTranslation();

  return (
    <SourcesOfIncome
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={
        isGlobalProject()
          ? t('onboarding.sourcesOfIncome.ownerSubtitle')
          : undefined
      }
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
