import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  array,
  string,
} from 'libs/form';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export const MAX_FILES = 10;

export interface W8BenFormFormType {
  isUSSecuritiesInvestmentPlanned?: YesOrNoSelectType;
  fileIds?: string[];
}

const isPlanned = (v?: YesOrNoSelectType) => v?.value === YesOrNoType.Yes;

export const getSchema = (): ObjectSchema<W8BenFormFormType> =>
  object({
    isUSSecuritiesInvestmentPlanned: yesOrNotSelectValidation
      .default(null)
      .required(),
    fileIds: array()
      .of(string().required())
      .when('isUSSecuritiesInvestmentPlanned', {
        is: isPlanned,
        then: (schema) => schema.required().min(1).max(MAX_FILES),
      }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<W8BenFormFormType>();

export type FormErrors = FormErrorsBase<W8BenFormFormType>;
