import { lodash } from 'helpers';

import {
  OnboardingTaxResidencyFormTaxValue,
  OnboardingTaxResidencyFormType,
} from '../../forms/onboardingTaxResidencyForm';

const parseTaxValues = (v: OnboardingTaxResidencyFormTaxValue) => ({
  taxId: !v.taxNumberIsMissing ? v.taxNumber : undefined,
  country: v.country?.id!,
  reasonForMissingTaxId: v.taxNumberIsMissing ? v.reasonForAbsence : undefined,
});

export const getSubmitData = (values: OnboardingTaxResidencyFormType) => {
  const result = {
    taxResidences: lodash.compact(values.taxValues.map(parseTaxValues)),
  };

  return result;
};

export const getSubmitDataWithFiles = (
  values: OnboardingTaxResidencyFormType
) => {
  const result = {
    taxResidences: lodash.compact(
      values.taxValues.map((v) => ({
        ...parseTaxValues(v),
        fileIds: v.fileIds,
      }))
    ),
  };

  return result;
};
