import { useRef } from 'react';

import { useClientSaveTaxResidences } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { isGlobalProject } from 'config';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { OnboardingTaxResidencyFormType } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import {
  getInitialData,
  getSubmitDataWithFiles,
  getSubmitData,
} from '../helpers/taxResidency';

export const useClientTaxResidency = (client: ClientEntity) => {
  const { saveTaxResidences, error, loading } = useClientSaveTaxResidences();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialData = useRef(getInitialData(client.taxResidences)).current;
  const initialValues = useRef(initialData.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const onSubmit = async (values: OnboardingTaxResidencyFormType) => {
    if (values.taxValues) {
      const data = isGlobalProject()
        ? getSubmitDataWithFiles(values)
        : getSubmitData(values);
      await saveTaxResidences(client.id, data);
    }
  };
  useServerErrorNotify(error);

  return {
    onSubmit,
    onAddFiles,
    onRemoveFile,
    files,
    submitDisabled,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
