import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingClientDocument,
  useClientSignDocumentGroupMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useClientSignDocumentGroup = () => {
  const [handle, { loading, error: apolloError }] =
    useClientSignDocumentGroupMutation({
      refetchQueries: [OnboardingClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    signDocumentGroup: useCallback(
      async (clientId: string, documentGroupId: string, otpCode: string) => {
        const res = await handle({
          variables: { id: clientId, otpCode, documentGroupId },
        });

        return !!res.data?.ClientController_signDocumentGroup;
      },
      [handle]
    ),
  };
};
