import { CountryEntity } from 'api/types/entity';

import { OnboardingRegistrationAddressFormType } from '../../../forms/onboardingRegistrationAddressForm';
import { AddressesFields } from '../../AddressesFields';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  variant: 'Registration' | 'Residence';
}

export const RegistrationAddressFields = ({
  countriesLoading,
  countries,
  variant,
}: Props) => {
  const countryName: keyof OnboardingRegistrationAddressFormType = `country${variant}`;
  const regionName: keyof OnboardingRegistrationAddressFormType = `region${variant}`;
  const localityName: keyof OnboardingRegistrationAddressFormType = `locality${variant}`;
  const areaName: keyof OnboardingRegistrationAddressFormType = `area${variant}`;
  const streetName: keyof OnboardingRegistrationAddressFormType = `street${variant}`;
  const houseNumberName: keyof OnboardingRegistrationAddressFormType = `houseNumber${variant}`;
  const housingName: keyof OnboardingRegistrationAddressFormType = `housing${variant}`;
  const apartmentName: keyof OnboardingRegistrationAddressFormType = `apartment${variant}`;
  const postcodeName: keyof OnboardingRegistrationAddressFormType = `postcode${variant}`;

  return (
    <AddressesFields<OnboardingRegistrationAddressFormType>
      apartmentName={apartmentName}
      areaName={areaName}
      countries={countries}
      countriesLoading={countriesLoading}
      countryName={countryName}
      houseNumberName={houseNumberName}
      housingName={housingName}
      localityName={localityName}
      postcodeName={postcodeName}
      regionName={regionName}
      streetName={streetName}
    />
  );
};
