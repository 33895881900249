import { Table, TableSetRowHref } from 'libs/ui';

import { useColumns } from './columns';
import { UserItem, UserOptionType } from './types';

interface Props {
  items: UserItem[];
  onClickEdit?: (row: UserItem) => void;
  onClickResend?: (row: UserItem) => void;
  setRowHref?: TableSetRowHref<UserItem>;
}

export const UsersTable = ({
  items,
  onClickEdit,
  onClickResend,
  setRowHref,
}: Props) => {
  const onClickOption = (item: UserItem, option: UserOptionType) => {
    switch (option) {
      case UserOptionType.edit:
        if (item.id) {
          onClickEdit?.(item);
        }
        break;
      case UserOptionType.resend:
        if (item.id) {
          onClickResend?.(item);
        }
        break;
      default:
        break;
    }
  };

  const columns = useColumns({ onClickOption });

  return (
    <Table hideFooter columns={columns} rows={items} setRowHref={setRowHref} />
  );
};
