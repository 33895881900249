import { FinancialPositionFormType } from 'modules/onboarding/common/forms/financialPositionForm';
import { useClientFinancialPosition } from 'modules/onboarding/common/hooks/useClientFinancialPosition';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerFinancialPosition = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { initialValues, saveFinancialPosition, submitLoading } =
    useClientFinancialPosition(data.ownerClient!);

  const onSubmit = async (values: FinancialPositionFormType) => {
    if (values) {
      await saveFinancialPosition(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    submitLoading,
    initialValues,
  };
};
