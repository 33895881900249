import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveBusinessRelationshipMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientSaveBusinessRelationshipParam } from 'api/types/params';

import {
  parseClientEntity,
  parseSaveBusinessRelationshipParam,
} from '../helpers';

export const useClientSaveBusinessRelationship = () => {
  const [saveBusinessRelationship, { data, loading, error: apolloError }] =
    useClientSaveBusinessRelationshipMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveBusinessRelationship
      ? parseClientEntity(data.clientSaveBusinessRelationship)
      : undefined,
    loading,
    error,
    saveBusinessRelationship: useCallback(
      async (id: string, params: ClientSaveBusinessRelationshipParam) => {
        const res = await saveBusinessRelationship({
          variables: {
            id,
            input: parseSaveBusinessRelationshipParam(params),
          },
        });

        return res.data?.clientSaveBusinessRelationship
          ? parseClientEntity(res.data.clientSaveBusinessRelationship)
          : undefined;
      },
      [saveBusinessRelationship]
    ),
  };
};
