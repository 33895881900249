import { InputHTMLAttributes, useCallback } from 'react';

import { OrderSubmissionContactType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import {
  getPlaceholderByType,
  getTypes,
  useFormContext,
  Field,
  OrderSubmissionContactFormTypeOption,
} from 'modules/onboarding/common/forms/orderSubmissionContacts';

import { ContactTypeFieldWrapper } from './Fields.styles';

interface Props {
  index: number;
}

export const Fields = ({ index }: Props) => {
  const { values, setValues } = useFormContext();
  const { t } = useTranslation();

  const valueIsPhone =
    values.orderSubmissionContacts[index].type?.value ===
      OrderSubmissionContactType.Phone ||
    values.orderSubmissionContacts[index].type?.value ===
      OrderSubmissionContactType.WhatsApp;
  const valueIsEmail =
    values.orderSubmissionContacts[index].type?.value ===
    OrderSubmissionContactType.Email;

  const ValueFieldComp = valueIsPhone ? Field.Phone : Field.Text;

  let valueFieldType: InputHTMLAttributes<unknown>['type'] = 'text';
  if (valueIsPhone) {
    valueFieldType = 'phone';
  } else if (valueIsEmail) {
    valueFieldType = 'email';
  }

  const onChangeType = useCallback(
    (value: object | object[] | null) => {
      setValues({
        orderSubmissionContacts: values.orderSubmissionContacts.map(
          (item, idx) => {
            if (idx === index) {
              return {
                ...item,
                type: value as OrderSubmissionContactFormTypeOption,
                value: '',
              };
            }
            return item;
          }
        ),
      });
    },
    [index, setValues, values.orderSubmissionContacts]
  );

  return (
    <>
      <ContactTypeFieldWrapper>
        <Field.Autocomplete
          hideClearIcon
          label={t('onboarding.orderSubmissionContacts.contact.label')}
          name={
            `orderSubmissionContacts.${index}.type` as 'orderSubmissionContacts'
          }
          optionLabelKey="label"
          options={getTypes(t)}
          optionValueKey="value"
          onChange={onChangeType}
        />
      </ContactTypeFieldWrapper>

      <ValueFieldComp
        fullWidth
        helperTextAbsolute
        maxLength={valueIsPhone ? 22 : 200}
        name={
          `orderSubmissionContacts.${index}.value` as 'orderSubmissionContacts'
        }
        placeholder={getPlaceholderByType(
          t,
          values.orderSubmissionContacts[index].type.value
        )}
        type={valueFieldType}
      />
    </>
  );
};
