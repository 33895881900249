import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const ArrowFilledDown = ({ size = 16, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;

  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66666 6.66667L8 10L11.3333 6.66667L4.66666 6.66667Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
