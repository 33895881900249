import { FinancialPosition } from 'modules/onboarding/common/components';

import { useFinancialPosition } from '../hooks';
import { PageComponentProps } from '../types';

export const FinancialPositionStep = ({ canGoBack }: PageComponentProps) => {
  const { onSubmit, goToBack, initialValues, submitLoading } =
    useFinancialPosition();
  return (
    <FinancialPosition
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
