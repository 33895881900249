import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveSourcesOfIncomeMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientSaveSourcesOfIncomeParam } from 'api/types/params';

import { parseClientEntity, parseSaveSourcesOfIncomeParam } from '../helpers';

export const useClientSaveSourcesOfIncome = () => {
  const [saveSourcesOfIncome, { data, loading, error: apolloError }] =
    useClientSaveSourcesOfIncomeMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveSourcesOfIncome
      ? parseClientEntity(data.clientSaveSourcesOfIncome)
      : undefined,
    loading,
    error,
    saveSourcesOfIncome: useCallback(
      async (id: string, params: ClientSaveSourcesOfIncomeParam) => {
        const res = await saveSourcesOfIncome({
          variables: {
            id,
            input: parseSaveSourcesOfIncomeParam(params),
          },
        });

        return res.data?.clientSaveSourcesOfIncome
          ? parseClientEntity(res.data.clientSaveSourcesOfIncome)
          : undefined;
      },
      [saveSourcesOfIncome]
    ),
  };
};
