import { Pressed } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { styled } from 'libs/ui/styled';

export const Pressedtyled = styled(Pressed)(() => ({
  padding: 6,
}));

export const IconStyled = styled(Icon.More)<{
  variant?: 'horizontal' | 'vertical';
}>(({ theme, variant }) => ({
  transform: variant === 'horizontal' ? 'rotate(90deg);' : undefined,

  ':hover': {
    color: theme.palette.hover,
  },
}));
