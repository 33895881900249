import { W8BenFormEntity } from 'api/types/entity';
import { lodash, yesOrNot } from 'helpers';
import { FileUpload } from 'hooks';
import { TFunc } from 'libs/i18n';

import { W8BenFormFormType } from '../../forms/w8BenFormForm';

export const getInitialData = (t: TFunc, w8BenForm?: W8BenFormEntity) => {
  const values: W8BenFormFormType = {
    isUSSecuritiesInvestmentPlanned: yesOrNot(
      t,
      w8BenForm?.isUSSecuritiesInvestmentPlanned
    ),
    fileIds: w8BenForm?.files.map((v) => v.id),
  };

  const files: FileUpload[] | undefined = lodash.compact(
    w8BenForm?.files?.map(
      (file) =>
        new FileUpload(
          {
            name: file.name,
            size: file.size,
          },
          undefined,
          false,
          file.id
        )
    )
  );

  return { values, files };
};
