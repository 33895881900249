export enum WaitingKycUserStatusType {
  notStarted = 'notStarted',
  rejected = 'rejected',
  success = 'success',
  progress = 'progress',
}

export interface WaitingKycUserType {
  id: string;
  status: WaitingKycUserStatusType;
  name: string;
  email: string;
}
