import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { DownloadContent } from 'modules/onboarding/common/components/DownloadContent';

import {
  SigningQuestionnaireFormType,
  FormErrors,
  getSchema,
} from '../../forms/signingQuestionnaireForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps {
  initialValues?: SigningQuestionnaireFormType;
  canGoBack?: boolean;
  formError?: FormErrors;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  questionnaireFileHref: string;
  onSubmit: (values: SigningQuestionnaireFormType) => void;
  onGoBack?: () => void;
}

export const SigningQuestionnaire = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  submitDisabled,
  formError,
  onSubmit,
  questionnaireFileHref,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.signingQuestionnaire.subtitle')}
      title={t('onboarding.signingQuestionnaire.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <DownloadContent
          href={questionnaireFileHref}
          title={t('onboarding.signingQuestionnaire.downloadDocument')}
        />
        <FormContent {...formProps} />
      </Stack>
    </FormContainer>
  );
};
