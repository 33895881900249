import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveKnowledgeAndExperienceMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientSaveKnowledgeAndExperienceParam } from 'api/types/params/onboarding/clientSaveKnowledgeAndExperienceParam';

import { parseClientEntity } from '../helpers';
import { parseKnowledgeAndExperienceParam } from '../helpers/parseKnowledgeAndExperienceParam';

export const useClientSaveKnowledgeAndExperience = () => {
  const [saveKnowledgeAndExperience, { data, loading, error: apolloError }] =
    useClientSaveKnowledgeAndExperienceMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveKnowledgeAndExperience
      ? parseClientEntity(data.clientSaveKnowledgeAndExperience)
      : undefined,
    loading,
    error,
    saveKnowledgeAndExperience: useCallback(
      async (id: string, params: ClientSaveKnowledgeAndExperienceParam) => {
        const input = parseKnowledgeAndExperienceParam(params);
        const res = await saveKnowledgeAndExperience({
          variables: { id, input },
        });
        return res.data?.clientSaveKnowledgeAndExperience
          ? parseClientEntity(res.data.clientSaveKnowledgeAndExperience)
          : undefined;
      },
      [saveKnowledgeAndExperience]
    ),
  };
};
