import { useEffect } from 'react';

import { useTranslation } from 'libs/i18n';

import {
  Field,
  FormErrors,
  useFormContext,
} from '../../../forms/registrationTwoFactorForm';

interface Props {
  formError: FormErrors;
}

export const FormContent = ({ formError }: Props) => {
  const { t } = useTranslation();

  const { setErrors } = useFormContext();

  useEffect(() => {
    setErrors(formError);
  }, [formError, setErrors]);

  return (
    <Field.Number
      allowSubmit
      fullWidth
      helperTextAbsolute
      label={t('auth.registration.code.label')}
      name="code"
      placeholder={t('auth.registration.code.placeholder')}
    />
  );
};
