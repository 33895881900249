import { DetailedHTMLProps } from 'react';

import { LinkLabel } from 'libs/ui';
// eslint-disable-next-line no-restricted-imports
import MarkdownBase from 'react-markdown';

import { UnorderedListStyled } from './Markdown.styles';
import { MarkdownProps } from './types';

const Link = ({
  children,
  href,
}: DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => <LinkLabel href={href} label={children as string} target="_blank" />;

const UnorderedList = ({
  children,
}: DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>) => <UnorderedListStyled>{children}</UnorderedListStyled>;

/**
 * Markdown component.
 */
export const Markdown = (props: MarkdownProps) => (
  <MarkdownBase components={{ a: Link, ul: UnorderedList }} {...props} />
);
