import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload } from 'libs/ui';
import { YesOrNoType } from 'types/yesOrNo';

import { Field, MAX_FILES, useFormContext } from '../../../forms/w8BenFormForm';

export interface FormContentProps {
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload, index: number) => void;
}

export const FormContent = ({
  files,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { t } = useTranslation();

  const { values } = useFormContext();

  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.W8BENForm.field.label')}
        name="isUSSecuritiesInvestmentPlanned"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
      {values.isUSSecuritiesInvestmentPlanned?.value === YesOrNoType.Yes && (
        <Field.FilesUpload
          files={files}
          fileTypes={[FileType.pdf]}
          maxFiles={MAX_FILES}
          name="fileIds"
          title={t('onboarding.W8BENForm.documentTitle')}
          onAddFiles={onAddFiles}
          onRemoveFile={onRemoveFile}
        />
      )}
    </>
  );
};
