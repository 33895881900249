import { WaitingKycUserType } from '../types';

export interface UserItem extends WaitingKycUserType {}

export enum UserOptionType {
  edit,
  resend,
}

export interface UserColumnArgs {
  onClickOption: (item: UserItem, option: UserOptionType) => void;
}
