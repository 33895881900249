import { RelationType } from 'api/types/entity';
import { isValidName } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export interface OnboardingPepConnectionFormType {
  relation: Maybe<SelectType<RelationType>>;

  firstname?: string;
  lastname?: string;
  surname?: string;

  workName?: string;
  position?: string;
}

export const getSchema = (
  t: TFunc,
  { notRequestInformation }: { notRequestInformation?: boolean }
): ObjectSchema<OnboardingPepConnectionFormType> => {
  const isYes = (v: SelectType<RelationType>) =>
    !notRequestInformation && v?.value === RelationType.yesSpouses;

  return object({
    relation: object({
      value: mixed<RelationType>()
        .oneOf(Object.values(RelationType))
        .required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    firstname: string().when('relation', {
      is: isYes,
      then: (schema) =>
        schema
          .max(20)
          .required()
          .test(
            'ischars',
            t('validation.noSpecialCharsAndNumber'),
            isValidName
          ),
    }),

    lastname: string().when('relation', {
      is: isYes,
      then: (schema) =>
        schema
          .max(20)
          .required()
          .test(
            'ischars',
            t('validation.noSpecialCharsAndNumber'),
            isValidName
          ),
    }),

    surname: string().when('relation', {
      is: isYes,
      then: (schema) =>
        schema
          .max(20)
          .test(
            'ischars',
            t('validation.noSpecialCharsAndNumber'),
            isValidName
          ),
    }),
    workName: string().when('relation', {
      is: isYes,
      then: (schema) => schema.max(2000).required(),
    }),

    position: string().when('relation', {
      is: isYes,
      then: (schema) => schema.max(2000).required(),
    }),
  });
};

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OnboardingPepConnectionFormType>();

export type FormErrors = FormErrorsBase<OnboardingPepConnectionFormType>;
