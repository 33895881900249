import { KnowledgeAndExperienceFormType } from 'modules/onboarding/common/forms/knowledgeAndExperienceForm';
import { useClientKnowledgeAndExperience } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useKnowledgeAndExperience = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { initialValues, saveKnowledgeAndExperience, submitLoading } =
    useClientKnowledgeAndExperience(data);

  const onSubmit = async (values: KnowledgeAndExperienceFormType) => {
    if (values) {
      await saveKnowledgeAndExperience(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    submitLoading,
    initialValues,
  };
};
