import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientGetQuestionnaireFileQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseFileEntity } from '../helpers';

export const useLegalClientGetQuestionnaireFile = (legalClientId: string) => {
  const {
    data,
    loading,
    error: apolloError,
  } = useLegalClientGetQuestionnaireFileQuery({
    variables: { id: legalClientId },
  });

  const error = useError(apolloError);

  const questionnaireFile = useMemo(
    () =>
      data?.questionnaireFile ? parseFileEntity(data.questionnaireFile) : null,
    [data?.questionnaireFile]
  );

  return {
    questionnaireFile,
    loading,
    error,
  };
};
