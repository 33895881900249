import {
  ClientType,
  OnboardingCitizenship,
} from 'modules/onboarding/common/components';

import { isGlobalProject } from '../../../../config';
import { useTranslation } from '../../../../libs/i18n';
import { useOwnerCitizenship } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerCitizenshipStep = ({ canGoBack }: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    goToBack,
    initialValues,
    onSubmit,
    submitLoading,
  } = useOwnerCitizenship();

  const { t } = useTranslation();

  return (
    <OnboardingCitizenship
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={
        isGlobalProject()
          ? t('onboarding.citizenship.ownerSubtitle')
          : undefined
      }
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
