import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveInvoicePaymentConfirmationFilesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientEntity } from '../helpers';

export const useClientSaveInvoicePaymentConfirmationFiles = () => {
  const [saveInvoice, { data, loading, error: apolloError }] =
    useClientSaveInvoicePaymentConfirmationFilesMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveInvoicePaymentConfirmationFiles
      ? parseClientEntity(data.clientSaveInvoicePaymentConfirmationFiles)
      : undefined,
    loading,
    error,
    saveInvoicePaymentConfirmationFiles: useCallback(
      async (id: string, fileIds: string[]) => {
        const res = await saveInvoice({
          variables: {
            id,
            fileIds,
          },
        });
        return res?.data?.clientSaveInvoicePaymentConfirmationFiles
          ? parseClientEntity(
              res.data.clientSaveInvoicePaymentConfirmationFiles
            )
          : undefined;
      },
      [saveInvoice]
    ),
  };
};
