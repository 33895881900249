// eslint-disable-next-line no-restricted-imports
import {
  ClientBusinessRelationshipDto_Input,
  Query_ClientController_GetById_BusinessRelationship_BusinessRelationshipGoals_Items as BusinessRelationshipGoalsApi,
  Query_ClientController_GetById_BusinessRelationship_InvestmentGoals_Items as InvestmentGoalsApi,
} from 'api/generated/graphql';
import { ClientSaveBusinessRelationshipParam } from 'api/types/params';

export const parseSaveBusinessRelationshipParam = (
  data: ClientSaveBusinessRelationshipParam
): ClientBusinessRelationshipDto_Input => ({
  businessRelationshipGoals: data.businessRelationshipGoals.map(
    (goal) => BusinessRelationshipGoalsApi[goal]
  ),
  investmentGoals: data.investmentGoals.map((goal) => InvestmentGoalsApi[goal]),
  otherBusinessRelationshipGoal: data.otherBusinessRelationshipGoal,
  otherInvestmentGoal: data.otherInvestmentGoal,
});
