import { MoreMenu } from 'components/MoreMenu';
import { lodash } from 'helpers';
import { TFunc } from 'libs/i18n';
import { Icon } from 'libs/ui/Icon';

import { WaitingKycUserStatusType } from '../../types';
import { UserItem, UserOptionType } from '../types';

export const renderMore = (
  t: TFunc,
  item: UserItem,
  onClickOption: (item: UserItem, option: UserOptionType) => void
) =>
  item.status !== WaitingKycUserStatusType.notStarted ? null : (
    <MoreMenu
      options={lodash.compact([
        {
          value: UserOptionType.edit,
          label: t('common.edit'),
          Icon: Icon.Edit,
        },
        {
          value: UserOptionType.resend,
          label: t('common.resendEmail'),
          Icon: Icon.ArrowRepeat,
        },
      ])}
      variant="horizontal"
      onClickOption={(option) => onClickOption(item, option.value)}
    />
  );
