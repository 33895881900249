import { useRef } from 'react';

import { useLegalClientSaveBusinessRelationship } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { CompanyBusinessRelationshipFormType } from '../forms/companyBusinessRelationshipForm';
import {
  getInitialData,
  getSubmitData,
} from '../helpers/companyBusinessRrelationship';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyBusinessRelationship = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveBusinessRelations } =
    useLegalClientSaveBusinessRelationship();

  const { t } = useTranslation();

  const initialValues = useRef(
    getInitialData(t, data.legalClient!.businessRelationship)
  ).current;

  const onSubmit = async (values: CompanyBusinessRelationshipFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveBusinessRelations(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
  };
};
