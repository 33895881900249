import { useRef } from 'react';

import { useClientSaveKnowledgeAndExperience } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';

import { KnowledgeAndExperienceFormType } from '../forms/knowledgeAndExperienceForm';
import {
  getInitialData,
  getSubmitData,
} from '../helpers/knowledgeAndExperience';

export const useClientKnowledgeAndExperience = (client: ClientEntity) => {
  const { t } = useTranslation();

  const {
    error,
    loading: submitLoading,
    saveKnowledgeAndExperience: saveFinancialPositionApi,
  } = useClientSaveKnowledgeAndExperience();

  const initialValues = useRef(
    getInitialData(t, client.knowledgeAndExperience)
  ).current;

  const saveKnowledgeAndExperience = async (
    values: KnowledgeAndExperienceFormType
  ) => {
    const submitData = getSubmitData(values);
    await saveFinancialPositionApi(client.id, submitData);
  };

  return {
    initialValues,
    submitLoading,
    error,
    saveKnowledgeAndExperience,
  };
};
