import {
  ClientType,
  FinancialPosition,
} from 'modules/onboarding/common/components';

import { isGlobalProject } from '../../../../config';
import { useTranslation } from '../../../../libs/i18n';
import { useOwnerFinancialPosition } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerFinancialPositionStep = ({
  canGoBack,
}: PageComponentProps) => {
  const { onSubmit, goToBack, initialValues, submitLoading } =
    useOwnerFinancialPosition();

  const { t } = useTranslation();

  return (
    <FinancialPosition
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={
        isGlobalProject()
          ? t('onboarding.financialPosition.ownerSubtitle')
          : undefined
      }
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
