import { W8BenFormFormType } from 'modules/onboarding/common/forms/w8BenFormForm';
import { useClientW8BenForm } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerW8BenForm = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    submitDisabled,
    files,
    onSubmit,
    onAddFiles,
    onRemoveFile,
  } = useClientW8BenForm(data.ownerClient!);

  const handleSubmit = async (values: W8BenFormFormType) => {
    await onSubmit(values);
    goToNext();
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    onAddFiles,
    onRemoveFile,
    files,
    submitLoading,
    submitDisabled,
    initialValues,
    countries,
    countriesLoading,
  };
};
