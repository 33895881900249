import { CountryEntity } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  boolean,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface CompanyAddressFormType {
  countryLegal: Maybe<CountryEntity>;
  localityLegal: string;
  regionLegal: string;
  areaLegal?: string;
  streetLegal: string;
  houseNumberLegal: string;
  housingLegal?: string;
  apartmentLegal?: string;
  postcodeLegal: string;

  countryActual?: Maybe<CountryEntity>;
  localityActual?: string;
  regionActual?: string;
  areaActual?: string;
  streetActual?: string;
  houseNumberActual?: string;
  housingActual?: string;
  apartmentActual?: string;
  postcodeActual?: string;

  actualAddressIsSame: boolean;
}

export const MAX_FILES = 10;

export const getSchema = (): ObjectSchema<CompanyAddressFormType> =>
  object({
    countryLegal: object({
      id: string().required(),
      name: string().required(),
    })
      .required()
      .default(null),
    localityLegal: string().required().max(200),
    regionLegal: string().required().max(200),
    areaLegal: string().max(200),
    streetLegal: string().required().max(200),
    houseNumberLegal: string().required().max(200),
    housingLegal: string().max(200),
    apartmentLegal: string().max(200),
    postcodeLegal: string().required().max(200),

    actualAddressIsSame: boolean().default(false),

    countryActual: object({
      id: string().required(),
      name: string().required(),
    })
      .when('actualAddressIsSame', {
        is: false,
        then: (schema) => schema.required(),
      })
      .default(null)
      .nullable(),

    localityActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.required().max(200),
    }),
    regionActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.required().max(200),
    }),
    areaActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.max(200),
    }),
    streetActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.required().max(200),
    }),
    houseNumberActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.required().max(200),
    }),
    housingActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.max(200),
    }),
    apartmentActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.max(200),
    }),
    postcodeActual: string().when('actualAddressIsSame', {
      is: false,
      then: (schema) => schema.required().max(200),
    }),
  });

export type FormErrors = FormErrorsBase<CompanyAddressFormType>;

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyAddressFormType>();
