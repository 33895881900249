import { useRef } from 'react';

import { useClientSaveBusinessRelationship } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { BusinessRelationshipFormType } from '../forms/businessRelationshipForm';
import { getInitialData } from '../helpers/businessRelationship/getInitialData';
import { getSubmitData } from '../helpers/businessRelationship/getSubmitData';

export const useClientBusinessRelationship = (client: ClientEntity) => {
  const {
    error,
    loading: submitLoading,
    saveBusinessRelationship: saveBusinessRelationshipApi,
  } = useClientSaveBusinessRelationship();
  const { t } = useTranslation();

  const initialValues = useRef(getInitialData(client, t)).current;

  const saveBusinessRelationship = async (
    values: BusinessRelationshipFormType
  ) => {
    const submitData = getSubmitData(values);
    await saveBusinessRelationshipApi(client.id, submitData);
  };

  useServerErrorNotify(error);

  return {
    saveBusinessRelationship,
    submitLoading,
    initialValues,
  };
};
