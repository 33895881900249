import { CompanyBusinessRelationship } from '../components/CompanyBusinessRelationship';
import { useCompanyBusinessRelationship } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyBusinessRelationshipStep = ({
  canGoBack,
}: PageComponentProps) => {
  const { initialValues, onSubmit, submitLoading, goToBack } =
    useCompanyBusinessRelationship();

  return (
    <CompanyBusinessRelationship
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
