import { useClientRiskProfile } from 'modules/onboarding/common/hooks';

import { RiskProfileFormType } from '../../common/forms/riskProfileForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerRiskProfile = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { initialValues, saveRiskProfile, submitLoading } =
    useClientRiskProfile(data.ownerClient!);

  const onSubmit = async (values: RiskProfileFormType) => {
    if (values) {
      await saveRiskProfile(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
