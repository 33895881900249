import { AmountField } from './AmountField';
import { AutocompleteField } from './AutocompleteField';
import { CheckboxField } from './CheckboxField';
import { DatePickerField } from './DatePickerField';
import { FilesUploadField } from './FilesUploadField';
import { NumberField } from './NumberField';
import { PasswordField } from './PasswordField';
import { PhoneField } from './PhoneField';
import { PriceField } from './PriceField';
import { SwitchField } from './SwitchField';
import { TextField } from './TextField';
import { VerificationCodeField } from './VerificationCodeField';

export const fields = {
  Text: TextField,
  Number: NumberField,
  Switch: SwitchField,
  DatePicker: DatePickerField,
  Phone: PhoneField,
  Password: PasswordField,
  Checkbox: CheckboxField,
  Autocomplete: AutocompleteField,
  FilesUpload: FilesUploadField,
  Price: PriceField,
  VerificationCode: VerificationCodeField,
  Amount: AmountField,
};
