import { InvoiceForPaymentFormType } from 'modules/onboarding/common/forms/invoiceForPayment';

import { useClientInvoiceForPayment } from '../../common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerInvoiceForPayment = () => {
  const { data, goToBack, goToNext } = useOnboardingContext();

  const { initialValues, saveClientInvoice, submitLoading } =
    useClientInvoiceForPayment(data.ownerClient!);

  const onSubmit = async (values: InvoiceForPaymentFormType) => {
    if (values.currency) {
      await saveClientInvoice(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
