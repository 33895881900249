// eslint-disable-next-line no-restricted-imports
import { Pagination } from '@mui/material';
import { getColor } from 'helpers';

import { CSSObject, styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

export const PaginationStyled = styled(Pagination)(({ theme }) => ({
  '.MuiPaginationItem-outlined': {
    borderRadius: 8,
    border: `1px solid ${getColor(theme, {
      dark: ColorsDark.gray100,
      light: ColorsLight.gray150,
    })}`,
    background: getColor(theme, {
      dark: ColorsDark.gray50,
      light: ColorsLight.gray50,
    }),

    height: 32,
    width: 32,
    color: theme.palette.text.primary,
    fontSize: 14,

    '&.Mui-selected': {
      background: getColor(theme, {
        dark: ColorsDark.blue50,
        light: ColorsLight.blue50,
      }),
      color: getColor(theme, {
        dark: ColorsDark.blue150,
        light: ColorsLight.blue150,
      }),
      fontWeight: 700,
    },
  } as CSSObject,

  '.MuiPaginationItem-ellipsis': {
    paddingTop: 6,
  },
}));
