import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const ArrowRepeat = ({ size = 24, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        color={color}
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.125 4.16797H5.83333C3.99238 4.16797 2.5 5.66035 2.5 7.5013V12.5013C2.5 14.3423 3.99238 15.8346 5.83333 15.8346H14.1667C16.0076 15.8346 17.5 14.3423 17.5 12.5013V7.5013C17.5 5.66035 16.0076 4.16797 14.1667 4.16797H13.2812M6.66667 6.66797L8.57741 4.75722C8.90285 4.43179 8.90285 3.90415 8.57741 3.57871L6.66667 1.66797"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </svg>
    </SvgIcon>
  );
};
