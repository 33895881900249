import { useEffect } from 'react';

import {
  useClientDocumentGroup,
  useClientSignDocumentGroup,
  useFilePreview,
} from 'api/requests';
import { ClientDocumentGroupName } from 'api/types/data';
import { ClientEntity } from 'api/types/entity';
import { ErrorCode } from 'api/types/error';
import { openLink } from 'helpers';
import { useObjectState } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import {
  SigningDocumentsFormType,
  FormErrors,
} from 'modules/onboarding/common/forms/signingDocumentsForm';

type SigningDocumentsFileTypeName =
  | 'BrokerageAgreementApplication'
  | 'OpenSubAccountOrder';

export const useClientSigningDocuments = (client: ClientEntity) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { getFileLink } = useFilePreview();

  const { documentGroup, loading } = useClientDocumentGroup(
    client.id,
    ClientDocumentGroupName.OpenAccount
  );

  const {
    signDocumentGroup,
    loading: submitLoading,
    error: submitError,
  } = useClientSignDocumentGroup();

  const [formError, setFormError] = useObjectState<FormErrors>({});

  const documents = documentGroup?.files.map(
    (f) => f.type as SigningDocumentsFileTypeName
  );

  const onClickDocument = async (type: SigningDocumentsFileTypeName) => {
    if (type) {
      const doc = documentGroup!.files.find((t) => t.type === type);

      if (doc) {
        const link = await getFileLink(doc.id);
        if (link) {
          openLink(link);
        }
      }
    }
  };

  const onSubmit = async (values: SigningDocumentsFormType) => {
    if (values.otpCode) {
      await signDocumentGroup(client.id, documentGroup!.id!, values.otpCode);
      notify.info(t('onboarding.signingDocuments.successMessage'));
    }
  };

  useEffect(() => {
    if (submitError) {
      if (submitError.code === ErrorCode.INVALID_OTP_TOKEN) {
        setFormError({ otpCode: submitError.message });
      } else {
        setFormError({ otpCode: undefined });
        notify.error(notify);
      }
    } else {
      setFormError({ otpCode: undefined });
    }
  }, [notify, setFormError, submitError]);

  return {
    documents,
    loading,
    submitLoading,
    formError,
    onClickDocument,
    onSubmit,
  };
};
