import { RegistrationAddressEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { CompanyAddressFormType } from '../../forms/companyAddressForm';

interface Props {
  legalAddress?: Maybe<RegistrationAddressEntity>;
  actualAddress?: Maybe<RegistrationAddressEntity>;
}

export const getBaseInitialData = (
  registrationAddress: RegistrationAddressEntity,
  residentialAddress?: Maybe<Partial<RegistrationAddressEntity>>
) => {
  const values: CompanyAddressFormType = {
    countryLegal: registrationAddress.country,
    localityLegal: registrationAddress.locality,
    regionLegal: registrationAddress.region,
    areaLegal: registrationAddress.district || undefined,
    streetLegal: registrationAddress.street,
    houseNumberLegal: registrationAddress.house,
    housingLegal: registrationAddress.building || undefined,
    apartmentLegal: registrationAddress.apartment || undefined,
    postcodeLegal: registrationAddress.postalCode,
    actualAddressIsSame: !residentialAddress,
  };

  values.countryActual = residentialAddress?.country;
  values.localityActual = residentialAddress?.locality;
  values.regionActual = residentialAddress?.region;
  values.areaActual = residentialAddress?.district || undefined;
  values.streetActual = residentialAddress?.street;
  values.houseNumberActual = residentialAddress?.house;
  values.housingActual = residentialAddress?.building || undefined;
  values.apartmentActual = residentialAddress?.apartment || undefined;
  values.postcodeActual = residentialAddress?.postalCode;

  return { values };
};

export const getInitialData = (client: Props) => {
  if (!client.legalAddress) {
    return undefined;
  }

  const values = getBaseInitialData(client.legalAddress, client.actualAddress);

  return values;
};
