import { LoadingView } from 'components';
import { SigningDocuments } from 'modules/onboarding/common/components/SigningDocuments';
import { useClientSigningDocuments } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from '../hooks';

export const SigningDocumentsStep = () => {
  const { data } = useOnboardingContext();

  const { onClickDocument, onSubmit, loading, documents, formError } =
    useClientSigningDocuments(data.ownerClient!);

  if (loading || !documents) {
    return <LoadingView />;
  }

  return (
    <SigningDocuments
      documents={documents}
      formError={formError}
      onClickDocument={onClickDocument}
      onSubmit={onSubmit}
    />
  );
};
