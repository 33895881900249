import { W8BenForm } from 'modules/onboarding/common/components';

import { useW8BenForm } from '../hooks';
import { PageComponentProps } from '../types';

export const W8BenFormStep = ({ canGoBack }: PageComponentProps) => {
  const {
    initialValues,
    submitLoading,
    files,
    submitDisabled,
    onAddFiles,
    onRemoveFile,
    onSubmit,
    goToBack,
  } = useW8BenForm();

  return (
    <W8BenForm
      canGoBack={canGoBack}
      files={files}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
