export const config = {
  API_URL: process.env.REACT_APP_API_URL!,
  DOCUMENT_TEMPLATE_SIGNATURES_URL:
    process.env.REACT_APP_DOCUMENT_TEMPLATE_SIGNATURES_URL,

  DOCUMENT_TEMPLATE_BENEFICIARY_URL:
    process.env.REACT_APP_DOCUMENT_TEMPLATE_BENEFICIARY_URL,
  EMAIL_ONBOARDING: process.env.REACT_APP_EMAIL_ONBOARDING,
  EMAIL_ORDER: process.env.REACT_APP_EMAIL_ORDER,
  PROJECT: (process.env.REACT_APP_PROJECT as 'finance' | 'global') ?? 'finance',
};

export const isGlobalProject = () => config.PROJECT === 'global';
export const isFinanceProject = () => config.PROJECT === 'finance';
