import { useRef } from 'react';

import { useClientSaveSourcesOfIncome } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { SourcesOfIncomeFormType } from '../forms/sourcesOfIncomeForm';
import { getInitialData } from '../helpers/sourcesOfIncome/getInitialData';
import { getSubmitData } from '../helpers/sourcesOfIncome/getSubmitData';

export const useClientSourcesOfIncome = (client: ClientEntity) => {
  const {
    error,
    loading: submitLoading,
    saveSourcesOfIncome: saveSourcesOfIncomeApi,
  } = useClientSaveSourcesOfIncome();
  const { t } = useTranslation();

  const initialData = useRef(getInitialData(client, t)).current;
  const initialValues = useRef(initialData.values).current;

  const saveSourcesOfIncome = async (values: SourcesOfIncomeFormType) => {
    const submitData = getSubmitData(values);
    await saveSourcesOfIncomeApi(client.id, submitData);
  };

  useServerErrorNotify(error);

  return {
    saveSourcesOfIncome,
    submitLoading,
    initialValues,
  };
};
