import { ObjectSchema, object, TypedForm, selectSchema } from 'libs/form';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoType } from 'types/yesOrNo';

export interface ReregistrationUSTaxStatusFormType {
  isUSTaxResident?: Maybe<SelectType<YesOrNoType>>;
}

export const getSchema = () => {
  const schema: ObjectSchema<ReregistrationUSTaxStatusFormType> = object({
    isUSTaxResident: selectSchema(Object.values(YesOrNoType))
      .default(null)
      .required(),
  });

  return schema;
};

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ReregistrationUSTaxStatusFormType>();
