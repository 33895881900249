import { Pressed } from 'libs/ui';
import { styled } from 'libs/ui/styled';

export const DialogContentStyled = styled('div')(({ theme }) => ({
  padding: 64,

  '.dialogContent-title': {
    lineHeight: '100%',

    [theme.breakpoints.down('mobile')]: {
      lineHeight: '112%',
    },
  },

  '.dialogContent-subtitle': {
    whiteSpace: 'pre-line',
  },

  [theme.breakpoints.down('mobile')]: {
    padding: '32px 16px',
  },
}));

export const CloseButtonStyled = styled(Pressed)(({ theme }) => ({
  position: 'absolute',
  right: 24,
  top: 24,

  '.dialogContent-closeIcon': {
    [theme.breakpoints.down('mobile')]: {
      width: 24,
      height: 24,
    },
  },

  [theme.breakpoints.down('mobile')]: {
    right: 16,
    top: 16,
  },
}));
