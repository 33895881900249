import { SourcesOfIncomeFormType } from 'modules/onboarding/common/forms/sourcesOfIncomeForm';
import { useClientSourcesOfIncome } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useSourcesOfIncome = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { initialValues, saveSourcesOfIncome, submitLoading } =
    useClientSourcesOfIncome(data);

  const onSubmit = async (values: SourcesOfIncomeFormType) => {
    if (values) {
      await saveSourcesOfIncome(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    submitLoading,
    initialValues,
  };
};
