import { useField } from 'libs/form/fields/useField';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  Field,
  FinancialPositionFormType,
} from 'modules/onboarding/common/forms/financialPositionForm';

type NestedKeys<T> = {
  [K in keyof T]: T[K] extends object
    ? `${K & string}.${keyof T[K] & string}`
    : never;
}[keyof T];

type FinancialPositionFormPaths = NestedKeys<FinancialPositionFormType>;

interface Props {
  sectionName: FinancialPositionFormPaths;
}

export const SectionBox = ({ sectionName }: Props) => {
  const parentPath = sectionName.split(
    '.'
  )[0] as keyof FinancialPositionFormType;

  const childPath = sectionName.split('.')[1];

  const { t } = useTranslation();

  const { field } = useField(`${sectionName}.enabled`);

  const options = t(
    `onboarding.financialPosition.${parentPath}.amountField.types`,
    { returnObjects: true }
  );

  const enabled = !!field.value;

  return (
    <Stack datax={sectionName} spacing={20} spacingMobile={8}>
      <Stack direction="row" justifyContent="space-between" spacing={16}>
        <Typography variant="subtitle1" variantMobile="captionBold">
          {t(`onboarding.financialPosition.${sectionName}.title`)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={16}
        >
          <Typography variant="bodyMedium" variantMobile="caption">
            {t('common.choose')}
          </Typography>
          <Field.Switch name={`${sectionName}.enabled`} />
        </Stack>
      </Stack>

      {enabled && (
        <Stack spacing={24}>
          {childPath === 'other' && (
            <Field.Text
              label={t(
                `onboarding.financialPosition.${parentPath}.other.otherField.label`
              )}
              name={`${parentPath}.other.otherText`}
              placeholder={t(
                `onboarding.financialPosition.${parentPath}.other.otherField.placeholder`
              )}
            />
          )}
          <Field.Autocomplete
            label={t(
              `onboarding.financialPosition.${parentPath}.amountField.label`
            )}
            name={`${sectionName}.value`}
            optionLabelKey="label"
            options={Object.keys(options).map((v) => ({
              label: t(
                // @ts-ignore
                `onboarding.financialPosition.${parentPath}.amountField.types.${v}`
              ),
              value: v,
            }))}
            optionValueKey="value"
            placeholder={t(
              `onboarding.financialPosition.${parentPath}.amountField.placeholder`
            )}
            variant="select"
          />
        </Stack>
      )}
    </Stack>
  );
};
