import { PropsWithChildren } from 'react';

import { Stack, Typography } from 'libs/ui';

interface Props extends PropsWithChildren {
  title: string;
}

export const Section = ({ title, children }: Props) => (
  <Stack spacing={24} spacingMobile={16}>
    <Typography variant="subtitle" variantMobile="bodyBold">
      {title}
    </Typography>

    <Stack spacing={24}>{children}</Stack>
  </Stack>
);
