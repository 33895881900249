import { BusinessRelationship } from 'modules/onboarding/common/components';

import { useBusinessRelationship } from '../hooks';
import { PageComponentProps } from '../types';

export const BusinessRelationshipStep = ({ canGoBack }: PageComponentProps) => {
  const { submitLoading, initialValues, onSubmit, goToBack } =
    useBusinessRelationship();

  return (
    <BusinessRelationship
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
