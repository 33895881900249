import { CompanyAddressFormType } from '../../forms/companyAddressForm';

export const getSubmitData = (values: CompanyAddressFormType) => {
  const legalAddress = {
    country: values.countryLegal?.id!,
    region: values.regionLegal,
    apartment: values.apartmentLegal,
    building: values.housingLegal,
    district: values.areaLegal,
    house: values.houseNumberLegal,
    locality: values.localityLegal,
    postalCode: values.postcodeLegal,
    street: values.streetLegal,
  };

  const actualAddress = !values.actualAddressIsSame
    ? {
        country: values.countryActual?.id!,
        region: values.regionActual!,
        apartment: values.apartmentActual!,
        building: values.housingActual,
        district: values.areaActual,
        house: values.houseNumberActual!,
        locality: values.localityActual!,
        postalCode: values.postcodeActual!,
        street: values.streetActual!,
      }
    : legalAddress;

  return {
    legalAddress,
    actualAddress: {
      ...actualAddress,
    },
  };
};
