import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  W8BenFormFormType,
  FormErrors,
  getSchema,
} from '../../forms/w8BenFormForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent, FormContentProps } from './FormContent';
import { SubTitle } from './SubTitle';

interface Props extends FormContentProps {
  initialValues?: W8BenFormFormType;
  onSubmit: (values: W8BenFormFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  clientType?: ClientType;
}

export const W8BenForm = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  onGoBack,
  onSubmit,
  clientType,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitleContent={<SubTitle />}
      title={t('onboarding.W8BENForm.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <FormContent {...formProps} />
      </Stack>
    </FormBodyWrapper>
  );
};
