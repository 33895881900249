import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from 'react-i18next';

import { buildYupLocale } from './buildYupLocale';
import { mergeLocales } from './mergeLocales';
import { Langs, I18N_KEY } from './types';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init(
    {
      debug: false,
      fallbackLng: Langs.RU,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        [Langs.RU]: {
          translation: mergeLocales(),
        },
      },
      returnNull: false,
      detection: {
        lookupLocalStorage: I18N_KEY,
        convertDetectedLanguage: (lng: string) => {
          // add all langs
          if (lng.includes('ru')) {
            return 'ru';
          }
          return 'ru';
        },
      },
    },
    buildYupLocale
  );
