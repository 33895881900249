import { useRef } from 'react';

import { useLegalClientBranchSaveAddresses } from 'api/requests/onboarding/legal/branch/useLegalClientBranchSaveAddresses';
import { useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';
import { CompanyAddressFormType } from 'modules/onboarding/legal/forms/companyAddressForm';

import {
  getInitialData,
  getSubmitData,
} from '../../../helpers/companyRegistrationAddress';

import { useOnboardingContext } from './useOnboardingContext';

export const useRegistrationAddress = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { error, loading, saveAddresses } = useLegalClientBranchSaveAddresses();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.legalClientBranch)?.values
  ).current;

  const onSubmit = async (values: CompanyAddressFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveAddresses(
        data.legalClient.id,
        data.legalClientBranch.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    initialValues,
    countries,
    countriesLoading,
    submitLoading: loading,
    goToBack,
  };
};
