// eslint-disable-next-line no-restricted-imports
import { Dialog as DialogBase } from '@mui/material';
import { getColor } from 'helpers';

import { CSSObject, styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

export const DialogSyled = styled(DialogBase)(({ theme }) => ({
  '.MuiDialog-paper': {
    backgroundColor: getColor(theme, {
      light: ColorsLight.white,
      dark: ColorsDark.black,
    }),
    backgroundImage: 'none',
  } as CSSObject,

  '.MuiPaper-root': {
    borderRadius: 24,
    boxShadow: theme.palette.shadow.main,
  },
}));
