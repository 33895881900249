import {
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  useFormContext,
  Field,
} from 'modules/onboarding/common/forms/sourcesOfIncomeForm';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isOtherIncomeSource =
    values.incomeSources?.find(
      (v) => v?.value === SourcesOfIncomeSourceType.Other
    ) !== undefined;

  const isOtherOccupation =
    values.occupation?.value === SourcesOfIncomeOccupationType.Other;

  const isEmployeeOrBusinessOwner =
    values.occupation?.value === SourcesOfIncomeOccupationType.Employee ||
    values.occupation?.value === SourcesOfIncomeOccupationType.BusinessOwner;

  return (
    <Stack spacing={32}>
      <Stack spacing={24} spacingMobile={16}>
        <Typography variant="subtitle" variantMobile="bodyBold">
          {t('onboarding.sourcesOfIncome.incomeSources.title')}
        </Typography>
        <Field.Autocomplete
          multiple
          label={t('onboarding.sourcesOfIncome.incomeSources.label')}
          name="incomeSources"
          optionLabelKey="label"
          options={Object.values(SourcesOfIncomeSourceType).map((v) => ({
            label: t(`onboarding.sourcesOfIncome.sourceType.${v}`),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t(
            'onboarding.sourcesOfIncome.incomeSources.placeholder'
          )}
          variant="select"
        />

        {isOtherIncomeSource && (
          <Field.Text
            label={t('onboarding.sourcesOfIncome.otherIncomeSource.label')}
            name="otherIncomeSource"
            placeholder={t(
              'onboarding.sourcesOfIncome.otherIncomeSource.placeholder'
            )}
          />
        )}
      </Stack>

      <Stack spacing={24} spacingMobile={16}>
        <Typography variant="subtitle" variantMobile="bodyBold">
          {t('onboarding.sourcesOfIncome.occupation.title')}
        </Typography>

        <Field.Autocomplete
          label={t('onboarding.sourcesOfIncome.occupation.label')}
          name="occupation"
          optionLabelKey="label"
          options={Object.values(SourcesOfIncomeOccupationType).map((v) => ({
            label: t(`onboarding.sourcesOfIncome.occupationType.${v}`),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t('onboarding.sourcesOfIncome.occupation.placeholder')}
          variant="select"
        />

        {isOtherOccupation && (
          <Field.Text
            label={t('onboarding.sourcesOfIncome.otherOccupation.label')}
            name="otherOccupation"
            placeholder={t(
              'onboarding.sourcesOfIncome.otherOccupation.placeholder'
            )}
          />
        )}

        {isEmployeeOrBusinessOwner && (
          <>
            <Field.Text
              label={t('onboarding.sourcesOfIncome.workplaceName.label')}
              name="workplaceName"
              placeholder={t(
                'onboarding.sourcesOfIncome.workplaceName.placeholder'
              )}
            />
            <Field.Text
              label={t('onboarding.sourcesOfIncome.position.label')}
              name="position"
              placeholder={t('onboarding.sourcesOfIncome.position.placeholder')}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
