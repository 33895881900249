import { ClientSaveFinancialPositionParam } from 'api/types/params';

import { FinancialPositionFormType } from '../../forms/financialPositionForm';

export const getSubmitData = (
  values: FinancialPositionFormType
): ClientSaveFinancialPositionParam => {
  const data: ClientSaveFinancialPositionParam = {
    regularMonthlyIncome: {
      businessIncome: values.regularMonthlyIncome.businessIncome?.enabled
        ? values.regularMonthlyIncome.businessIncome?.value?.value
        : undefined,
      investmentIncome: values.regularMonthlyIncome.investmentIncome?.enabled
        ? values.regularMonthlyIncome.investmentIncome?.value?.value
        : undefined,
      work: values.regularMonthlyIncome.work?.enabled
        ? values.regularMonthlyIncome.work?.value?.value
        : undefined,
      other: values.regularMonthlyIncome.other?.enabled
        ? {
            otherText: values.regularMonthlyIncome.other?.otherText,
            value: values.regularMonthlyIncome.other?.value?.value,
          }
        : undefined,
    },
    assetValue: {
      liquidAssets: values.assetValue.liquidAssets?.enabled
        ? values.assetValue.liquidAssets?.value?.value
        : undefined,
      securities: values.assetValue.securities?.enabled
        ? values.assetValue.securities?.value?.value
        : undefined,
      realEstate: values.assetValue.realEstate?.enabled
        ? values.assetValue.realEstate?.value?.value
        : undefined,
      other: values.assetValue.other?.enabled
        ? {
            otherText: values.assetValue.other?.otherText,
            value: values.assetValue.other?.value?.value,
          }
        : undefined,
    },
    financialLiabilities: {
      bankLoans: values.financialLiabilities.bankLoans?.enabled
        ? values.financialLiabilities.bankLoans?.value?.value
        : undefined,
      mortgageLoan: values.financialLiabilities.mortgageLoan?.enabled
        ? values.financialLiabilities.mortgageLoan?.value?.value
        : undefined,
      other: values.financialLiabilities.other?.enabled
        ? {
            otherText: values.financialLiabilities.other?.otherText,
            value: values.financialLiabilities.other?.value?.value,
          }
        : undefined,
    },
    expenses: {
      livingExpenses: values.expenses.livingExpenses?.enabled
        ? values.expenses.livingExpenses?.value?.value
        : undefined,
      loanRepayment: values.expenses.loanRepayment?.enabled
        ? values.expenses.loanRepayment?.value?.value
        : undefined,
      rent: values.expenses.rent?.enabled
        ? values.expenses.rent?.value?.value
        : undefined,
      other: values.expenses.other?.enabled
        ? {
            otherText: values.expenses.other?.otherText,
            value: values.expenses.other?.value?.value,
          }
        : undefined,
    },
  };
  return data;
};
