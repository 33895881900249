import { useMemo } from 'react';

import { DataGrid, GridColDef, GridRow } from '@mui/x-data-grid';
// eslint-disable-next-line no-restricted-imports
import { Link } from 'react-router-dom';

import { Icon } from '../Icon';
import { cx } from '../theme';

import { CustomPagination } from './CustomPagination';
import { TableStyled } from './Table.styles';
import { BaseRow, TableProps } from './types';

const ArrowFilledDown = () => (
  <Icon.ArrowFilledDown className="table-sort-down" size={16} />
);
const ArrowFilledUp = () => (
  <Icon.ArrowFilledUp className="table-sort-up" size={16} />
);

/**
 * Table component.
 */
export const Table = <Row extends BaseRow, S extends string = string>({
  rows,
  columns,
  limit = 20,
  rowCount,
  className,
  loading,
  sort,
  page = 0,
  hideFooter,
  hideFooterPagination,
  noRowsLabel = '',
  overlayWrapperHeight,
  variant = 'standard',
  isError,
  onCellClick,
  onSortChange,
  onPaginationChange,
  setRowHref,
}: TableProps<Row, S>) => {
  const tableColumns: GridColDef<Row>[] = useMemo(
    () =>
      columns.map(
        (column) =>
          ({
            ...column,
            valueGetter: column.renderValue
              ? (params) => column.renderValue!(params.row as unknown as Row)
              : undefined,
            renderCell: column.renderCell
              ? (params) =>
                  column.renderCell!(params.row as unknown as Row, params.field)
              : undefined,
            headerName: column.title,
            renderHeader: column.renderTitle
              ? () => column.renderTitle!()
              : undefined,
            sortable: column.sortable ?? false,
            field: column.field.toString(),
          } as GridColDef<Row>)
      ),
    [columns]
  );

  const sortModel = useMemo(
    () => (sort ? [{ ...sort, field: sort.field.toString() }] : []),
    [sort]
  );

  hideFooter = hideFooter || limit >= (rowCount ?? 0);

  const { columnHeaderHeight, rowHeight } = useMemo(
    () => ({
      columnHeaderHeight: variant === 'standard' ? 44 : 48,
      rowHeight: variant === 'standard' ? 52 : 48,
    }),
    [variant]
  );

  return (
    <TableStyled
      className={cx('table', className)}
      isCellClickable={!!onCellClick || !!setRowHref}
      isError={isError}
      overlayWrapperHeight={overlayWrapperHeight}
      variant={variant}
    >
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        classes={{
          main: 'table-main',
          columnHeaders: 'table-columnHeaders',
          columnHeader: 'table-columnHeader',
          columnHeaderTitle: 'table-columnHeaderTitle',
          row: 'table-row',
          'row--lastVisible': 'table-row--lastVisible',
          cell: 'table-cell',
          columnSeparator: 'table-columnSeparator',
          footerContainer: 'table-footerContainer',
          overlayWrapper: 'table-overlayWrapper',
          virtualScroller: 'table-virtualScroller',
        }}
        className="table-dataGrid"
        columnHeaderHeight={columnHeaderHeight}
        columns={tableColumns}
        hideFooter={hideFooter}
        hideFooterPagination={hideFooterPagination}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
              page,
            },
          },
        }}
        loading={loading}
        localeText={{ noRowsLabel }}
        pageSizeOptions={[limit]}
        paginationMode="server"
        paginationModel={{ page, pageSize: limit }}
        rowBuffer={limit}
        rowCount={rowCount}
        rowHeight={rowHeight}
        // @ts-ignore
        rows={rows}
        slotProps={{
          baseIconButton: { disableRipple: true },
        }}
        slots={{
          columnSortedAscendingIcon: ArrowFilledDown,
          columnSortedDescendingIcon: ArrowFilledUp,
          pagination: CustomPagination,
          // eslint-disable-next-line react/no-unstable-nested-components
          row: (props) => {
            const href = setRowHref?.(props.row);
            if (href) {
              return (
                <Link className="table-row-link" to={href}>
                  <GridRow {...props} />
                </Link>
              );
            }
            return <GridRow {...props} />;
          },
        }}
        sortingMode="server"
        sortModel={sortModel}
        onCellClick={(params, event) => {
          if (window?.getSelection?.()?.type !== 'Range') {
            onCellClick?.(params.row as unknown as Row, event);
          }
        }}
        onPaginationModelChange={(p) => onPaginationChange?.(p.page)}
        onSortModelChange={(s) =>
          onSortChange?.(
            s?.[0] ? { field: s[0].field as S, sort: s[0].sort } : undefined
          )
        }
      />
    </TableStyled>
  );
};
