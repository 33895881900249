import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientController_SaveRiskProfile_Input_RiskProfile,
  useClientSaveRiskProfileMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { RiskProfileParam } from 'api/types/params';

import { parseClientEntity } from '../helpers';

export const useClientSaveRiskProfile = () => {
  const [saveRiskProfile, { data, loading, error: apolloError }] =
    useClientSaveRiskProfileMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveRiskProfile
      ? parseClientEntity(data.clientSaveRiskProfile)
      : undefined,
    loading,
    error,
    saveRiskProfile: useCallback(
      async (id: string, params: RiskProfileParam) => {
        const res = await saveRiskProfile({
          variables: {
            id,
            input: {
              riskProfile:
                MutationInput_ClientController_SaveRiskProfile_Input_RiskProfile[
                  params.riskProfile
                ],
            },
          },
        });
        return res?.data?.clientSaveRiskProfile
          ? parseClientEntity(res.data.clientSaveRiskProfile)
          : undefined;
      },
      [saveRiskProfile]
    ),
  };
};
