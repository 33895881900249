import { BusinessRelationshipFormType } from 'modules/onboarding/common/forms/businessRelationshipForm';
import { useClientBusinessRelationship } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useBusinessRelationship = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { initialValues, saveBusinessRelationship, submitLoading } =
    useClientBusinessRelationship(data);

  const onSubmit = async (values: BusinessRelationshipFormType) => {
    if (values) {
      await saveBusinessRelationship(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    submitLoading,
    initialValues,
  };
};
