/* eslint-disable no-restricted-imports */
import { useCallback } from 'react';

import { ApolloClient } from '@apollo/client';
import { useApiClient } from 'api/client';
import {
  LegalClientBeneficiaryInfoDocument,
  LegalClientHeadInfoDocument,
  LegalClientInfoDocument,
  LegalClientLegalHeadFirstHeadInfoDocument,
  LegalClientRepresentativeInfoDocument,
  PersonClientInfoDocument,
  PersonClientRepresentativeInfoDocument,
} from 'api/generated/graphql';
import { ClientIdentityType } from 'api/types/data';
import { ClientInfoEntity, OnboardingProcessEntity } from 'api/types/entity';

interface ClientInfoApi {
  id: string;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  onboarding?: {
    process?: OnboardingProcessEntity;
  };
}

const parseClientInfo = async (
  client: ApolloClient<object>,
  type: ClientIdentityType,
  clientInfo?: ClientInfoApi | null
): Promise<ClientInfoEntity | undefined> => {
  if (!clientInfo) return undefined;

  const parse = (clientInfo: ClientInfoApi) => ({
    id: clientInfo.id,
    firstName: clientInfo.firstName,
    lastName: clientInfo.lastName,
    middleName: clientInfo.middleName,
    isStartOnboareding: !!clientInfo.onboarding?.process?.previousSteps.length,
    type,
  });

  const parseForLegal = async (
    clientInfo: ClientInfoApi & { legalClientId?: string }
  ) => {
    let companyName: string | undefined;

    if (clientInfo.legalClientId) {
      const { data } = await client.query({
        query: LegalClientInfoDocument,
        variables: { id: clientInfo.legalClientId },
      });

      companyName = data.clientInfo.fullName;
    }

    return {
      ...parse(clientInfo),
      companyName,
    };
  };

  switch (type) {
    case ClientIdentityType.Client:
    case ClientIdentityType.ClientRepresentative:
    case ClientIdentityType.ClientWithRepresentative:
    case ClientIdentityType.LegalClientHead:
    case ClientIdentityType.LegalClientLegalHeadFirstHead:
    case ClientIdentityType.LegalClientBeneficiary: {
      return parse(clientInfo as ClientInfoApi);
    }

    case ClientIdentityType.LegalClientRepresentative: {
      return parseForLegal(clientInfo as ClientInfoApi);
    }

    default:
      return undefined;
  }
};

export const useClientInfo = () => {
  const client = useApiClient();

  const getClientInfo = useCallback(
    async (id: string, type: ClientIdentityType) => {
      let doc;
      switch (type) {
        case ClientIdentityType.Client:
        case ClientIdentityType.ClientWithRepresentative:
          doc = PersonClientInfoDocument;
          break;

        case ClientIdentityType.ClientRepresentative:
          doc = PersonClientRepresentativeInfoDocument;
          break;

        case ClientIdentityType.LegalClientRepresentative:
          doc = LegalClientRepresentativeInfoDocument;
          break;

        case ClientIdentityType.LegalClientHead:
          doc = LegalClientHeadInfoDocument;
          break;

        case ClientIdentityType.LegalClientLegalHeadFirstHead:
          doc = LegalClientLegalHeadFirstHeadInfoDocument;
          break;

        case ClientIdentityType.LegalClientBeneficiary:
          doc = LegalClientBeneficiaryInfoDocument;
          break;

        default:
          return undefined;
      }

      const { data } = await client.query({
        query: doc,
        variables: { id },
      });

      return parseClientInfo(client, type, data.clientInfo);
    },
    [client]
  );

  return { getClientInfo };
};
