import { ReactNode, useRef } from 'react';

import { OnboardingStatusWrapper } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import { FormErrors, FormSubmit } from '../../forms/emailForm';

import { EmailFormDialog } from './EmailFormDialog';
import { UsersTable } from './UsersTable/UsersTable';
import { WaitingKycUserType } from './types';

type Props = {
  users: WaitingKycUserType[];
  title?: string;
  subtitle: ReactNode;
  initialEmailEditErrors?: FormErrors;
  loadingEmailEdit?: boolean;
  onResend: (id: string) => void;
  onEditEmail: (id: string, email: string) => Promise<boolean>;
};

export const WaitingKyc = ({
  title,
  subtitle,
  users,
  initialEmailEditErrors,
  loadingEmailEdit,
  onEditEmail,
  onResend,
}: Props) => {
  const { t } = useTranslation();

  const { isOpen, onClose, open } = useDialog();

  const editItemid = useRef<string>();

  const handleEditSubmit: FormSubmit = async (values) => {
    if (editItemid.current) {
      if (await onEditEmail(editItemid.current, values.email)) {
        onClose();
      }
    }
  };

  const onClickEdit = (row: WaitingKycUserType) => {
    editItemid.current = row.id;
    open();
  };

  const onClickResend = (row: WaitingKycUserType) => {
    onResend(row.id);
  };

  return (
    <OnboardingStatusWrapper title={title ?? t('onboarding.waitingKyc.title')}>
      <Stack spacing={32}>
        {subtitle}

        <UsersTable
          items={users}
          onClickEdit={onClickEdit}
          onClickResend={onClickResend}
        />
      </Stack>

      <EmailFormDialog
        initialErrors={initialEmailEditErrors}
        isOpen={isOpen}
        submitLoading={loadingEmailEdit}
        onClose={onClose}
        onSubmit={handleEditSubmit}
      />
    </OnboardingStatusWrapper>
  );
};
