import { FC } from 'react';

import { Link } from 'libs/ui';

import { OnboardingStatusWrapper } from '../OnboardingStatusWrapper';

export type OnboardingStatusDefaultProps = {
  email: string;
  text: string;
  title: string;
};

export const OnboardingStatusDefault: FC<OnboardingStatusDefaultProps> = ({
  email,
  text,
  title,
}) => (
  <OnboardingStatusWrapper title={title}>
    {text}{' '}
    <Link href={`mailto: ${email}`} mode="text">
      {email}
    </Link>
    .
  </OnboardingStatusWrapper>
);
