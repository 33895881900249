import { getColor } from 'helpers';
import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const DocumentsStyled = styled('div')(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${getColor(theme, {
    dark: ColorsDark.gray150,
    light: ColorsLight.gray150,
  })}`,

  '.SigningDocuments-documentBox': {
    padding: '12px 16px',

    '&:not(:last-child)': {
      borderBottom: `1px solid ${getColor(theme, {
        dark: ColorsDark.gray150,
        light: ColorsLight.gray150,
      })}`,
    },
  },
}));

export const DialogDocumentsStyled = styled(Stack)(({ theme }) => ({
  padding: 12,
  borderRadius: 12,
  background: getColor(theme, {
    dark: ColorsDark.gray50,
    light: ColorsLight.gray50,
  }),
  marginBottom: 16,
  [theme.breakpoints.down('mobile')]: {
    marginBottom: 12,
  },
}));
