// eslint-disable-next-line no-restricted-imports
import { W8BenFormFragment } from 'api/generated/graphql';
import { W8BenFormEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileEntity } from './parseFileEntity';

export const parseW8BENFormEntity = (
  w8BenForm: Omit<W8BenFormFragment, '__typename'>
): W8BenFormEntity => ({
  files: lodash.compact(w8BenForm.files).map(parseFileEntity),
  isUSSecuritiesInvestmentPlanned: w8BenForm.isUSSecuritiesInvestmentPlanned,
});
