// eslint-disable-next-line no-restricted-imports
import { Query_ClientController_GetDocumentGroup_Files_Items_Type as FileTypeApi } from 'api/generated/graphql';
import { FileEntity, FileTypeName } from 'api/types/entity';

interface Args {
  id: string;
  name: string;
  size: number;
  type?: FileTypeApi;
}

export const parseFileEntity = (v: Args): FileEntity => ({
  id: v.id,
  name: v.name,
  size: v.size,
  type: v.type ? FileTypeName[v.type] : undefined,
});
