import { FC, ReactNode } from 'react';

import { Body } from 'components';
import { Stack, Typography } from 'libs/ui';

import { LayoutStyled } from './OnboardingStatusWrapper.styles';

export type OnboardingStatusViewWrapperProps = {
  title: string;
  children: ReactNode | ReactNode[];
};

export const OnboardingStatusWrapper: FC<OnboardingStatusViewWrapperProps> = ({
  children,
  title,
}) => (
  <LayoutStyled variant="filled">
    <Body className="onboardingStatus-content">
      <Stack spacing={32} spacingMobile={12}>
        <Typography
          as="h1"
          datax="onboardingStatus-title"
          variant="title"
          variantMobile="subtitle"
        >
          {title}
        </Typography>

        <Typography datax="onboardingStatus-text" whiteSpace="pre-wrap">
          {children}
        </Typography>
      </Stack>
    </Body>
  </LayoutStyled>
);
