import { useEffect, useState } from 'react';

import { ConfirmationDialogContent } from 'components';
import { useTranslation } from 'libs/i18n';
import { Dialog, DialogProps } from 'libs/ui';

import {
  EmailFormType,
  FormSubmit,
  Form,
  Field,
  FormErrors,
  Submit,
  getSchema,
} from '../../../forms/emailForm';

interface Props extends DialogProps {
  initialValues?: EmailFormType;
  initialErrors?: FormErrors;
  submitLoading?: boolean;
  onSubmit: FormSubmit;
}

export const EmailFormDialog = ({
  initialValues,
  initialErrors,
  submitLoading,
  onSubmit,
  ...dialogProps
}: Props) => {
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState(initialErrors);

  useEffect(() => {
    if (!dialogProps.isOpen) {
      setFormErrors(undefined);
    }
  }, [dialogProps.isOpen]);

  useEffect(() => {
    setFormErrors(initialErrors);
  }, [initialErrors]);

  return (
    <Dialog {...dialogProps} showPuller>
      <Form
        initialErrors={formErrors}
        initialValues={initialValues}
        validationSchema={getSchema(t)}
        onSubmit={onSubmit}
      >
        <ConfirmationDialogContent
          headerCentered
          buttonConfirm={Submit}
          confirmButtonLabel={t('common.save')}
          loading={submitLoading}
          subtitle={t('onboarding.waitingKyc.emailDialog.subtitle')}
          title={t('onboarding.waitingKyc.emailDialog.title')}
          onBack={dialogProps.onClose}
        >
          <Field.Text
            helperTextAbsolute
            autoComplete="off"
            label={t('onboarding.waitingKyc.emailDialog.email.label')}
            name="email"
            placeholder={t(
              'onboarding.waitingKyc.emailDialog.email.placeholder'
            )}
          />
        </ConfirmationDialogContent>
      </Form>
    </Dialog>
  );
};
