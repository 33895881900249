import { Maybe } from 'types/maybe';

export enum UserGenderType {
  Male = 'Male',
  Female = 'Female',
}
export interface UserEntity {
  id: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Maybe<Date>;
  otp?: Maybe<{ verified: boolean }>;
  isPasswordUpdateRequired?: Boolean;
  gender?: UserGenderType;
}
