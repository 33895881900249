import { TaxResidencesEntity } from 'api/types/entity';
import { isGlobalProject } from 'config';
import { lodash } from 'helpers';
import { FileUpload } from 'hooks';
import { Maybe } from 'types/maybe';

import { OnboardingTaxResidencyFormType } from '../../forms/onboardingTaxResidencyForm';

export const getInitialData = (
  taxResidences?: Maybe<TaxResidencesEntity[]>
) => {
  const values: OnboardingTaxResidencyFormType = {
    taxValues: taxResidences?.map((v) => ({
      country: v.country || null,
      reasonForAbsence: v.reasonForMissingTaxId,
      taxNumber: v.taxId,
      taxNumberIsMissing: !v.taxId && !!v.reasonForMissingTaxId,
      fileIds: isGlobalProject() ? v.files?.map((f) => f.id) : undefined,
    })) || [{ country: null }],
  };

  const files: FileUpload[] | undefined = isGlobalProject()
    ? lodash.compact(
        taxResidences?.flatMap((v, index) =>
          v.files?.map(
            (file) =>
              new FileUpload(
                {
                  name: file.name,
                  size: file.size,
                },
                undefined,
                false,
                file.id,
                index
              )
          )
        )
      )
    : undefined;

  return { values, files };
};
