import { FormContainer, VerificationCodeFormContentDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { Box, LinkLabel, Typography } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import {
  SigningDocumentsFormType,
  FormErrors,
  getSchema,
} from '../../forms/signingDocumentsForm';

import {
  DialogDocumentsStyled,
  DocumentsStyled,
} from './SigningDocuments.styles';

type SigningDocumentsFileTypeName =
  | 'BrokerageAgreementApplication'
  | 'OpenSubAccountOrder';

interface Props {
  canGoBack?: boolean;
  formError?: FormErrors;
  submitLoading?: boolean;
  documents: SigningDocumentsFileTypeName[];
  onGoBack?: () => void;
  onSubmit: (values: SigningDocumentsFormType) => void;
  onClickDocument: (type: SigningDocumentsFileTypeName) => void;
}

export const SigningDocuments = ({
  canGoBack,
  submitLoading,
  formError,
  documents,
  onGoBack,
  onSubmit,
  onClickDocument,
}: Props) => {
  const { t } = useTranslation();
  const scheme = getSchema();

  const {
    isOpen: isOpenVerificationDialog,
    onClose: onCloseVerificationDialog,
    open: openVerificationDialog,
  } = useDialog();

  const handleSubmit = (values: SigningDocumentsFormType) => {
    if (isOpenVerificationDialog && values.otpCode) {
      onSubmit(values);
    } else {
      openVerificationDialog();
    }
  };

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      submitLabel={t('onboarding.signingDocuments.submitLabel')}
      submitLoading={submitLoading}
      subTitle={t('onboarding.signingDocuments.subtitle')}
      title={t('onboarding.signingDocuments.title')}
      validationSchema={scheme}
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
    >
      <DocumentsStyled>
        {documents.map((type) => (
          <Box key={type} className="SigningDocuments-documentBox">
            <LinkLabel
              as="button"
              label={t(`onboarding.signingDocuments.docs.${type}`)}
              mode="text"
              typographyVariant="bodyBold"
              typographyVariantMobile="captionBold"
              onClick={() => onClickDocument(type)}
            />
          </Box>
        ))}
      </DocumentsStyled>

      <VerificationCodeFormContentDialog<SigningDocumentsFormType>
        codeFieldName="otpCode"
        isOpen={isOpenVerificationDialog}
        title={t('onboarding.signingDocuments.verificationDialog.title')}
        onClose={onCloseVerificationDialog}
      >
        <DialogDocumentsStyled spacing={8} spacingMobile={4}>
          <Typography variant="bodyBold" variantMobile="captionBold">
            {t('onboarding.signingDocuments.verificationDialog.docsTitle')}
          </Typography>
          <ul>
            {documents.map((type) => (
              <li key={type}>
                <Typography variantMobile="caption">
                  {t(`onboarding.signingDocuments.docs.${type}`)}
                </Typography>
              </li>
            ))}
          </ul>
        </DialogDocumentsStyled>
      </VerificationCodeFormContentDialog>
    </FormContainer>
  );
};
