import { isGlobalProject } from 'config';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const LogoDarkFinance = ({ size, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 116 40"
      width="116"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112.313 39.9061V34.2365H115.996V35.3056H113.518V38.837H115.977V39.9061H112.313ZM112.891 37.5329V36.4962H115.458V37.5329H112.891Z"
        fill="#04ACF4"
      />
      <path
        d="M101.123 40C100.7 40 100.309 39.9298 99.9513 39.7894C99.5992 39.6436 99.2911 39.4411 99.0271 39.182C98.7685 38.9174 98.5677 38.6069 98.4246 38.2505C98.2816 37.8887 98.2101 37.4946 98.2101 37.068C98.2101 36.6522 98.2844 36.2688 98.4329 35.9179C98.5814 35.5615 98.7905 35.2537 99.0601 34.9945C99.3296 34.7299 99.646 34.5248 100.009 34.379C100.372 34.2332 100.766 34.1603 101.189 34.1603C101.464 34.1603 101.734 34.1981 101.998 34.2737C102.267 34.3439 102.512 34.4492 102.732 34.5896C102.958 34.7299 103.15 34.8973 103.31 35.0917L102.534 35.9341C102.402 35.8045 102.265 35.6938 102.122 35.602C101.984 35.5048 101.836 35.4319 101.676 35.3833C101.522 35.3293 101.357 35.3023 101.181 35.3023C100.944 35.3023 100.722 35.3455 100.512 35.4319C100.309 35.5183 100.13 35.6398 99.9761 35.7964C99.822 35.9476 99.6982 36.1338 99.6047 36.3552C99.5167 36.5712 99.4727 36.8115 99.4727 37.0761C99.4727 37.3461 99.5167 37.5918 99.6047 37.8131C99.6927 38.0291 99.8165 38.2154 99.9761 38.372C100.136 38.5232 100.325 38.642 100.545 38.7284C100.771 38.8094 101.019 38.8499 101.288 38.8499C101.464 38.8499 101.635 38.8256 101.8 38.777C101.965 38.7284 102.116 38.6609 102.254 38.5745C102.397 38.4881 102.523 38.3882 102.633 38.2748L103.236 39.2224C103.104 39.3682 102.925 39.5005 102.699 39.6193C102.479 39.7381 102.232 39.8326 101.957 39.9028C101.682 39.9676 101.404 40 101.123 40Z"
        fill="#04ACF4"
      />
      <path
        d="M84.812 39.9189V34.2365H85.8287L88.765 38.4821H88.5525C88.5323 38.3143 88.5146 38.152 88.4994 37.995C88.4842 37.8327 88.4691 37.6703 88.4539 37.5079C88.4438 37.3402 88.4337 37.1697 88.4235 36.9965C88.4185 36.8179 88.4134 36.6312 88.4084 36.4364C88.4033 36.2361 88.4008 36.0197 88.4008 35.787V34.2365H89.5161V39.9189H88.4842L85.4873 35.6327L85.7756 35.6408C85.8009 35.9223 85.8211 36.1631 85.8363 36.3633C85.8565 36.5582 85.8717 36.7286 85.8818 36.8747C85.8919 37.0209 85.8995 37.148 85.9046 37.2563C85.9147 37.3645 85.9198 37.4674 85.9198 37.5648C85.9248 37.6622 85.9273 37.765 85.9273 37.8732V39.9189H84.812Z"
        fill="#04ACF4"
      />
      <path
        d="M70.6272 39.919L72.8883 34.2494H73.9941L76.247 39.919H74.9761L73.8126 36.8655C73.7795 36.7791 73.7383 36.6684 73.6888 36.5334C73.6448 36.3985 73.598 36.2554 73.5485 36.1042C73.499 35.953 73.4522 35.8099 73.4082 35.6749C73.3642 35.5345 73.3312 35.4184 73.3092 35.3266L73.532 35.3185C73.499 35.4427 73.4605 35.5723 73.4165 35.7073C73.3779 35.8369 73.3367 35.9719 73.2927 36.1123C73.2487 36.2473 73.2019 36.3796 73.1524 36.5091C73.1029 36.6387 73.0561 36.7656 73.0121 36.8898L71.8485 39.919H70.6272ZM71.6752 38.7284L72.0796 37.7484H74.7286L75.1412 38.7284H71.6752Z"
        fill="#04ACF4"
      />
      <path
        d="M57.673 39.9189V34.2365H58.6897L61.6259 38.4821H61.4135C61.3933 38.3143 61.3756 38.152 61.3604 37.995C61.3452 37.8327 61.33 37.6703 61.3149 37.5079C61.3047 37.3402 61.2946 37.1697 61.2845 36.9965C61.2794 36.8179 61.2744 36.6312 61.2693 36.4364C61.2643 36.2361 61.2617 36.0197 61.2617 35.787V34.2365H62.3771V39.9189H61.3452L58.3482 35.6327L58.6366 35.6408C58.6618 35.9223 58.6821 36.1631 58.6973 36.3633C58.7175 36.5582 58.7327 36.7286 58.7428 36.8747C58.7529 37.0209 58.7605 37.148 58.7655 37.2563C58.7757 37.3645 58.7807 37.4674 58.7807 37.5648C58.7858 37.6622 58.7883 37.765 58.7883 37.8732V39.9189H57.673Z"
        fill="#04ACF4"
      />
      <path
        d="M47.2147 39.919V34.2494H47.8336C47.9382 34.2494 48.0427 34.2494 48.1472 34.2494C48.2518 34.2494 48.3535 34.2494 48.4526 34.2494V39.919H47.2147Z"
        fill="#04ACF4"
      />
      <path
        d="M34.9034 39.919V34.2494H36.1413V39.919H34.9034ZM35.4728 37.6674V36.6225H38.4602V37.6674H35.4728ZM35.4728 35.3428V34.2494H38.8067V35.3428H35.4728Z"
        fill="#04ACF4"
      />
      <path
        d="M100.641 27.4604V12.1677H103.967L109.546 21.2341L107.161 21.2124L112.806 12.1677H116V27.4604H112.39V22.6974C112.39 21.3866 112.419 20.2077 112.478 19.1587C112.551 18.11 112.668 17.0687 112.828 16.0346L113.244 17.1706L108.999 23.7465H107.555L103.398 17.2143L103.814 16.0346C103.974 17.0104 104.083 18.0153 104.142 19.0494C104.214 20.0686 104.252 21.2846 104.252 22.6974V27.4604H100.641Z"
        fill="#F1F1F1"
      />
      <path
        d="M86.5398 27.4604V12.1677H97.2161V15.3355H90.1498V24.2926H97.4349V27.4604H86.5398ZM88.2901 21.1908V18.1756H96.2315V21.1908H88.2901Z"
        fill="#F1F1F1"
      />
      <path
        d="M69.6394 27.4604V12.1677H76.0493C77.1722 12.1677 78.2011 12.357 79.134 12.7357C80.068 13.0998 80.8699 13.6241 81.5407 14.3087C82.227 14.9933 82.7513 15.8016 83.1166 16.7337C83.4809 17.6658 83.6636 18.6926 83.6636 19.8141C83.6636 20.9352 83.4809 21.9699 83.1166 22.9159C82.7513 23.8485 82.227 24.6564 81.5407 25.3417C80.8699 26.0115 80.068 26.536 79.134 26.9142C78.2011 27.278 77.1722 27.4604 76.0493 27.4604H69.6394ZM73.3588 24.9264L72.8552 24.1834H75.9399C76.5529 24.1834 77.0999 24.0814 77.5808 23.8773C78.0628 23.6589 78.4705 23.36 78.8059 22.9818C79.1567 22.6036 79.4261 22.144 79.616 21.6051C79.8059 21.0661 79.8998 20.4695 79.8998 19.8141C79.8998 19.1587 79.8059 18.5615 79.616 18.0226C79.4261 17.4838 79.1567 17.025 78.8059 16.6463C78.4705 16.2676 78.0628 15.9764 77.5808 15.7724C77.0999 15.554 76.5529 15.4447 75.9399 15.4447H72.7891L73.3588 14.7456V24.9264Z"
        fill="#F1F1F1"
      />
      <path
        d="M59.635 27.6787C58.4099 27.6787 57.3015 27.4818 56.3098 27.0892C55.3179 26.6956 54.4646 26.1422 53.7499 25.4281C53.0352 24.7149 52.4882 23.8771 52.109 22.9157C51.7297 21.9398 51.5402 20.8846 51.5402 19.7483C51.5402 18.6123 51.7371 17.571 52.1309 16.6243C52.5393 15.663 53.1154 14.8255 53.8593 14.1119C54.6032 13.3983 55.4784 12.8448 56.4848 12.4516C57.5058 12.0583 58.6143 11.8617 59.8104 11.8617C60.6567 11.8617 61.4513 11.9709 62.1954 12.1894C62.9539 12.4079 63.6392 12.7064 64.2522 13.0851C64.8642 13.4638 65.3823 13.9007 65.8054 14.3959L63.5515 16.7772C63.1872 16.4276 62.8074 16.1363 62.4142 15.9033C62.0344 15.6703 61.6268 15.4882 61.1892 15.3572C60.7516 15.2115 60.2841 15.1387 59.7887 15.1387C59.1468 15.1387 58.5565 15.2552 58.0163 15.4882C57.4912 15.7212 57.0244 16.0489 56.6161 16.4713C56.2223 16.8791 55.9159 17.3671 55.6972 17.9351C55.4784 18.4885 55.369 19.0929 55.369 19.7483C55.369 20.4033 55.4856 21.0154 55.719 21.5833C55.9524 22.1511 56.2732 22.6467 56.6817 23.0692C57.0901 23.4773 57.5714 23.805 58.1257 24.0523C58.6948 24.2852 59.2996 24.4017 59.9415 24.4017C60.3935 24.4017 60.8166 24.3285 61.2109 24.1832C61.6195 24.0379 61.9694 23.8411 62.2604 23.5938C62.5525 23.3454 62.7857 23.0548 62.9612 22.7199C63.1356 22.3695 63.2233 21.9985 63.2233 21.6049V21.0371L63.7053 21.7801H59.4822V18.8745H66.6806C66.7095 19.0347 66.7312 19.2532 66.7456 19.5299C66.7611 19.8066 66.7683 20.0756 66.7683 20.3384C66.7828 20.6002 66.79 20.797 66.79 20.9279C66.79 21.9182 66.6073 22.8291 66.243 23.6587C65.8932 24.4748 65.3968 25.1807 64.7548 25.7784C64.1129 26.3751 63.3544 26.8409 62.4792 27.1758C61.6041 27.5107 60.6567 27.6787 59.635 27.6787Z"
        fill="#F1F1F1"
      />
      <path
        d="M34.8762 27.4604V12.1677H38.2018L46.2314 23.0911L45.5094 22.9818C45.4364 22.5016 45.3781 22.042 45.3344 21.6051C45.2906 21.1681 45.2468 20.7384 45.2031 20.3169C45.1739 19.8941 45.1448 19.4645 45.1156 19.0276C45.101 18.5906 45.0864 18.1173 45.0718 17.6075C45.0718 17.0978 45.0718 16.5444 45.0718 15.9472V12.1677H48.6818V27.4604H45.3125L36.9328 16.1875L38.0487 16.3404C38.1216 17.0395 38.1799 17.6367 38.2237 18.1319C38.282 18.6125 38.3258 19.0421 38.355 19.4208C38.3987 19.7995 38.4279 20.1418 38.4425 20.4478C38.457 20.7539 38.4644 21.0589 38.4644 21.365C38.4789 21.6566 38.4862 21.9843 38.4862 22.3481V27.4604H34.8762Z"
        fill="#F1F1F1"
      />
      <path
        d="M5.08039 37.4066L0 39.8964V16.5168C0 13.9175 1.48932 11.5371 3.85553 10.388L9.24214 7.74768V30.7853C9.25606 33.5898 7.62755 36.148 5.08039 37.4066Z"
        fill="url(#paint0_linear_476_1751)"
      />
      <path
        d="M9.2561 22.9739L6.69503 20.0189C3.2849 16.079 4.55152 10.046 9.2561 7.74768V22.9739Z"
        fill="url(#paint1_linear_476_1751)"
      />
      <path
        d="M9.2561 7.73401V22.9739L16.4522 32.509V17.2555L9.2561 7.73401Z"
        fill="#0F4B61"
      />
      <path
        d="M22.3816 29.6088L16.4521 32.5091V9.47144C16.4521 6.66697 18.0667 4.09507 20.6278 2.85017L25.7082 0.360352V24.3282C25.7082 26.5581 24.4137 28.6102 22.3816 29.6088Z"
        fill="url(#paint2_linear_476_1751)"
      />
      <path
        d="M16.4521 32.509V17.2555L19.0132 20.2378C22.4233 24.164 21.1567 30.1971 16.4521 32.509Z"
        fill="url(#paint3_linear_476_1751)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_476_1751"
          x1="4.62185"
          x2="4.62185"
          y1="7.73976"
          y2="39.8922"
        >
          <stop stopColor="#0F4B61" />
          <stop offset="0.1273" stopColor="#0E516A" />
          <stop offset="0.3349" stopColor="#0D6182" />
          <stop offset="0.597" stopColor="#0A7BAA" />
          <stop offset="0.9006" stopColor="#059FE1" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_476_1751"
          x1="7.00596"
          x2="7.00596"
          y1="22.9696"
          y2="7.73976"
        >
          <stop stopColor="#077BA8" />
          <stop offset="1" stopColor="#068CC4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_476_1751"
          x1="21.0804"
          x2="21.0804"
          y1="32.5065"
          y2="0.354031"
        >
          <stop stopColor="#0F4B61" />
          <stop offset="0.1273" stopColor="#0E516A" />
          <stop offset="0.3349" stopColor="#0D6182" />
          <stop offset="0.597" stopColor="#0A7BAA" />
          <stop offset="0.9006" stopColor="#059FE1" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_476_1751"
          x1="18.6994"
          x2="18.6994"
          y1="17.2487"
          y2="32.5065"
        >
          <stop stopColor="#077BA8" />
          <stop offset="1" stopColor="#068CC4" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

export const LogoDarkGlobal = ({ size, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="44"
      viewBox="0 0 126 44"
      width="126"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M121.874 43.9079V37.6641H123.199V42.7037H126V43.9079H121.874Z"
        fill="#04ACF4"
      />
      <path
        d="M102.747 43.9079L105.167 37.6641H106.351L108.763 43.9079H107.403L106.157 40.5451C106.122 40.45 106.077 40.3281 106.024 40.1794C105.977 40.0308 105.927 39.8732 105.874 39.7067C105.821 39.5402 105.771 39.3826 105.724 39.2339C105.677 39.0793 105.642 38.9515 105.618 38.8504L105.857 38.8415C105.821 38.9782 105.78 39.1209 105.733 39.2696C105.692 39.4123 105.647 39.561 105.6 39.7156C105.553 39.8643 105.503 40.0099 105.45 40.1527C105.397 40.2954 105.347 40.4351 105.3 40.5719L104.054 43.9079H102.747ZM103.869 42.5967L104.302 41.5174H107.138L107.579 42.5967H103.869Z"
        fill="#04ACF4"
      />
      <path
        d="M86.019 43.9079V37.6641H88.7049C89.1173 37.6641 89.4648 37.7265 89.7475 37.8514C90.0361 37.9703 90.254 38.1457 90.4013 38.3776C90.5486 38.6096 90.6222 38.8861 90.6222 39.2072C90.6222 39.564 90.5338 39.8613 90.3571 40.0991C90.1804 40.337 89.9271 40.5005 89.5973 40.5897L89.5885 40.4113C89.8594 40.4767 90.098 40.5838 90.3041 40.7324C90.5103 40.8811 90.6693 41.0654 90.7812 41.2855C90.8931 41.5055 90.9491 41.7552 90.9491 42.0347C90.9491 42.3439 90.899 42.6175 90.7989 42.8553C90.6988 43.0872 90.5515 43.2805 90.3571 43.4351C90.1627 43.5897 89.933 43.7086 89.668 43.7919C89.4088 43.8692 89.1173 43.9079 88.7933 43.9079H86.019ZM87.2913 42.7483H88.7403C88.9288 42.7483 89.0878 42.7215 89.2174 42.668C89.3529 42.6086 89.4559 42.5194 89.5266 42.4004C89.5973 42.2815 89.6326 42.1418 89.6326 41.9812C89.6326 41.8266 89.5944 41.6958 89.5178 41.5887C89.4412 41.4817 89.3322 41.4014 89.1909 41.3479C89.0554 41.2884 88.8905 41.2587 88.6961 41.2587H87.2913V42.7483ZM87.2913 40.1973H88.5459C88.7049 40.1973 88.8434 40.1675 88.9612 40.1081C89.079 40.0486 89.1673 39.9683 89.2262 39.8672C89.291 39.7661 89.3234 39.6472 89.3234 39.5104C89.3234 39.2904 89.2527 39.1209 89.1114 39.002C88.97 38.8831 88.758 38.8236 88.4752 38.8236H87.2913V40.1973Z"
        fill="#04ACF4"
      />
      <path
        d="M71.2972 43.9969C70.8437 43.9969 70.4255 43.9167 70.0426 43.7561C69.6597 43.5955 69.324 43.3726 69.0354 43.0871C68.7526 42.7957 68.5347 42.4538 68.3816 42.0614C68.2284 41.6629 68.1519 41.2318 68.1519 40.768C68.1519 40.2982 68.2284 39.8701 68.3816 39.4836C68.5347 39.0911 68.7526 38.7492 69.0354 38.4578C69.324 38.1664 69.6597 37.9404 70.0426 37.7799C70.4255 37.6193 70.8437 37.5391 71.2972 37.5391C71.7566 37.5391 72.1748 37.6193 72.5518 37.7799C72.9347 37.9404 73.2674 38.1664 73.5502 38.4578C73.8388 38.7492 74.0597 39.0911 74.2128 39.4836C74.366 39.876 74.4425 40.3042 74.4425 40.768C74.4425 41.2318 74.366 41.66 74.2128 42.0524C74.0597 42.4449 73.8388 42.7868 73.5502 43.0782C73.2674 43.3696 72.9347 43.5955 72.5518 43.7561C72.1748 43.9167 71.7566 43.9969 71.2972 43.9969ZM71.2972 42.7303C71.5564 42.7303 71.7949 42.6828 72.0128 42.5876C72.2367 42.4925 72.4281 42.3557 72.5871 42.1773C72.7462 41.9989 72.8699 41.7908 72.9582 41.5529C73.0525 41.3151 73.0996 41.0534 73.0996 40.768C73.0996 40.4826 73.0525 40.2209 72.9582 39.9831C72.8699 39.7452 72.7462 39.5401 72.5871 39.3676C72.4281 39.1892 72.2367 39.0524 72.0128 38.9573C71.7949 38.8562 71.5564 38.8057 71.2972 38.8057C71.038 38.8057 70.7995 38.8562 70.5815 38.9573C70.3636 39.0524 70.1722 39.1892 70.0073 39.3676C69.8423 39.5401 69.7157 39.7452 69.6273 39.9831C69.5449 40.2209 69.5036 40.4826 69.5036 40.768C69.5036 41.0534 69.5449 41.3151 69.6273 41.5529C69.7157 41.7908 69.8423 41.9989 70.0073 42.1773C70.1722 42.3557 70.3636 42.4925 70.5815 42.5876C70.7995 42.6828 71.038 42.7303 71.2972 42.7303Z"
        fill="#04ACF4"
      />
      <path
        d="M54.6714 43.9079V37.6641H55.9967V42.7037H58.7974V43.9079H54.6714Z"
        fill="#04ACF4"
      />
      <path
        d="M40.9803 43.9936C40.5032 43.9936 40.0644 43.9133 39.6639 43.7528C39.2692 43.5863 38.9276 43.3573 38.639 43.066C38.3504 42.7746 38.1265 42.4327 37.9675 42.0402C37.8144 41.6477 37.7378 41.2226 37.7378 40.7647C37.7378 40.3068 37.8173 39.8846 37.9763 39.4981C38.1413 39.1056 38.371 38.7667 38.6655 38.4812C38.9659 38.1899 39.3164 37.9639 39.7169 37.8033C40.1174 37.6428 40.5562 37.5625 41.0333 37.5625C41.3632 37.5625 41.6783 37.6071 41.9787 37.6963C42.2791 37.7855 42.553 37.9074 42.8004 38.062C43.0478 38.2166 43.2539 38.395 43.4188 38.5972L42.5972 39.4713C42.444 39.3227 42.282 39.1978 42.1112 39.0967C41.9463 38.9897 41.7725 38.9094 41.5899 38.8559C41.4074 38.7964 41.2189 38.7667 41.0245 38.7667C40.7477 38.7667 40.4914 38.8172 40.2558 38.9183C40.0261 39.0194 39.8229 39.1591 39.6462 39.3375C39.4695 39.5159 39.3311 39.727 39.2309 39.9708C39.1367 40.2146 39.0896 40.4792 39.0896 40.7647C39.0896 41.0442 39.1396 41.3088 39.2398 41.5585C39.3458 41.8023 39.4872 42.0164 39.6639 42.2007C39.8465 42.3791 40.0585 42.5219 40.3 42.6289C40.5415 42.73 40.8007 42.7805 41.0775 42.7805C41.2719 42.7805 41.4574 42.7508 41.6341 42.6913C41.8108 42.6319 41.964 42.5486 42.0936 42.4416C42.229 42.3286 42.3321 42.1978 42.4028 42.0491C42.4794 41.9005 42.5176 41.7429 42.5176 41.5764V41.3623L42.6943 41.612H40.9273V40.4971H43.7899C43.7958 40.5625 43.8017 40.6428 43.8076 40.7379C43.8194 40.8331 43.8253 40.9282 43.8253 41.0233C43.8311 41.1125 43.8341 41.1839 43.8341 41.2374C43.8341 41.6418 43.7605 42.0134 43.6132 42.3524C43.4718 42.6913 43.2716 42.9827 43.0124 43.2265C42.7591 43.4703 42.4587 43.6606 42.1112 43.7974C41.7637 43.9282 41.3867 43.9936 40.9803 43.9936Z"
        fill="#04ACF4"
      />
      <path
        d="M108.99 30.3687V13.2266H112.595L118.643 23.3894L116.057 23.3651L122.177 13.2266H125.639V30.3687H121.726V25.0297C121.726 23.5603 121.757 22.2389 121.821 21.0629C121.901 19.8875 122.027 18.7202 122.2 17.5611L122.651 18.8345L118.05 26.2056H116.485L111.978 18.8835L112.429 17.5611C112.603 18.6549 112.721 19.7813 112.785 20.9405C112.863 22.0829 112.904 23.446 112.904 25.0297V30.3687H108.99Z"
        fill="#F1F1F1"
      />
      <path
        d="M93.7041 30.3687V13.2266H105.278V16.7774H97.6175V26.8178H105.515V30.3687H93.7041ZM95.6015 23.3409V19.961H104.21V23.3409H95.6015Z"
        fill="#F1F1F1"
      />
      <path
        d="M75.3833 30.3687V13.2266H82.3318C83.549 13.2266 84.6644 13.4388 85.6757 13.8633C86.6882 14.2714 87.5575 14.8591 88.2847 15.6265C89.0286 16.3938 89.5969 17.2999 89.993 18.3447C90.3879 19.3895 90.5859 20.5405 90.5859 21.7976C90.5859 23.0544 90.3879 24.2141 89.993 25.2745C89.5969 26.3199 89.0286 27.2256 88.2847 27.9937C87.5575 28.7445 86.6882 29.3325 85.6757 29.7564C84.6644 30.1642 83.549 30.3687 82.3318 30.3687H75.3833ZM79.4152 27.5282L78.8693 26.6953H82.2132C82.8778 26.6953 83.4707 26.581 83.992 26.3523C84.5145 26.1074 84.9564 25.7724 85.32 25.3485C85.7004 24.9245 85.9923 24.4093 86.1982 23.8052C86.404 23.2011 86.5058 22.5323 86.5058 21.7976C86.5058 21.0629 86.404 20.3936 86.1982 19.7895C85.9923 19.1855 85.7004 18.6713 85.32 18.2467C84.9564 17.8222 84.5145 17.4958 83.992 17.2672C83.4707 17.0223 82.8778 16.8999 82.2132 16.8999H78.7977L79.4152 16.1162V27.5282Z"
        fill="#F1F1F1"
      />
      <path
        d="M64.5382 30.6126C63.2102 30.6126 62.0087 30.392 60.9336 29.9519C59.8583 29.5106 58.9334 28.8903 58.1586 28.0898C57.3838 27.2905 56.7909 26.3514 56.3798 25.2736C55.9687 24.1797 55.7632 22.9969 55.7632 21.7232C55.7632 20.4498 55.9766 19.2826 56.4036 18.2213C56.8462 17.1438 57.4708 16.2051 58.2772 15.4051C59.0836 14.6052 60.0323 13.9848 61.1233 13.544C62.2301 13.1032 63.4317 12.8828 64.7283 12.8828C65.6457 12.8828 66.5071 13.0053 67.3138 13.2501C68.136 13.495 68.8789 13.8297 69.5434 14.2542C70.2068 14.6787 70.7684 15.1685 71.2271 15.7235L68.7838 18.3928C68.3889 18.001 67.9772 17.6744 67.5509 17.4132C67.1392 17.152 66.6973 16.9479 66.223 16.801C65.7486 16.6378 65.2418 16.5561 64.7049 16.5561C64.009 16.5561 63.3691 16.6868 62.7835 16.9479C62.2143 17.2091 61.7083 17.5764 61.2656 18.0499C60.8387 18.507 60.5067 19.054 60.2695 19.6907C60.0323 20.3111 59.9137 20.9886 59.9137 21.7232C59.9137 22.4574 60.0401 23.1436 60.2932 23.7801C60.5462 24.4165 60.894 24.9721 61.3368 25.4457C61.7794 25.9032 62.3012 26.2705 62.9021 26.5477C63.519 26.8088 64.1746 26.9393 64.8704 26.9393C65.3604 26.9393 65.8191 26.8573 66.2465 26.6944C66.6895 26.5316 67.0688 26.3109 67.3842 26.0337C67.7008 25.7553 67.9537 25.4296 68.1439 25.0542C68.3329 24.6614 68.428 24.2456 68.428 23.8043V23.1678L68.9505 24.0007H64.3726V20.7437H72.1758C72.2071 20.9233 72.2306 21.1682 72.2463 21.4783C72.2631 21.7885 72.2709 22.0901 72.2709 22.3847C72.2866 22.6781 72.2944 22.8987 72.2944 23.0454C72.2944 24.1555 72.0964 25.1766 71.7015 26.1065C71.3222 27.0213 70.7841 27.8126 70.0882 28.4826C69.3924 29.1514 68.5701 29.6735 67.6214 30.0489C66.6727 30.4243 65.6457 30.6126 64.5382 30.6126Z"
        fill="#F1F1F1"
      />
      <path
        d="M37.6992 30.3687V13.2266H41.3043L50.0085 25.4709L49.2259 25.3485C49.1468 24.8102 49.0836 24.295 49.0361 23.8052C48.9887 23.3154 48.9412 22.8338 48.8938 22.3613C48.8622 21.8874 48.8306 21.4058 48.7989 20.916C48.7832 20.4262 48.7673 19.8957 48.7515 19.3242C48.7515 18.7528 48.7515 18.1325 48.7515 17.4631V13.2266H52.6649V30.3687H49.0124L39.9287 17.7325L41.1382 17.9039C41.2173 18.6875 41.2805 19.3569 41.328 19.912C41.3912 20.4507 41.4386 20.9323 41.4703 21.3568C41.5177 21.7813 41.5494 22.1649 41.5651 22.508C41.5809 22.8511 41.5889 23.193 41.5889 23.5361C41.6046 23.863 41.6126 24.2303 41.6126 24.6381V30.3687H37.6992Z"
        fill="#F1F1F1"
      />
      <path
        d="M5.43929 40.7953L0 43.5372V17.7899C0 14.9274 1.59453 12.306 4.1279 11.0405L9.89504 8.13281V33.5035C9.90995 36.5919 8.16639 39.4092 5.43929 40.7953Z"
        fill="url(#paint0_linear_12674_1119)"
      />
      <path
        d="M9.91015 24.9009L7.16814 21.6468C3.51711 17.3078 4.87321 10.6639 9.91015 8.13281V24.9009Z"
        fill="url(#paint1_linear_12674_1119)"
      />
      <path
        d="M9.91016 8.11719V24.9004L17.6146 35.4012V18.6029L9.91016 8.11719Z"
        fill="#0F4B61"
      />
      <path
        d="M23.9626 32.2105L17.6143 35.4044V10.0338C17.6143 6.94529 19.3429 4.11294 22.0849 2.74196L27.5242 0V26.3951C27.5242 28.8508 26.1383 31.1107 23.9626 32.2105Z"
        fill="url(#paint2_linear_12674_1119)"
      />
      <path
        d="M17.6143 35.4076V18.6094L20.3563 21.8937C24.0073 26.2176 22.6512 32.8615 17.6143 35.4076Z"
        fill="url(#paint3_linear_12674_1119)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_12674_1119"
          x1="4.94836"
          x2="4.94836"
          y1="8.12409"
          y2="43.5327"
        >
          <stop stopColor="#0F4B61" />
          <stop offset="0.1273" stopColor="#0E516A" />
          <stop offset="0.3349" stopColor="#0D6182" />
          <stop offset="0.597" stopColor="#0A7BAA" />
          <stop offset="0.9006" stopColor="#059FE1" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_12674_1119"
          x1="7.50105"
          x2="7.50105"
          y1="24.8962"
          y2="8.12409"
        >
          <stop stopColor="#077BA8" />
          <stop offset="1" stopColor="#068CC4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_12674_1119"
          x1="22.5695"
          x2="22.5695"
          y1="35.4016"
          y2="-0.00696048"
        >
          <stop stopColor="#0F4B61" />
          <stop offset="0.1273" stopColor="#0E516A" />
          <stop offset="0.3349" stopColor="#0D6182" />
          <stop offset="0.597" stopColor="#0A7BAA" />
          <stop offset="0.9006" stopColor="#059FE1" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_12674_1119"
          x1="20.0202"
          x2="20.0202"
          y1="18.6019"
          y2="35.4048"
        >
          <stop stopColor="#077BA8" />
          <stop offset="1" stopColor="#068CC4" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

export const LogoDark = isGlobalProject() ? LogoDarkGlobal : LogoDarkFinance;
