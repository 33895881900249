import { useCallback, useState } from 'react';

import { useClientConfirmationData } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useConfirmationData = () => {
  const { goToBack, data, goToNext } = useOnboardingContext();

  const { confirm, error } = useClientConfirmationData(data.ownerClient!.id);
  const [loading, setLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    try {
      await confirm();
      setTimeout(goToNext, 300);
    } catch (error) {
      setLoading(false);
    }
  }, [confirm, goToNext]);

  return { onConfirm, goToBack, loading, error };
};
