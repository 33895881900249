import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
} from 'libs/form';

export const MAX_FILES = 10;

export interface SigningQuestionnaireFormType {
  fileIds: string[];
}

export const getSchema = (): ObjectSchema<SigningQuestionnaireFormType> =>
  object({
    fileIds: array().of(string().required()).required().min(1).max(MAX_FILES),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<SigningQuestionnaireFormType>();

export type FormErrors = FormErrorsBase<SigningQuestionnaireFormType>;
