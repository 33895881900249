import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const More = ({ size = 16, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        color={color}
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99988 4.38893C8.79764 4.38893 9.44435 3.74222 9.44435 2.94446C9.44435 2.14671 8.79764 1.5 7.99988 1.5C7.20213 1.5 6.55542 2.14671 6.55542 2.94446C6.55542 3.74222 7.20213 4.38893 7.99988 4.38893Z"
          fill="currentColor"
        />
        <path
          d="M7.99988 9.44447C8.79764 9.44447 9.44435 8.79776 9.44435 8.00001C9.44435 7.20225 8.79764 6.55554 7.99988 6.55554C7.20213 6.55554 6.55542 7.20225 6.55542 8.00001C6.55542 8.79776 7.20213 9.44447 7.99988 9.44447Z"
          fill="currentColor"
        />
        <path
          d="M7.99988 14.5C8.79764 14.5 9.44435 13.8533 9.44435 13.0555C9.44435 12.2578 8.79764 11.6111 7.99988 11.6111C7.20213 11.6111 6.55542 12.2578 6.55542 13.0555C6.55542 13.8533 7.20213 14.5 7.99988 14.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
