import { useEffect, useRef, useState } from 'react';

import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';

import {
  useClientCompleteOnboarding,
  useClientSaveInvoicePaymentConfirmationFiles,
  useOnboardingClientLazy,
} from '../../../../api/requests';
import { useFilesUpload } from '../../../../hooks';
import { InvoiceConfirmForPaymentFormType } from '../forms/invoiceConfirmForPaymentForm';

import { useClientInvoice } from './useClientInvoice';

export const useClientInvoiceConfirmForPayment = (client: ClientEntity) => {
  const { getInvoice, invoiceLink } = useClientInvoice(client);

  const { getClient } = useOnboardingClientLazy();

  const { saveInvoicePaymentConfirmationFiles, error } =
    useClientSaveInvoicePaymentConfirmationFiles();

  const { completeOnboarding } = useClientCompleteOnboarding();

  const [submitLoading, setSubmitLoading] = useState(false);

  const initialValues: InvoiceConfirmForPaymentFormType = useRef({
    fileIds: [],
  }).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload([]);

  const saveClientInvoiceConfirmForPayment = async (
    values: InvoiceConfirmForPaymentFormType
  ) => {
    setSubmitLoading(true);
    try {
      await saveInvoicePaymentConfirmationFiles(client.id!, values.fileIds);
      await completeOnboarding(client.id);
      await getClient(client?.id!);
    } catch (error) {
      setSubmitLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    getInvoice?.();
  }, [getInvoice]);

  useServerErrorNotify(error);

  return {
    saveClientInvoiceConfirmForPayment,
    files,
    invoiceLink,
    onRemoveFile,
    onAddFiles,
    submitDisabled,
    initialValues,
    submitLoading,
  };
};
