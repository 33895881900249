import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  OnboardingBiographyStep,
  OnboardingCitizenshipStep,
  OnboardingRegistrationAddressStep,
  OnboardingTaxResidencyStep,
  PepConnectionStep,
  FinancingSourcesStep,
  DebtsStep,
  WithdrawalAccountsStep,
  ConfirmationDataStep,
  SignatureSamplesStep,
  AccountsInOrganizationsStep,
  OrderSubmissionContactsStep,
  KycStep,
  SigningDocumentsStep,
  BusinessRelationshipStep,
  RiskProfileStep,
  FinancialPositionStep,
  KnowledgeAndExperienceStep,
  InvoiceForPaymentStep,
  InvoiceConfirmForPaymentStep,
} from './steps';
import { SourcesOfIncomeStep } from './steps/SourcesOfIncomeStep';
import { W8BenFormStep } from './steps/W8BenFormStep';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.ClientCitizenships]: OnboardingCitizenshipStep,
  [OnboardingProcessStepNameType.ClientTaxResidencies]:
    OnboardingTaxResidencyStep,
  [OnboardingProcessStepNameType.ClientAddresses]:
    OnboardingRegistrationAddressStep,
  [OnboardingProcessStepNameType.ClientKyc]: KycStep,
  [OnboardingProcessStepNameType.ClientBiography]: OnboardingBiographyStep,
  [OnboardingProcessStepNameType.ClientBankAccounts]:
    AccountsInOrganizationsStep,
  [OnboardingProcessStepNameType.ClientFinancingSources]: FinancingSourcesStep,
  [OnboardingProcessStepNameType.ClientPep]: PepConnectionStep,
  [OnboardingProcessStepNameType.ClientDebts]: DebtsStep,
  [OnboardingProcessStepNameType.ClientWithdrawalAccounts]:
    WithdrawalAccountsStep,
  [OnboardingProcessStepNameType.ClientOrderSubmissionContacts]:
    OrderSubmissionContactsStep,
  [OnboardingProcessStepNameType.ClientSignatureCards]: SignatureSamplesStep,
  [OnboardingProcessStepNameType.ClientConfirmDataAccuracy]:
    ConfirmationDataStep,
  [OnboardingProcessStepNameType.ClientSigningDocuments]: SigningDocumentsStep,
  [OnboardingProcessStepNameType.ClientBusinessRelationship]:
    BusinessRelationshipStep,
  [OnboardingProcessStepNameType.ClientRiskProfile]: RiskProfileStep,
  [OnboardingProcessStepNameType.ClientFinancialPosition]:
    FinancialPositionStep,
  [OnboardingProcessStepNameType.ClientW8BENForm]: W8BenFormStep,
  [OnboardingProcessStepNameType.ClientSourcesOfIncome]: SourcesOfIncomeStep,
  [OnboardingProcessStepNameType.ClientKnowledgeAndExperience]:
    KnowledgeAndExperienceStep,
  [OnboardingProcessStepNameType.ClientInvoice]: InvoiceForPaymentStep,
  [OnboardingProcessStepNameType.ClientInvoicePaymentConfirmationFiles]:
    InvoiceConfirmForPaymentStep,
};
