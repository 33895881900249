import { FormContainerStepProps } from 'components';
import { ObjectSchema } from 'libs/form';
import { useTranslation } from 'libs/i18n';

import {
  OnboardingRegistrationAddressFormType,
  FormErrors,
  getSchema,
  getBaseSchema,
  BaseOnboardingRegistrationAddressFormType,
} from '../../forms/onboardingRegistrationAddressForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent, FormContentProps } from './FormContent';
import { SubTitle } from './SubTitle';

interface OnboardingRegistrationAddressProps<T extends boolean>
  extends FormContentProps,
    FormContainerStepProps {
  initialValues?: T extends true
    ? OnboardingRegistrationAddressFormType
    : BaseOnboardingRegistrationAddressFormType;
  onSubmit: (
    values: T extends true
      ? OnboardingRegistrationAddressFormType
      : BaseOnboardingRegistrationAddressFormType
  ) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
  subTitle?: string;
  submitDisabled?: boolean;
  enabledPlaceOfBirth?: T;
  enabledRegistrationAddress?: boolean;
  title?: string;
}

export const OnboardingRegistrationAddress = <T extends boolean = true>({
  initialValues,
  canGoBack,
  submitLoading,
  formError,
  step,
  title,
  subTitle,
  clientType,
  submitDisabled,
  onGoBack,
  onSubmit,
  enabledPlaceOfBirth,
  enabledRegistrationAddress = true,
  ...formProps
}: OnboardingRegistrationAddressProps<T>) => {
  const { t } = useTranslation();

  const schema = (
    enabledPlaceOfBirth === false
      ? getBaseSchema(formProps.enabledFiles, enabledRegistrationAddress)
      : getSchema(formProps.enabledFiles, enabledRegistrationAddress)
  ) as ObjectSchema<
    T extends true
      ? OnboardingRegistrationAddressFormType
      : BaseOnboardingRegistrationAddressFormType
  >;

  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitleContent={<SubTitle subTitle={subTitle} />}
      title={title ?? t('onboarding.registrationAddress.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent
        {...formProps}
        enabledPlaceOfBirth={enabledPlaceOfBirth}
        enabledRegistrationAddress={enabledRegistrationAddress}
      />
    </FormBodyWrapper>
  );
};
