import { ClientSaveKnowledgeAndExperienceParam } from 'api/types/params/onboarding/clientSaveKnowledgeAndExperienceParam';

import { KnowledgeAndExperienceFormType } from '../../forms/knowledgeAndExperienceForm';

const parseFinancialInstruments = (
  v: KnowledgeAndExperienceFormType['financialInstruments']['bonds']
) =>
  v?.enabled
    ? {
        investmentAmount: v?.investmentAmount!,
        transactionCount: v.transactionCount!,
        yearOfFirstInvestment: v.yearOfFirstInvestment?.value!,
      }
    : undefined;

export const getSubmitData = (
  values: KnowledgeAndExperienceFormType
): ClientSaveKnowledgeAndExperienceParam => ({
  education: {
    educationLevel: values.education.educationLevel?.value!,
  },
  financialInstruments: {
    bonds: parseFinancialInstruments(values.financialInstruments?.bonds),
    derivatives: parseFinancialInstruments(
      values.financialInstruments?.derivatives
    ),
    mutualFunds: parseFinancialInstruments(
      values.financialInstruments?.mutualFunds
    ),
    stocks: parseFinancialInstruments(values.financialInstruments?.stocks),
    structuredProducts: parseFinancialInstruments(
      values.financialInstruments?.structuredProducts
    ),
  },
  futureOperations: {
    investmentDuration: values.futureOperations.investmentDuration?.value!,
    tradeAmount: values.futureOperations.tradeAmount?.value!,
    tradeFrequency: values.futureOperations.tradeFrequency?.value!,
  },
});
