import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  BasisForRepresentationStep,
  CitizenshipStep,
  RegistrationAddressStep,
  TaxResidencyStep,
  OwnerPersonalDataStep,
  OwnerCitizenshipStep,
  OwnerTaxResidencyStep,
  OwnerRegistrationAddressStep,
  BiographyStep,
  OwnerBiographyStep,
  PepConnectionStep,
  ConfirmationDataStep,
  AccountsInOrganizationsStep,
  SignatureSamplesStep,
  DebtsStep,
  KycStep,
  OwnerPepConnectionStep,
  OwnerWithdrawalAccountsStep,
  OwnerDebtsStep,
  OwnerAccountsInOrganizationsStep,
  OwnerFinancingSourcesStep,
  OwnerOrderSubmissionContactsStep,
  SigningDocumentsStep,
  OwnerSourcesOfIncomeStep,
  OwnerKnowledgeAndExperienceStep,
  OwnerFinancialPositionStep,
  OwnerBusinessRelationshipStep,
  OwnerRiskProfileStep,
  OwnerW8BenFormStep,
  OwnerInvoiceConfirmForPaymentStep,
  OwnerInvoiceForPaymentStep,
} from './steps';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.ClientRepresentativeBasisForRepresentation]:
    BasisForRepresentationStep,
  [OnboardingProcessStepNameType.ClientRepresentativeCreateClient]:
    OwnerPersonalDataStep,
  [OnboardingProcessStepNameType.ClientRepresentativeCitizenships]:
    CitizenshipStep,
  [OnboardingProcessStepNameType.ClientRepresentativeTaxResidencies]:
    TaxResidencyStep,
  [OnboardingProcessStepNameType.ClientRepresentativeAddresses]:
    RegistrationAddressStep,

  [OnboardingProcessStepNameType.ClientCitizenships]: OwnerCitizenshipStep,
  [OnboardingProcessStepNameType.ClientTaxResidencies]: OwnerTaxResidencyStep,
  [OnboardingProcessStepNameType.ClientAddresses]: OwnerRegistrationAddressStep,

  [OnboardingProcessStepNameType.ClientRepresentativeKyc]: KycStep,

  [OnboardingProcessStepNameType.ClientRepresentativeBiography]: BiographyStep,
  [OnboardingProcessStepNameType.ClientRepresentativeBankAccounts]:
    AccountsInOrganizationsStep,
  [OnboardingProcessStepNameType.ClientRepresentativePep]: PepConnectionStep,
  [OnboardingProcessStepNameType.ClientRepresentativeDebts]: DebtsStep,

  [OnboardingProcessStepNameType.ClientBiography]: OwnerBiographyStep,
  [OnboardingProcessStepNameType.ClientBankAccounts]:
    OwnerAccountsInOrganizationsStep,
  [OnboardingProcessStepNameType.ClientFinancingSources]:
    OwnerFinancingSourcesStep,
  [OnboardingProcessStepNameType.ClientPep]: OwnerPepConnectionStep,
  [OnboardingProcessStepNameType.ClientDebts]: OwnerDebtsStep,
  [OnboardingProcessStepNameType.ClientWithdrawalAccounts]:
    OwnerWithdrawalAccountsStep,
  [OnboardingProcessStepNameType.ClientOrderSubmissionContacts]:
    OwnerOrderSubmissionContactsStep,
  [OnboardingProcessStepNameType.ClientSignatureCards]: SignatureSamplesStep,
  [OnboardingProcessStepNameType.ClientConfirmDataAccuracy]:
    ConfirmationDataStep,
  [OnboardingProcessStepNameType.ClientSigningDocuments]: SigningDocumentsStep,
  [OnboardingProcessStepNameType.ClientSourcesOfIncome]:
    OwnerSourcesOfIncomeStep,
  [OnboardingProcessStepNameType.ClientKnowledgeAndExperience]:
    OwnerKnowledgeAndExperienceStep,
  [OnboardingProcessStepNameType.ClientFinancialPosition]:
    OwnerFinancialPositionStep,
  [OnboardingProcessStepNameType.ClientBusinessRelationship]:
    OwnerBusinessRelationshipStep,
  [OnboardingProcessStepNameType.ClientRiskProfile]: OwnerRiskProfileStep,
  [OnboardingProcessStepNameType.ClientW8BENForm]: OwnerW8BenFormStep,
  [OnboardingProcessStepNameType.ClientInvoice]: OwnerInvoiceForPaymentStep,
  [OnboardingProcessStepNameType.ClientInvoicePaymentConfirmationFiles]:
    OwnerInvoiceConfirmForPaymentStep,
};
