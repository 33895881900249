import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveInvoiceMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { CreateInvoiceParam } from 'api/types/params';

import { parseClientCreateInvoiceParam, parseClientEntity } from '../helpers';

export const useClientSaveInvoice = () => {
  const [saveInvoice, { data, loading, error: apolloError }] =
    useClientSaveInvoiceMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveInvoice
      ? parseClientEntity(data.clientSaveInvoice)
      : undefined,
    loading,
    error,
    saveInvoice: useCallback(
      async (id: string, params: CreateInvoiceParam) => {
        const res = await saveInvoice({
          variables: {
            id,
            input: parseClientCreateInvoiceParam(params),
          },
        });
        return res?.data?.clientSaveInvoice
          ? parseClientEntity(res.data.clientSaveInvoice)
          : undefined;
      },
      [saveInvoice]
    ),
  };
};
