import { PropsWithChildren, useEffect } from 'react';

import { VerificationCodeField } from 'libs/form/fields/VerificationCodeField';
import { useField } from 'libs/form/fields/useField';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Box, Dialog, DialogProps } from 'libs/ui';

import { DialogContent } from '../DialogContent';

interface Props<Values extends object> extends DialogProps {
  codeFieldName: Extract<keyof Values, string>;
  length?: number;
  title?: string;
  subtitle?: string;
}

export const VerificationCodeFormContentDialog = <Values extends object>({
  isOpen,
  codeFieldName,
  length = 6,
  children,
  title,
  subtitle,
  onClose,
  ...dialogProps
}: Props<Values> & PropsWithChildren) => {
  const { t } = useTranslation();

  const { handleSubmit, values, errors } = useFormContext<Values>();

  const { helpers } = useField(codeFieldName);

  useEffect(() => {
    const codeValue = values[codeFieldName];

    if (codeValue && String(codeValue).length === length) {
      handleSubmit();
    }
  }, [codeFieldName, handleSubmit, length, values]);

  useEffect(() => {
    const codeValue = values[codeFieldName];
    if (
      codeValue &&
      errors &&
      String(codeValue).length === length &&
      errors[codeFieldName]
    ) {
      helpers.setValue('');
    }
  }, [codeFieldName, errors, helpers, length, values]);

  useEffect(() => {
    if (!isOpen) {
      helpers.setValue('');
    }
  }, [helpers, isOpen]);

  return (
    <Dialog fullWidth isOpen={isOpen} onClose={onClose} {...dialogProps}>
      <DialogContent
        headerCentered
        subtitle={subtitle ?? t('components.verificationLogin.subtitle')}
        title={title ?? t('components.verificationLogin.title')}
        onClose={onClose}
      >
        <Box>
          {children}
          <VerificationCodeField
            key={String(isOpen)}
            length={length}
            name={codeFieldName}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
