import { useRef } from 'react';

import { useClientSaveW8BENForm } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { W8BenFormFormType } from 'modules/onboarding/common/forms/w8BenFormForm';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { getInitialData, getSubmitData } from '../helpers/w8BenForm';

export const useClientW8BenForm = (client: ClientEntity) => {
  const { t } = useTranslation();
  const { saveW8BenForm, error, loading } = useClientSaveW8BENForm();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialData = useRef(getInitialData(t, client.W8BENForm)).current;
  const initialValues = useRef(initialData.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const onSubmit = async (values: W8BenFormFormType) => {
    const data = getSubmitData(values);
    await saveW8BenForm(client.id, data);
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    onAddFiles,
    onRemoveFile,
    files,
    submitDisabled,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
