import { Typography } from '../Typography';

import { LinkStyled } from './LinkLabel.styles';
import { LinkLabelProps } from './types';

/**
 * Link label component.
 */
export const LinkLabel = ({
  label,
  typographyVariant,
  typographyVariantMobile,
  ...linkProps
}: LinkLabelProps) => (
  <LinkStyled {...linkProps}>
    <Typography
      className="link-label"
      variant={typographyVariant}
      variantMobile={typographyVariantMobile}
    >
      {label}
    </Typography>
  </LinkStyled>
);
