// eslint-disable-next-line no-restricted-imports
import { InvoiceFragment } from 'api/generated/graphql';
import { CurrencyType, InvoiceEntity } from 'api/types/entity';
import compact from 'lodash.compact';

import { parseFileEntity } from './parseFileEntity';

export const parseInvoice = (data: InvoiceFragment): InvoiceEntity => ({
  id: data.id,
  currency: CurrencyType[data.currency],
  privateFile: data.privateFile ? parseFileEntity(data.privateFile) : undefined,
  paymentConfirmationFiles: compact(data.paymentConfirmationFiles).map(
    parseFileEntity
  ),
});
