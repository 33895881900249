import { FC, useCallback, useLayoutEffect, useState } from 'react';

import { ClientIdentityType } from 'api/types/data';
import { LoadingLayout } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle } from 'libs/navigation';
import { useUser } from 'libs/providers';

import { OnboardingInfoView } from '../views';

export type OnboardingInfoContainerProps = {};

export const OnboardingInfoContainer: FC<OnboardingInfoContainerProps> = () => {
  const { navigate } = useNavigate();
  const { client } = useUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const goToOnbording = useCallback(() => {
    navigate(ROUTES.onboarding.fullPath, {}, { replace: true });
  }, [navigate]);

  let texts = t('onboarding-info.personalTexts', { returnObjects: true });

  useLayoutEffect(() => {
    setLoading(true);
    if (
      client?.isStartOnboareding ||
      client?.type === ClientIdentityType.ClientWithRepresentative ||
      client?.type === ClientIdentityType.LegalClientBeneficiary ||
      client?.type === ClientIdentityType.LegalClientHead ||
      client?.type === ClientIdentityType.LegalClientLegalHeadFirstHead
    ) {
      goToOnbording();
    } else {
      setLoading(false);
    }
  }, [client?.isStartOnboareding, client?.type, goToOnbording]);

  usePageTitle(t('onboarding.pageTitle'));

  if (loading) {
    return <LoadingLayout />;
  }

  if (client?.type === ClientIdentityType.LegalClientRepresentative) {
    texts = t('onboarding-info.legalTexts', { returnObjects: true });
  }

  return <OnboardingInfoView texts={texts} onOnboadring={goToOnbording} />;
};
