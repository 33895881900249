import { Hint } from 'components';
import { useTranslation } from 'libs/i18n';
import { Typography } from 'libs/ui';

import { HintContainer } from './styles';

export const SubTitle = () => {
  const { t } = useTranslation();

  return (
    <Typography as="p" datax="onboarding-subtitle" variantMobile="caption">
      {t('auth.registration.USTaxStatus.subtitle')}
      <HintContainer>
        <Hint
          title={
            <Typography variant="caption">
              {t('auth.registration.USTaxStatus.hint')}
            </Typography>
          }
        />
      </HintContainer>
    </Typography>
  );
};
