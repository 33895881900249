import { ClientEntity, OnboardingStatus } from 'api/types/entity';
import {
  useClientOnboardingStatus,
  useKycOnboardingStatus,
} from 'modules/onboarding/common/hooks';

export const useClientWithRepresentativeOnboardingStatus = (
  client: ClientEntity | null
): OnboardingStatus | undefined => {
  const onboardingStatus = useClientOnboardingStatus(
    client,
    'clientWithRepresentative'
  );
  const kycOboardingStatus = useKycOnboardingStatus(client?.kyc?.status);

  return onboardingStatus ?? kycOboardingStatus;
};
