import { PropsWithChildren } from 'react';

import { Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { Spacing } from 'libs/ui/Stack/types';

import { CloseButtonStyled, DialogContentStyled } from './DialogContent.styles';

export interface DialogContentProps {
  className?: string;
  hideCloseIcon?: boolean;
  headerCentered?: boolean;
  title: string;
  subtitle?: string;
  spacingContent?: Spacing;
  spacingMobileContent?: Spacing;
  onClose?: () => void;
}

export const DialogContent = ({
  className,
  hideCloseIcon,
  children,
  headerCentered,
  title,
  subtitle,
  spacingContent = 36,
  spacingMobileContent = 32,
  onClose,
}: DialogContentProps & PropsWithChildren) => (
  <DialogContentStyled className={className}>
    {!hideCloseIcon && (
      <CloseButtonStyled onClick={onClose}>
        <Icon.Close className="dialogContent-closeIcon" size={32} />
      </CloseButtonStyled>
    )}
    <Stack spacing={spacingContent} spacingMobile={spacingMobileContent}>
      <Stack
        alignItems={headerCentered ? 'center' : undefined}
        as="header"
        spacing={20}
      >
        <Typography
          align={headerCentered ? 'center' : undefined}
          as="h3"
          className="dialogContent-title"
          datax="dialog-title"
          variant="title2"
          variantMobile="subtitle"
        >
          {title}
        </Typography>
        {!!subtitle && (
          <Typography
            align={headerCentered ? 'center' : undefined}
            className="dialogContent-subtitle"
            datax="dialog-subtitle"
            variantMobile="caption"
          >
            {subtitle}
          </Typography>
        )}
      </Stack>

      {children}
    </Stack>
  </DialogContentStyled>
);
