import { RiskProfileType } from 'api/types/entity';
import { ObjectSchema, object, mixed, TypedForm } from 'libs/form';

export interface RiskProfileFormType {
  riskProfile: RiskProfileType;
}

export const getSchema = (): ObjectSchema<RiskProfileFormType> =>
  object({
    riskProfile: mixed<RiskProfileType>()
      .oneOf(Object.values(RiskProfileType))
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RiskProfileFormType>();
