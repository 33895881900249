import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveW8BenFormMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveW8BENFormParam } from 'api/types/params';

import { parseClientEntity } from '../helpers';

export const useClientSaveW8BENForm = () => {
  const [saveW8BenForm, { data, loading, error: apolloError }] =
    useClientSaveW8BenFormMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveW8BENForm
      ? parseClientEntity(data.clientSaveW8BENForm)
      : undefined,
    loading,
    error,
    saveW8BenForm: useCallback(
      async (id: string, params: SaveW8BENFormParam) => {
        const res = await saveW8BenForm({
          variables: { id, input: params },
        });
        return res.data?.clientSaveW8BENForm
          ? parseClientEntity(res.data.clientSaveW8BENForm)
          : undefined;
      },
      [saveW8BenForm]
    ),
  };
};
