import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
} from 'libs/form';

const CODE_LENGTH = 6;
export interface SigningDocumentsFormType {
  otpCode?: string;
}

export const getSchema = (): ObjectSchema<SigningDocumentsFormType> =>
  object({
    otpCode: string().min(CODE_LENGTH),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<SigningDocumentsFormType>();

export type FormErrors = FormErrorsBase<SigningDocumentsFormType>;
