import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { UserGenderType } from 'api/types/entity';
import { useObjectState } from 'hooks';

import { RegistrationType } from '../../types';

interface State {
  email?: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  surname?: string | null;
  dateOfBirth?: Date;
  representative?: boolean;
  gender?: UserGenderType;
}
interface Value {
  contextData: State;
  userId?: string;
  agreementIds: string[];
  registrationType: RegistrationType;
}

interface Actions {
  changeContextData: (v: State) => void;
  setUserId: (id: string) => void;
}

const Context = createContext<Value & Actions>(null as never);

export const useRegistrationContext = () => useContext(Context);

interface Props extends PropsWithChildren {
  agreementIds: string[];
  registrationType: RegistrationType;
}

export const RegistrationProvider: FC<Props> = ({
  children,
  agreementIds,
  registrationType,
}) => {
  const [contextData, setContextData] = useObjectState<State>({});
  const [userId, setUserId] = useState<string>();

  const store = useMemo(
    () => ({
      contextData,
      userId,
      agreementIds,
      registrationType,
      setUserId,
      changeContextData: setContextData,
    }),
    [contextData, userId, agreementIds, registrationType, setContextData]
  );

  return <Context.Provider value={store}>{children}</Context.Provider>;
};
