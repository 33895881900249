import { useRef } from 'react';

import { useClientRepresentativeSaveTaxResidences } from 'api/requests';
import { isGlobalProject } from 'config';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { OnboardingTaxResidencyFormType } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';
import {
  getInitialData,
  getSubmitDataWithFiles,
  getSubmitData,
} from 'modules/onboarding/common/helpers/taxResidency';
import {
  useBeforeSubmitTaxResidency,
  useOnboardingCountries,
} from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useTaxResidency = () => {
  const { saveTaxResidences, error, loading } =
    useClientRepresentativeSaveTaxResidences();
  const { goToBack, clientId, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialData = useRef(
    getInitialData(data.currentClient.taxResidences)
  ).current;
  const initialValues = useRef(initialData.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const onSubmit = useBeforeSubmitTaxResidency(
    async (values: OnboardingTaxResidencyFormType) => {
      const data = isGlobalProject()
        ? getSubmitDataWithFiles(values)
        : getSubmitData(values);
      await saveTaxResidences(clientId, data);
    }
  );

  useServerErrorNotify(error);

  return {
    onSubmit,
    onAddFiles,
    onRemoveFile,
    goToBack,
    files,
    submitDisabled,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
