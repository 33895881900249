// eslint-disable-next-line no-restricted-imports
import { Tooltip as TooltipBase, TooltipProps } from '@mui/material';
import { getColor } from 'helpers';

import { styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <TooltipBase {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  maxWidth: 504,

  '& .MuiTooltip-tooltip': {
    backgroundColor: getColor(theme, {
      light: ColorsLight.blue50,
      dark: ColorsDark.gray100,
    }),
    color: getColor(theme, {
      light: ColorsLight.black,
      dark: ColorsDark.black,
    }),
    padding: '8px 16px',
    borderRadius: 12,
    marginTop: 8,
    maxWidth: 600,
  },

  '.MuiTooltip-arrow': {
    color: getColor(theme, {
      light: ColorsLight.blue50,
      dark: ColorsDark.gray100,
    }),
  },

  '.tooltip-typography': {
    color: theme.palette.text.primary,
    whiteSpace: 'pre-wrap',
  },
}));
