import { FormLayout } from 'components';
import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

import { getColor } from '../../../../../helpers';
import { ColorsDark, ColorsLight } from '../../../../../libs/ui/theme';

export const FormLayoutStyled = styled(FormLayout)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  [theme.breakpoints.down('mobile')]: {
    backgroundColor: theme.palette.body,
  } as CSSObject,

  '.formLayout-content': {
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
    } as CSSObject,
  },

  '.registrationSelectType-content': {
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
    } as CSSObject,
  },
}));

export const ButtonsStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: 16,
  },
}));

export const HintStyled = styled('div')(({ theme }) => ({
  padding: 20,
  backgroundColor: getColor(theme, {
    dark: ColorsDark.gray50,
    light: ColorsLight.gray50,
  }),
  borderRadius: 12,

  [theme.breakpoints.down('mobile')]: {
    padding: 16,
  } as CSSObject,
}));
