import { useMemo } from 'react';

import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { renderMore, renderStatus } from './renders';
import { UserColumnArgs, UserItem } from './types';

export const getColumns = (
  t: TFunc,
  { onClickOption }: UserColumnArgs
): TableColumn<UserItem>[] => [
  {
    field: 'name',
    title: t('onboarding.waitingKyc.users.columns.name'),
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'email',
    title: t('onboarding.waitingKyc.users.columns.email'),
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'status' as 'name',
    title: t('onboarding.waitingKyc.users.columns.status'),
    width: 152,
    renderValue: (item) => renderStatus(t, item),
  },
  {
    field: 'more' as 'name',
    title: '',
    width: 56,
    align: 'center',
    renderCell: (item) => renderMore(t, item, onClickOption),
  },
];

export const useColumns = (args: UserColumnArgs) => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t, args), [args, t]);
};
