import { PropsWithChildren } from 'react';

import { useIsMobileSize } from 'hooks';

import { SwipeableDrawer } from '../SwipeableDrawer';

import { DialogSyled } from './Dialog.styles';
import { DialogProps } from './types';

/**
 * Dialog component.
 */
export const Dialog = ({
  isOpen,
  children,
  className,
  maxWidth = 'md',
  fullWidth,
  showPuller,
  onClose,
}: DialogProps & PropsWithChildren) => {
  const isMobile = useIsMobileSize();

  if (isMobile) {
    return (
      <SwipeableDrawer
        isOpen={isOpen}
        showPuller={showPuller}
        onClose={onClose}
      >
        {children}
      </SwipeableDrawer>
    );
  }

  return (
    <DialogSyled
      className={className}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={onClose}
    >
      {children}
    </DialogSyled>
  );
};
