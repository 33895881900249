import { RelationType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  pepConnectionRelationPath,
  PepConnectionRelationPath,
} from 'modules/onboarding/common/helpers/pepConnection/getInitialData';

import {
  Field,
  useFormContext,
} from '../../../forms/onboardingPepConnectionForm';

export interface FormContentProps {
  relationLabel?: string;
  positionPlaceholder?: string;
  notRequestInformation?: boolean;
  customPepConnectionRelationPath?: PepConnectionRelationPath;
}

export const FormContent = ({
  relationLabel,
  positionPlaceholder,
  notRequestInformation,
  customPepConnectionRelationPath,
}: FormContentProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isYes = values.relation?.value === RelationType.yesSpouses;

  return (
    <Stack spacing={32}>
      <Field.Autocomplete
        label={relationLabel ?? t('onboarding.pepConnection.relation.label')}
        name="relation"
        optionLabelKey="label"
        options={Object.values(RelationType).map((v) => ({
          label: t(
            customPepConnectionRelationPath
              ? customPepConnectionRelationPath[v]
              : pepConnectionRelationPath[v]
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('onboarding.pepConnection.relation.placeholder')}
        variant="select"
      />

      {!notRequestInformation && isYes && (
        <Stack spacing={24} spacingMobile={16}>
          <Typography variant="subtitle" variantMobile="bodyBold">
            {t('onboarding.pepConnection.infoRelationTitle')}
          </Typography>
          <Field.Text
            label={t('onboarding.pepConnection.firstName.label')}
            name="firstname"
            placeholder={t('onboarding.pepConnection.firstname.placeholder')}
            trim="start"
          />
          <Field.Text
            label={t('onboarding.pepConnection.lastname.label')}
            name="lastname"
            placeholder={t('onboarding.pepConnection.lastname.placeholder')}
            trim="start"
          />
          <Field.Text
            showOptionalMark
            label={t('onboarding.pepConnection.surname.label')}
            name="surname"
            placeholder={t('onboarding.pepConnection.surname.placeholder')}
            trim="start"
          />
          <Field.Text
            label={t('onboarding.pepConnection.workName.label')}
            name="workName"
            placeholder={t('onboarding.pepConnection.workName.placeholder')}
          />
          <Field.Text
            label={t('onboarding.pepConnection.position.label')}
            name="position"
            placeholder={
              positionPlaceholder ??
              t('onboarding.pepConnection.position.placeholder')
            }
          />
        </Stack>
      )}
    </Stack>
  );
};
