import {
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { ClientSaveSourcesOfIncomeParam } from 'api/types/params';

import { SourcesOfIncomeFormType } from '../../forms/sourcesOfIncomeForm';

export const getSubmitData = (
  values: SourcesOfIncomeFormType
): ClientSaveSourcesOfIncomeParam => {
  const incomeSources = values.incomeSources.map((v) => v.value);

  const data: ClientSaveSourcesOfIncomeParam = {
    occupation: values.occupation.value,
    incomeSources,
    otherIncomeSource: incomeSources.includes(SourcesOfIncomeSourceType.Other)
      ? values.otherIncomeSource
      : undefined,
    otherOccupation:
      values.occupation.value === SourcesOfIncomeOccupationType.Other
        ? values.otherOccupation
        : undefined,
    workplaceName:
      values.occupation.value === SourcesOfIncomeOccupationType.Employee ||
      values.occupation.value === SourcesOfIncomeOccupationType.BusinessOwner
        ? values.workplaceName
        : undefined,
    position:
      values.occupation.value === SourcesOfIncomeOccupationType.Employee ||
      values.occupation.value === SourcesOfIncomeOccupationType.BusinessOwner
        ? values.position
        : undefined,
  };
  return data;
};
