import { getColor } from 'helpers';
import { CSSObject } from 'styled-components';

import { styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

import { TableVariant } from './types';

interface Props {
  isCellClickable: boolean;
  overlayWrapperHeight?: number;
  variant?: TableVariant;
  isError?: boolean;
}

export const TableStyled = styled('div')<Props>(
  ({
    theme,
    isCellClickable,
    overlayWrapperHeight = 156,
    variant = 'standard',
    isError,
  }) => ({
    width: '100%',
    position: 'relative',

    '.table-dataGrid': {
      borderRadius: 16,

      border: `1px solid ${
        isError
          ? theme.palette.error.main
          : getColor(theme, {
              dark: ColorsDark.gray100,
              light: ColorsLight.gray150,
            })
      }`,
      background: 'none',

      '.table-main': {
        overflow: 'unset',
      },

      '.table-virtualScroller': {
        marginTop: '0!important',
      },

      '.table-columnHeaders': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background:
          variant === 'standard'
            ? getColor(theme, {
                dark: ColorsDark.gray50,
                light: ColorsLight.gray50,
              })
            : '',
        height: 44,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderColor: getColor(theme, {
          dark: ColorsDark.gray100,
          light: ColorsLight.gray150,
        }),

        '.table-columnHeaderTitle': {
          fontSize: 12,
          fontWeight: 600,
          color: getColor(theme, {
            dark: ColorsDark.gray250,
            light: ColorsLight.gray300,
          }),
        } as CSSObject,

        '.table-columnSeparator': {
          display: 'none',
        } as CSSObject,
      } as CSSObject,

      '.table-cell': {
        ...theme.typography.body,
      },

      '.table-row:hover': {
        cursor: isCellClickable ? 'pointer' : 'auto',
      },

      '.table-row-link': {
        color: 'inherit',
      },

      '.table-columnHeader:not(:first-child), .table-cell:not(:first-child)': {
        borderLeft: `1px solid  ${getColor(theme, {
          dark: ColorsDark.gray100,
          light: ColorsLight.gray150,
        })}`,
        borderColor: getColor(theme, {
          dark: ColorsDark.gray100,
          light: ColorsLight.gray150,
        }),
      } as CSSObject,

      '.table-columnHeader, .table-cell': {
        padding: variant === 'standard' ? '0 16px' : '0 12px',
      } as CSSObject,

      '.table-columnHeader:focus, .table-cell:focus, .table-columnHeader:focus-within, .table-cell:focus-within':
        {
          outline: 'none',
        } as CSSObject,

      '.table-row--lastVisible .table-cell': {
        borderBottom: 0,
        minHeight: 70,
      } as CSSObject,
      '.table-row--lastVisible': {
        minHeight: 70,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      } as CSSObject,

      '.table-footerContainer': {
        borderColor: getColor(theme, {
          dark: ColorsDark.gray100,
          light: ColorsLight.gray150,
        }),
        height: 66,
      } as CSSObject,
    } as CSSObject,

    '.table-overlayWrapper': {
      minHeight: overlayWrapperHeight,
      ...theme.typography.bodyMedium,
      color: isError ? theme.palette.error.main : undefined,
    } as CSSObject,
  })
);
