import { KnowledgeAndExperience } from 'modules/onboarding/common/components/KnowledgeAndExperience';

import { isGlobalProject } from '../../../../config';
import { useTranslation } from '../../../../libs/i18n';
import { ClientType } from '../../common/components';
import { useOwnerKnowledgeAndExperience } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerKnowledgeAndExperienceStep = ({
  canGoBack,
}: PageComponentProps) => {
  const { submitLoading, initialValues, goToBack, onSubmit } =
    useOwnerKnowledgeAndExperience();

  const { t } = useTranslation();

  return (
    <KnowledgeAndExperience
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={
        isGlobalProject()
          ? t('onboarding.knowledgeAndExperience.ownerSubtitle')
          : undefined
      }
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
