import { KnowledgeAndExperience } from 'modules/onboarding/common/components/KnowledgeAndExperience';

import { useKnowledgeAndExperience } from '../hooks';
import { PageComponentProps } from '../types';

export const KnowledgeAndExperienceStep = ({
  canGoBack,
}: PageComponentProps) => {
  const { submitLoading, initialValues, goToBack, onSubmit } =
    useKnowledgeAndExperience();

  return (
    <KnowledgeAndExperience
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
