import { Layout } from 'components';
import { CSSObject, styled } from 'libs/ui/styled';

export const LayoutStyled = styled(Layout)(({ theme }) => ({
  '.onboardingStatus-content': {
    maxWidth: 896,
    margin: 'auto',

    [theme.breakpoints.up('mobile')]: {
      justifyContent: 'center',
      padding: 64,
    },
    [theme.breakpoints.down('tablet')]: {
      maxWidth: '100%',
    } as CSSObject,
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
    } as CSSObject,
  },
}));
