import { useMemo } from 'react';

import { FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  getSchema,
  FormErrors,
  OnboardingPepConnectionFormType,
} from '../../forms/onboardingPepConnectionForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent, FormContentProps } from './FormContent';
import { SubTitle } from './SubTitle';

interface Props extends FormContentProps, FormContainerStepProps {
  initialValues?: OnboardingPepConnectionFormType;
  onSubmit: (values: OnboardingPepConnectionFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
  subTitle?: string;
}

export const OnboardingPepConnection = ({
  initialValues,
  canGoBack,
  submitLoading,
  formError,
  clientType,
  step,
  subTitle,
  onSubmit,
  onGoBack,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      getSchema(t, { notRequestInformation: formProps.notRequestInformation }),
    [formProps.notRequestInformation, t]
  );

  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitleContent={<SubTitle subTitle={subTitle} />}
      title={t('onboarding.pepConnection.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormBodyWrapper>
  );
};
