import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  number,
  boolean,
} from 'libs/form';

interface FinancialInstruments {
  volumeOfOperationsAnnually?: number;
  volumeOfOperationsMonthly?: number;
  enabled?: boolean;
}

export interface TargetedFinancialInstrumentsFormType {
  estimatedNumberOperationsMonthly?: number;
  estimatedNumberOperationsAnnually?: number;

  financialInstruments: {
    stocks?: FinancialInstruments;
    bonds?: FinancialInstruments;
    shares?: FinancialInstruments;
    derivatives?: FinancialInstruments;
    structural?: FinancialInstruments;
    others?: FinancialInstruments;
  };
}

const financialInstrumentsSchema = object({
  enabled: boolean().default(false),
  volumeOfOperationsAnnually: number().when('enabled', {
    is: true,
    then: (schema) => schema.required(),
  }),
  volumeOfOperationsMonthly: number().when('enabled', {
    is: true,
    then: (schema) => schema.required(),
  }),
});

export const getSchema =
  (): ObjectSchema<TargetedFinancialInstrumentsFormType> =>
    object({
      estimatedNumberOperationsMonthly: number().required(),
      estimatedNumberOperationsAnnually: number().required(),

      financialInstruments: object({
        stocks: financialInstrumentsSchema,
        bonds: financialInstrumentsSchema,
        shares: financialInstrumentsSchema,
        derivatives: financialInstrumentsSchema,
        structural: financialInstrumentsSchema,
        others: financialInstrumentsSchema,
      }),
    });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<TargetedFinancialInstrumentsFormType>();

export type FormErrors = FormErrorsBase<TargetedFinancialInstrumentsFormType>;
