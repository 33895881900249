import { LoadingView } from 'components';

import { SigningQuestionnaire } from '../components';
import { useSigningQuestionnaire } from '../hooks';
import { PageComponentProps } from '../types';

export const SigningQuestionnaireStep = ({ canGoBack }: PageComponentProps) => {
  const {
    initialValues,
    files,
    submitLoading,
    submitDisabled,
    questionnaireFileHref,
    onSubmit,
    goToBack,
    onAddFiles,
    onRemoveFile,
  } = useSigningQuestionnaire();

  if (!questionnaireFileHref) {
    return <LoadingView />;
  }

  return (
    <SigningQuestionnaire
      canGoBack={canGoBack}
      files={files}
      initialValues={initialValues}
      questionnaireFileHref={questionnaireFileHref}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
