import { CountryEntity } from 'api/types/entity';
import { NestedKeyOf, TypedForm } from 'libs/form';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

interface Props<T extends object, K = NestedKeyOf<Required<T>>> {
  countries: CountryEntity[];
  countriesLoading?: boolean;
  countryName: K;
  regionName: K;
  localityName: K;
  areaName: K;
  streetName: K;
  houseNumberName: K;
  housingName: K;
  apartmentName: K;
  postcodeName: K;
}

export const AddressesFields = <T extends object>({
  countriesLoading,
  countries,
  apartmentName,
  areaName,
  countryName,
  houseNumberName,
  housingName,
  localityName,
  postcodeName,
  regionName,
  streetName,
}: Props<T>) => {
  const { Field } = TypedForm<T>();

  const { t } = useTranslation();

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Autocomplete
        label={t('onboarding.registrationAddress.country.label')}
        loading={countriesLoading}
        name={countryName}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('onboarding.registrationAddress.country.placeholder')}
      />
      <Field.Text
        label={t('onboarding.registrationAddress.region.label')}
        name={regionName}
        placeholder={t('onboarding.registrationAddress.region.placeholder')}
      />
      <Field.Text
        label={t('onboarding.registrationAddress.locality.label')}
        name={localityName}
        placeholder={t('onboarding.registrationAddress.locality.placeholder')}
      />
      <Field.Text
        showOptionalMark
        label={t('onboarding.registrationAddress.area.label')}
        name={areaName}
        placeholder={t('onboarding.registrationAddress.area.placeholder')}
      />
      <Field.Text
        label={t('onboarding.registrationAddress.street.label')}
        name={streetName}
        placeholder={t('onboarding.registrationAddress.street.placeholder')}
      />

      <Stack
        direction="row-exceptMobile"
        justifyContent="space-between"
        spacing={24}
      >
        <Field.Text
          fullWidth
          label={t('onboarding.registrationAddress.houseNumber.label')}
          name={houseNumberName}
          placeholder={t(
            'onboarding.registrationAddress.houseNumber.placeholder'
          )}
        />
        <Field.Text
          fullWidth
          showOptionalMark
          label={t('onboarding.registrationAddress.housing.label')}
          name={housingName}
          placeholder={t('onboarding.registrationAddress.housing.placeholder')}
        />
      </Stack>

      <Stack
        direction="row-exceptMobile"
        justifyContent="space-between"
        spacing={24}
      >
        <Field.Text
          fullWidth
          showOptionalMark
          label={t('onboarding.registrationAddress.apartment.label')}
          name={apartmentName}
          placeholder={t(
            'onboarding.registrationAddress.apartment.placeholder'
          )}
        />
        <Field.Text
          fullWidth
          label={t('onboarding.registrationAddress.postcode.label')}
          name={postcodeName}
          placeholder={t('onboarding.registrationAddress.postcode.placeholder')}
        />
      </Stack>
    </Stack>
  );
};
