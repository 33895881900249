// eslint-disable-next-line no-restricted-imports
import { MenuItem } from '@mui/material';

import { CSSObject, styled } from '../styled';

export const MenuItemStyled = styled(MenuItem)(({ color, theme }) => ({
  color: color ?? theme.palette.text.primary,

  '.menu-item-label': {
    transition: theme.transitions.create(['color']),
  },
  '&:hover, &.Mui-selected:hover, &:hover .menu-item-icon': {
    color: theme.palette.hover,
  } as CSSObject,
}));
