import { useEffect, useRef, useState } from 'react';

import {
  useLegalClientRepresentativeCompleteOnboarding,
  useLegalClientSaveInvoicePaymentConfirmationFiles,
  useOnboardingLegalClientLazy,
} from 'api/requests';
import { useFilesUpload } from 'hooks';
import { InvoiceConfirmForPaymentFormType } from 'modules/onboarding/common/forms/invoiceConfirmForPaymentForm';
import { useClientInvoice } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useInvoiceConfirmForPayment = () => {
  const { data, goToBack, goToNext } = useOnboardingContext();

  const { getInvoice, invoiceLink } = useClientInvoice(data.legalClient);

  const { getLegalClient } = useOnboardingLegalClientLazy();

  const { saveInvoicePaymentConfirmationFiles } =
    useLegalClientSaveInvoicePaymentConfirmationFiles();

  const { completeOnboarding } =
    useLegalClientRepresentativeCompleteOnboarding();

  const [submitLoading, setSubmitLoading] = useState(false);

  const initialValues: InvoiceConfirmForPaymentFormType = useRef({
    fileIds: [],
  }).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload([]);

  const onSubmit = async (values: InvoiceConfirmForPaymentFormType) => {
    setSubmitLoading(true);
    try {
      await saveInvoicePaymentConfirmationFiles(
        data.legalClient?.id!,
        values.fileIds
      );
      await completeOnboarding(data.currentClient.id);
      await getLegalClient(data.legalClient?.id!);
    } catch (error) {
      setSubmitLoading(false);
      throw error;
    }
    goToNext();
  };

  useEffect(() => {
    getInvoice?.();
  }, [getInvoice]);

  return {
    onSubmit,
    files,
    invoiceLink,
    onRemoveFile,
    onAddFiles,
    goToBack,
    submitDisabled,
    initialValues,
    submitLoading,
  };
};
