import {
  ClientOnboardingStatusType,
  OnboardingProcessStepNameType,
} from 'api/types/entity';
import { LoadingLayout } from 'components';
import { config } from 'config';
import { useInterval } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';
import {
  OnboardingLayout,
  OnboardingStatus,
} from 'modules/onboarding/common/components';
import { STATUS_UPDATE_INTERVAL } from 'modules/onboarding/common/constants';
import { useClientOnboardingStatus } from 'modules/onboarding/common/hooks';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { WaitingOwnerKyc } from '../components';
import { useOnboarding } from '../hooks/useOnboarding';
import { useWaitingOwnerKyc } from '../hooks/useWaitingOwnerKyc';
import { pagesMap } from '../route';

interface Props {
  clientId: string;
}

export const RepresentativeContainer = ({ clientId }: Props) => {
  const {
    currentClient,
    ownerClient,
    loading,
    process,
    onGoBack,
    refetchCurrentClient,
    refetchOwnerClient,
  } = useOnboarding(clientId);

  const { t } = useTranslation();

  usePageTitle(t('onboarding.pageTitle'));

  const onboardingStatus = useClientOnboardingStatus(ownerClient, 'client');

  const {
    ownerClientUser,
    isWaitingOwnerKyc,
    formErrorSaveOwnerEmail,
    loadingOwnerEmailEdit,
    getOwnerClientUser,
    onEditOwnerEmail,
    sendOwnerLoginEmail,
  } = useWaitingOwnerKyc(currentClient, ownerClient);

  useInterval(
    () => {
      refetchOwnerClient();
    },
    onboardingStatus ? STATUS_UPDATE_INTERVAL : null
  );

  useInterval(
    () => {
      refetchOwnerClient();
      if (ownerClient?.id) {
        getOwnerClientUser(ownerClient.id);
      }
    },
    isWaitingOwnerKyc ? STATUS_UPDATE_INTERVAL : null
  );

  if (!currentClient || loading) {
    return <LoadingLayout />;
  }

  if (onboardingStatus) {
    return <OnboardingStatus onboardingStatus={onboardingStatus} />;
  }

  if (isWaitingOwnerKyc) {
    if (!ownerClientUser) {
      return <LoadingLayout />;
    }
    return (
      <WaitingOwnerKyc
        email={config.EMAIL_ONBOARDING ?? ''}
        initialEmailEditErrors={formErrorSaveOwnerEmail}
        loadingEmailEdit={loadingOwnerEmailEdit}
        owner={ownerClientUser}
        onEditEmail={onEditOwnerEmail}
        onResend={sendOwnerLoginEmail}
      />
    );
  }

  if (
    process?.currentStep.name ===
      OnboardingProcessStepNameType.ClientSigningDocuments &&
    ownerClient?.onboardingStatus !==
      ClientOnboardingStatusType.SigningDocuments
  ) {
    return <LoadingLayout />;
  }

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={{ currentClient, ownerClient, refetchCurrentClient }}
      pagesMap={pagesMap}
      process={process!}
      renderChild={({ route }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout
            key={route.pageKey}
            countSteps={process?.totalStepCount}
            currentStepIndex={route.pageNumber}
          >
            <Component {...route.page.componentProps} />
          </OnboardingLayout>
        );
      }}
      onGoBack={onGoBack}
    />
  );
};
