import { useMemo } from 'react';

import { CountryEntity } from 'api/types/entity';
import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';
import {
  Field,
  OnboardingTaxResidencyFormTaxValue,
  MAX_FILES,
} from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';

interface Props {
  index: number;
  value: OnboardingTaxResidencyFormTaxValue;
  countriesLoading?: boolean;
  countries: CountryEntity[];
  enabledFiles?: boolean;
  files?: FileUpload[];
  onAddFiles?: (file: File[], index: number) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const Fields = ({
  index,
  value,
  countriesLoading,
  countries,
  enabledFiles,
  files,
  onAddFiles,
  onRemoveFile,
}: Props) => {
  const { t } = useTranslation();

  const filteredFiles = useMemo(
    () => files?.filter((v) => v.data === index),
    [files, index]
  );

  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.taxResidency.country.label')}
        loading={countriesLoading}
        name={`taxValues.${index}.country` as 'taxValues'}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('onboarding.taxResidency.country.placeholder')}
      />

      {value.taxNumberIsMissing ? (
        <Field.Text
          label={t('onboarding.taxResidency.reasonForAbsence.label')}
          name={`taxValues.${index}.reasonForAbsence` as 'taxValues'}
          placeholder={t(
            'onboarding.taxResidency.reasonForAbsence.placeholder'
          )}
        />
      ) : (
        <>
          <Field.Text
            label={t('onboarding.taxResidency.taxNumber.label')}
            name={`taxValues.${index}.taxNumber` as 'taxValues'}
            placeholder={t('onboarding.taxResidency.taxNumber.placeholder')}
          />
          {enabledFiles && (
            <Field.FilesUpload
              files={filteredFiles}
              fileTypes={[FileType.pdf]}
              maxFiles={MAX_FILES}
              name={`taxValues.${index}.fileIds`}
              title={t('onboarding.taxResidency.documentTitle')}
              onAddFiles={(f) => onAddFiles?.(f, index)}
              onRemoveFile={onRemoveFile}
            />
          )}
        </>
      )}
    </>
  );
};
