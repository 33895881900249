import React from 'react';

import { useTranslation } from 'libs/i18n';
import { Link, Typography } from 'libs/ui';
import {
  WaitingKyc,
  WaitingKycUserType,
} from 'modules/onboarding/common/components';

import { FormErrors } from '../../forms/emailForm';

interface Props {
  email: string;
  owner: WaitingKycUserType;
  initialEmailEditErrors?: FormErrors;
  loadingEmailEdit?: boolean;
  onResend: (id: string) => void;
  onEditEmail: (id: string, email: string) => Promise<boolean>;
}

export const WaitingOwnerKyc = React.memo(
  ({
    email,
    owner,
    initialEmailEditErrors,
    loadingEmailEdit,
    onEditEmail,
    onResend,
  }: Props) => {
    const { t } = useTranslation();

    return (
      <WaitingKyc
        initialEmailEditErrors={initialEmailEditErrors}
        loadingEmailEdit={loadingEmailEdit}
        subtitle={
          <Typography datax="waitingKyc-subtitle">
            {t('onboarding.waitingOwnerKyc.text')}{' '}
            <Link href={`mailto: ${email}`} mode="text">
              {email}
            </Link>
            .
          </Typography>
        }
        users={[owner]}
        onEditEmail={onEditEmail}
        onResend={onResend}
      />
    );
  }
);
