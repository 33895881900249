// eslint-disable-next-line no-restricted-imports
import {
  IncomeSourcesDto_Input,
  Query_ClientController_GetById_IncomeSources_IncomeSources_Items as IncomeSourcesApi,
  Query_ClientController_GetById_IncomeSources_Occupation as OccupationTypeApi,
} from 'api/generated/graphql';
import {
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { ClientSaveSourcesOfIncomeParam } from 'api/types/params';

const incomeSources: Record<SourcesOfIncomeSourceType, IncomeSourcesApi> = {
  [SourcesOfIncomeSourceType.Salary]: IncomeSourcesApi.Salary,
  [SourcesOfIncomeSourceType.Savings]: IncomeSourcesApi.Savings,
  [SourcesOfIncomeSourceType.Inheritance]: IncomeSourcesApi.Inheritance,
  [SourcesOfIncomeSourceType.BusinessActivity]: IncomeSourcesApi.BusinessIncome,
  [SourcesOfIncomeSourceType.Other]: IncomeSourcesApi.Other,
};

const occupationTypes: Record<
  SourcesOfIncomeOccupationType,
  OccupationTypeApi
> = {
  [SourcesOfIncomeOccupationType.Employee]: OccupationTypeApi.Employee,
  [SourcesOfIncomeOccupationType.IndividualEntrepreneur]:
    OccupationTypeApi.Entrepreneur,
  [SourcesOfIncomeOccupationType.BusinessOwner]:
    OccupationTypeApi.BusinessOwner,
  [SourcesOfIncomeOccupationType.Student]: OccupationTypeApi.Student,
  [SourcesOfIncomeOccupationType.Retiree]: OccupationTypeApi.Retired,
  [SourcesOfIncomeOccupationType.Unemployed]: OccupationTypeApi.Unemployed,
  [SourcesOfIncomeOccupationType.Other]: OccupationTypeApi.Other,
};

export const parseSaveSourcesOfIncomeParam = (
  data: ClientSaveSourcesOfIncomeParam
): IncomeSourcesDto_Input => ({
  incomeSources: data.incomeSources.map((v) => incomeSources[v]),
  otherIncomeSource: data.otherIncomeSource || null,
  occupation: occupationTypes[data.occupation],
  otherOccupation: data.otherOccupation || null,
  workplaceName: data.workplaceName || null,
  position: data.position || null,
});
