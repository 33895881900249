import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { Section } from './Section';
import { SectionBox } from './SectionBox';

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={48} spacingMobile={36}>
      <Section
        title={t('onboarding.financialPosition.regularMonthlyIncome.title')}
      >
        <SectionBox sectionName="regularMonthlyIncome.work" />
        <SectionBox sectionName="regularMonthlyIncome.businessIncome" />
        <SectionBox sectionName="regularMonthlyIncome.investmentIncome" />
        <SectionBox sectionName="regularMonthlyIncome.other" />
      </Section>

      <Section title={t('onboarding.financialPosition.assetValue.title')}>
        <SectionBox sectionName="assetValue.liquidAssets" />
        <SectionBox sectionName="assetValue.securities" />
        <SectionBox sectionName="assetValue.realEstate" />
        <SectionBox sectionName="assetValue.other" />
      </Section>

      <Section title={t('onboarding.financialPosition.expenses.title')}>
        <SectionBox sectionName="expenses.livingExpenses" />
        <SectionBox sectionName="expenses.rent" />
        <SectionBox sectionName="expenses.loanRepayment" />
        <SectionBox sectionName="expenses.other" />
      </Section>

      <Section
        title={t('onboarding.financialPosition.financialLiabilities.title')}
      >
        <SectionBox sectionName="financialLiabilities.mortgageLoan" />
        <SectionBox sectionName="financialLiabilities.bankLoans" />
        <SectionBox sectionName="financialLiabilities.other" />
      </Section>
    </Stack>
  );
};
