// eslint-disable-next-line no-restricted-imports
import {
  KnowledgeAndExperienceDto_Input,
  Query_ClientController_GetById_KnowledgeAndExperience_Education_EducationLevel as EducationLevelApi,
  Query_ClientController_GetById_KnowledgeAndExperience_FutureOperations_InvestmentDuration as InvestmentDurationApi,
  Query_ClientController_GetById_KnowledgeAndExperience_FutureOperations_TradeFrequency as TradeFrequencyApi,
  Query_ClientController_GetById_KnowledgeAndExperience_FutureOperations_TradeAmount as TradeAmountApi,
} from 'api/generated/graphql';
import {
  EducationLevelType,
  InvestmentDurationType,
  KnowledgeAndExperienceFinancialInstrument,
  TradeAmountType,
  TradeFrequencyType,
} from 'api/types/entity';
import { ClientSaveKnowledgeAndExperienceParam } from 'api/types/params/onboarding/clientSaveKnowledgeAndExperienceParam';

const parseFinancialInstruments = (
  data?: KnowledgeAndExperienceFinancialInstrument | null
) =>
  data
    ? {
        investmentAmount: data.investmentAmount ?? undefined,
        transactionCount: data.transactionCount ?? undefined,
        firstInvestmentYear: data.yearOfFirstInvestment ?? undefined,
      }
    : undefined;

const educationLevelMap: Record<EducationLevelType, EducationLevelApi> = {
  [EducationLevelType.MasterOrPhdInFinance]:
    EducationLevelApi.MasterOrPhdInFinance,
  [EducationLevelType.BachelorInFinance]: EducationLevelApi.BachelorInFinance,
  [EducationLevelType.BachelorInOtherFields]:
    EducationLevelApi.BachelorInOtherFields,
  [EducationLevelType.SecondaryEducationInFinance]:
    EducationLevelApi.SecondaryEducationInFinance,
  [EducationLevelType.ProfessionalCertificateInFinance]:
    EducationLevelApi.ProfessionalCertificateInFinance,
  [EducationLevelType.PrimaryEducation]: EducationLevelApi.PrimaryEducation,
};

const investmentDurationMap: Record<
  InvestmentDurationType,
  InvestmentDurationApi
> = {
  [InvestmentDurationType.INTRADAY_TRADING]:
    InvestmentDurationApi.IntradayTrading,
  [InvestmentDurationType.LESS_THAN_YEAR]: InvestmentDurationApi.LessThanYear,
  [InvestmentDurationType.MORE_THAN_YEAR]: InvestmentDurationApi.MoreThanYear,
};

const tradeAmountMap: Record<TradeAmountType, TradeAmountApi> = {
  [TradeAmountType.LESS_THAN_10K]: TradeAmountApi.LessThan_10K,
  [TradeAmountType.BETWEEN_50K_100K]: TradeAmountApi.Between_50K_100K,
  [TradeAmountType.MORE_THAN_100K]: TradeAmountApi.MoreThan_100K,
};

const tradeFrequencyMap: Record<TradeFrequencyType, TradeFrequencyApi> = {
  [TradeFrequencyType.BETWEEN_1_AND_50]: TradeFrequencyApi.Between_1And_50,
  [TradeFrequencyType.BETWEEN_50_AND_100]: TradeFrequencyApi.Between_50And_100,
  [TradeFrequencyType.MORE_THAN_50]: TradeFrequencyApi.MoreThan_50,
};

export const parseKnowledgeAndExperienceParam = (
  data: ClientSaveKnowledgeAndExperienceParam
): KnowledgeAndExperienceDto_Input => ({
  education: {
    educationLevel: educationLevelMap[data.education.educationLevel],
  },
  financialInstruments: {
    bonds: parseFinancialInstruments(data.financialInstruments.bonds),
    derivatives: parseFinancialInstruments(
      data.financialInstruments.derivatives
    ),
    mutualFunds: parseFinancialInstruments(
      data.financialInstruments.mutualFunds
    ),
    stocks: parseFinancialInstruments(data.financialInstruments.stocks),
    structuredProducts: parseFinancialInstruments(
      data.financialInstruments.structuredProducts
    ),
  },
  futureOperations: {
    investmentDuration:
      investmentDurationMap[data.futureOperations.investmentDuration],
    tradeAmount: tradeAmountMap[data.futureOperations.tradeAmount],
    tradeFrequency: tradeFrequencyMap[data.futureOperations.tradeFrequency],
  },
});
