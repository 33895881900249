import { YesOrNoType } from 'types/yesOrNo';

import { ReregistrationUSTaxStatusFormType } from '../forms/registrationUSTaxStatusForm';
import { RegistrationStepProps } from '../types';

export const useRegistrationUSTaxStatus = ({
  onGoNext,
  onRejected,
}: Pick<RegistrationStepProps, 'onGoNext' | 'onRejected'>) => {
  const onSubmit = async (values: ReregistrationUSTaxStatusFormType) => {
    if (values.isUSTaxResident?.value === YesOrNoType.No) {
      onGoNext();
    } else {
      onRejected?.();
    }
  };

  return { onSubmit };
};
