import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  FinancialPositionFormType,
  FormErrors,
  getSchema,
} from '../../forms/financialPositionForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: FinancialPositionFormType;
  onSubmit: (values: FinancialPositionFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  clientType?: ClientType;
  subTitle?: string;
}

export const FinancialPosition = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  onGoBack,
  onSubmit,
  clientType,
  subTitle,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.financialPosition.subtitle')}
      title={t('onboarding.financialPosition.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <FormContent />
      </Stack>
    </FormBodyWrapper>
  );
};
