import { useTranslation } from 'libs/i18n';

import { RiskProfileFormType, getSchema } from '../../forms/riskProfileForm';
import { FormBodyWrapper, ClientType } from '../FormBodyWrapper';

import { FormContent, FormContentProps } from './FormContent';

interface RiskProfileProps extends FormContentProps {
  initialValues?: RiskProfileFormType;
  onSubmit: (values: RiskProfileFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  clientType?: ClientType;
  subTitle?: string;
}

export const RiskProfile = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  submitDisabled,
  onSubmit,
  subTitle,
  clientType,
  ...formProps
}: RiskProfileProps) => {
  const schema = getSchema();
  const { t } = useTranslation();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.riskProfile.subtitle')}
      title={t('onboarding.riskProfile.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormBodyWrapper>
  );
};
