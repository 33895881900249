import { SourcesOfIncome } from 'modules/onboarding/common/components';

import { useSourcesOfIncome } from '../hooks';
import { PageComponentProps } from '../types';

export const SourcesOfIncomeStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, submitLoading, onSubmit, goToBack } =
    useSourcesOfIncome();

  return (
    <SourcesOfIncome
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
