import { useMemo } from 'react';

import { FormContainer } from 'components';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';

import {
  getSchema,
  Field,
  ReregistrationUSTaxStatusFormType,
} from '../../forms/registrationUSTaxStatusForm';

import { SubTitle } from './SubTitle';

interface RegistrationUSTaxStatusProps {
  initialValues?: Partial<ReregistrationUSTaxStatusFormType>;
  onSubmit: (valie: ReregistrationUSTaxStatusFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
}

export const RegistrationUSTaxStatus = ({
  initialValues,
  canGoBack,
  onGoBack,
  onSubmit,
}: RegistrationUSTaxStatusProps) => {
  const { t } = useTranslation();

  const schema = useMemo(() => getSchema(), []);

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialValues={initialValues}
      subTitleContent={<SubTitle />}
      title={t('auth.registration.USTaxStatus.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Field.Autocomplete
        label={t('auth.registration.USTaxStatus.field.label')}
        name="isUSTaxResident"
        options={getYesOrNoOptions(t)}
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
    </FormContainer>
  );
};
