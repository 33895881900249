import { Hint } from 'components';
import { useTranslation } from 'libs/i18n';
import { ListTypography, Typography } from 'libs/ui';

import { HintContainer, HintContent } from './styles';

interface Props {
  subTitle?: string;
}

export const SubTitle = ({ subTitle }: Props) => {
  const { t } = useTranslation();

  return (
    <Typography>
      {subTitle ?? t('onboarding.registrationAddress.subtitle')}
      <HintContainer>
        <Hint
          title={
            <HintContent spacing={8}>
              <Typography className="hint-title" variant="bodyBold">
                {t('onboarding.registrationAddress.hint.title')}
              </Typography>
              <ListTypography
                listType="ul"
                texts={t('onboarding.registrationAddress.hint.texts', {
                  returnObjects: true,
                })}
                variant="caption"
              />
            </HintContent>
          }
        />
      </HintContainer>
    </Typography>
  );
};
