import { useCallback, useState } from 'react';

import { useFileDownload } from 'api/requests';

import { InvoiceEntity } from '../../../../api/types/entity';

interface Client {
  invoice?: InvoiceEntity;
}

export const useClientInvoice = (client?: Client | null) => {
  const { getFileLink, loading } = useFileDownload();
  const [invoiceLink, setInvoiceLink] = useState<string | null>(null);

  const getInvoice = useCallback(async () => {
    if (client?.invoice?.privateFile?.id) {
      const link = await getFileLink(client.invoice.privateFile.id);
      if (link) {
        setInvoiceLink(link);
      }
    }
  }, [client?.invoice?.privateFile?.id, getFileLink]);

  return {
    getInvoice,
    invoiceLink,
    loading,
  };
};
