import { isFinanceProject, isGlobalProject } from 'config';
import {
  OnboardingRegistrationAddress,
  ClientType,
} from 'modules/onboarding/common/components';

import { useTranslation } from '../../../../libs/i18n';
import { useOwnerRegistrationAddress } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerRegistrationAddressStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    files,
    submitDisabled,
    onSubmit,
    goToBack,
    onAddFiles,
    onRemoveFile,
  } = useOwnerRegistrationAddress();

  const { t } = useTranslation();

  return (
    <OnboardingRegistrationAddress
      enabledFiles
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      countries={countries}
      countriesLoading={countriesLoading}
      enabledRegistrationAddress={isFinanceProject()}
      files={files}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={
        isGlobalProject()
          ? t('onboarding.registrationAddress.ownerSubtitle')
          : undefined
      }
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
