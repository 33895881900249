import { useTranslation } from 'libs/i18n';
import { Button, Stack } from 'libs/ui';

import { DialogContentStyled } from './ConfirmationDialog.styles';
import { ConfirmationDialogContentProps } from './types';

export const ConfirmationDialogContent = ({
  title,
  subtitle,
  confirmButtonLabel,
  backButtonLabel,
  children,
  className,
  loading,
  headerCentered,
  buttonConfirm,
  confirmButtonColorVariant,
  onConfirm,
  onBack,
  ...dialogContentProps
}: ConfirmationDialogContentProps) => {
  const { t } = useTranslation();

  const ButtonConfirm = buttonConfirm ?? Button;

  return (
    <DialogContentStyled
      {...dialogContentProps}
      hideCloseIcon
      className={className}
      headerCentered={headerCentered}
      subtitle={subtitle}
      title={title}
      onClose={onBack}
    >
      {children}

      <Stack spacing={12}>
        <ButtonConfirm
          colorVariant={confirmButtonColorVariant}
          datax="confirmDialog-buttonConfirm"
          label={confirmButtonLabel ?? t('common.confirm')}
          loading={loading}
          onClick={onConfirm}
        />
        <Stack alignItems="center">
          <Button
            datax="confirmDialog-buttonBack"
            label={backButtonLabel ?? t('common.back')}
            mode="text"
            onClick={onBack}
          />
        </Stack>
      </Stack>
    </DialogContentStyled>
  );
};
