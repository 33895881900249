import { FC } from 'react';

import { OnboardingStatus as OnboardingStatusType } from 'api/types/entity';
import { OnboardingStatusDefault } from 'components';
import { config } from 'config';
import { useTranslation } from 'libs/i18n';

export type OnboardingStatusProps = {
  onboardingStatus: OnboardingStatusType;
};

export const OnboardingStatus: FC<OnboardingStatusProps> = ({
  onboardingStatus,
}) => {
  const { t } = useTranslation();

  const email =
    onboardingStatus === 'success'
      ? config.EMAIL_ORDER
      : config.EMAIL_ONBOARDING;

  return (
    <OnboardingStatusDefault
      email={email ?? ''}
      text={t(`onboarding-status.${onboardingStatus}.text`)}
      title={t(`onboarding-status.${onboardingStatus}.title`)}
    />
  );
};
