import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveSignedQuestionnaireFilesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveSignedQuestionnaireFiles = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveSignedQuestionnaireFilesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveSignedQuestionnaireFiles
      ? parseLegalClient(data.legalClientSaveSignedQuestionnaireFiles)
      : undefined,
    loading,
    error,
    saveSignedQuestionnaireFiles: useCallback(
      async (id: string, signedQuestionnaireFileIds: string[]) => {
        const res = await handle({
          variables: {
            id,
            signedQuestionnaireFileIds,
          },
        });
        return res?.data?.legalClientSaveSignedQuestionnaireFiles
          ? parseLegalClient(res.data.legalClientSaveSignedQuestionnaireFiles)
          : undefined;
      },
      [handle]
    ),
  };
};
