import { useTranslation } from 'libs/i18n';
import { LinkLabel, Typography } from 'libs/ui';

const LINK = 'https://www.irs.gov/pub/irs-pdf/fw8ben.pdf';

export const SubTitle = () => {
  const { t } = useTranslation();

  const subTitle = t('onboarding.W8BENForm.subtitle', { returnObjects: true });

  return (
    <Typography variantMobile="caption">
      {subTitle.map((t) => {
        if (t.type === 'link') {
          return (
            <LinkLabel
              href={LINK}
              label={t.value}
              mode="text"
              target="_blank"
              typographyVariantMobile="caption"
            />
          );
        }
        return t.value;
      })}
    </Typography>
  );
};
