import { useEffect } from 'react';

import { RiskProfileType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { Box, Checkbox, Typography } from 'libs/ui';

import { useFormContext } from '../../../forms/riskProfileForm';

import { ContentStyled, ItemStyled } from './styles';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();
  const notify = useNotify();

  const { values, setValues, errors, setErrors } = useFormContext();

  const closingSetValue = (riskProfile: RiskProfileType) => () => {
    setValues({ riskProfile });
  };

  useEffect(() => {
    if (errors.riskProfile) {
      setErrors({});
      notify.show('error', t('onboarding.riskProfile.errorMessage'));
    }
  }, [errors, notify, setErrors, t]);

  const data = [
    RiskProfileType.ConservativeIncome,
    RiskProfileType.ModerateGrowth,
    RiskProfileType.BalancedGrowth,
    RiskProfileType.AggressiveGrowth,
    RiskProfileType.SpeculativeGrowth,
  ];

  return (
    <ContentStyled as="ul" spacing={16} spacingMobile={12}>
      {data.map((value) => (
        <ItemStyled
          as="li"
          datax={value}
          direction="row"
          spacing={8}
          spacingMobile={4}
        >
          <Box>
            <Checkbox
              checked={values.riskProfile === value}
              onChange={closingSetValue(value)}
            />
          </Box>
          <Typography>{t(`onboarding.riskProfile.types.${value}`)}</Typography>
        </ItemStyled>
      ))}
    </ContentStyled>
  );
};
