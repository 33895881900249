import {
  InvestmentDurationType,
  TradeAmountType,
  TradeFrequencyType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/onboarding/common/forms/knowledgeAndExperienceForm';

export const FutureOperationsFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t(
          `onboarding.knowledgeAndExperience.futureOperations.investmentDuration.label`
        )}
        name="futureOperations.investmentDuration"
        optionLabelKey="label"
        options={Object.values(InvestmentDurationType).map((v) => ({
          label: t(
            `onboarding.knowledgeAndExperience.futureOperations.investmentDuration.types.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(`common.select.placeholder`)}
        variant="select"
      />

      <Field.Autocomplete
        label={t(
          `onboarding.knowledgeAndExperience.futureOperations.tradeFrequency.label`
        )}
        name="futureOperations.tradeFrequency"
        optionLabelKey="label"
        options={Object.values(TradeFrequencyType).map((v) => ({
          label: t(
            `onboarding.knowledgeAndExperience.futureOperations.tradeFrequency.types.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(`common.select.placeholder`)}
        variant="select"
      />

      <Field.Autocomplete
        label={t(
          `onboarding.knowledgeAndExperience.futureOperations.tradeAmount.label`
        )}
        name="futureOperations.tradeAmount"
        optionLabelKey="label"
        options={Object.values(TradeAmountType).map((v) => ({
          label: t(
            `onboarding.knowledgeAndExperience.futureOperations.tradeAmount.types.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(`common.select.placeholder`)}
        variant="select"
      />
    </>
  );
};
