export enum FileTypeName {
  Unknown = 'Unknown',
  AdditionalDocument = 'AdditionalDocument',
  AdditionalInformation = 'AdditionalInformation',
  BrokerageAgreementApplication = 'BrokerageAgreementApplication',
  IncomeSource = 'IncomeSource',
  OpenSubAccountOrder = 'OpenSubAccountOrder',
  ProofOfResidence = 'ProofOfResidence',
  Selfie = 'Selfie',
  SignaturesCard = 'SignaturesCard',
  SignedBrokerageAgreementApplication = 'SignedBrokerageAgreementApplication',
  SignedOpenSubAccountOrder = 'SignedOpenSubAccountOrder',
  TaxResidence = 'TaxResidence',
  TermsOfBrokerageServices = 'TermsOfBrokerageServices',
  W8BENForm = 'W8BENForm',
  WithdrawalAccountOwnerConfirmation = 'WithdrawalAccountOwnerConfirmation',
}
export interface FileEntity {
  id: string;
  name: string;
  size: number;
  type?: FileTypeName;
}
