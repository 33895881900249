// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetById__Dollar_1cInvoiceCurrency as CurrencyApi,
  Query_ClientController_GetById__Dollar_1cInvoiceCurrency as CurrencyApi1,
} from 'api/generated/graphql';
import { CurrencyInvoiceType } from 'api/types/entity';

const currency: Record<CurrencyApi | CurrencyApi1, CurrencyInvoiceType> = {
  [CurrencyApi.Eur]: CurrencyInvoiceType.EUR,
  [CurrencyApi.Kzt]: CurrencyInvoiceType.KZT,
  [CurrencyApi.Rub]: CurrencyInvoiceType.RUB,
  [CurrencyApi.Usd]: CurrencyInvoiceType.USD,
};

export const parseInvoiceCurrency = (
  data: CurrencyApi | CurrencyApi1
): CurrencyInvoiceType => currency[data];
