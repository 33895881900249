import { useTranslation } from 'libs/i18n';
import { OnboardingConfirmationDataDialog } from 'modules/onboarding/common/components';

import { useConfirmationData } from '../hooks';

export const ConfirmationDataStep = () => {
  const { t } = useTranslation();

  const { onConfirm, goToBack, loading } = useConfirmationData();

  return (
    <OnboardingConfirmationDataDialog
      isOpen
      loading={loading}
      texts={t('onboarding.confirmationDataDialog.texts', {
        returnObjects: true,
      })}
      onBack={goToBack}
      onConfirm={onConfirm}
    />
  );
};
