import { useCallback, useEffect, useState } from 'react';

import {
  useLegalClientRepresentativeSettingsSave,
  useOnboardingLegalClientLazy,
  useOnboardingLegalClientRepresentative,
} from 'api/requests';

import { useInnerState } from './useInnerState';

export const useOnboarding = (clientId: string) => {
  const [mainLoading, setMainLoading] = useState(true);

  const {
    currentClient,
    loading,
    refetch: refetchCurrentClient,
  } = useOnboardingLegalClientRepresentative(clientId);

  const { saveSettings } = useLegalClientRepresentativeSettingsSave();

  const {
    getLegalClient,
    refetch: refetchLegalClient,
    legalClient,
  } = useOnboardingLegalClientLazy();

  const [process, setProcess] = useState(currentClient?.onboarding?.process);

  const innerState = useInnerState();

  const onGoBack = useCallback(
    async (name: string) => {
      if (currentClient) {
        await saveSettings(currentClient.id, name);
      }
    },
    [currentClient, saveSettings]
  );

  useEffect(() => {
    if (currentClient?.legalClientId) {
      getLegalClient(currentClient?.legalClientId).finally(() => {
        setMainLoading(false);
      });
    }
  }, [currentClient?.legalClientId, getLegalClient]);

  useEffect(() => {
    setProcess(currentClient?.onboarding?.process);
  }, [currentClient?.onboarding?.process]);

  useEffect(() => {
    if (legalClient?.onboarding?.process) {
      setProcess(legalClient?.onboarding?.process);
    }
  }, [legalClient?.onboarding?.process]);

  return {
    currentClient,
    legalClient,
    loading: mainLoading || (!currentClient && loading),
    innerState,
    process,
    refetchLegalClient,
    refetchCurrentClient,
    onGoBack,
  };
};
