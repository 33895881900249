import { CountryEntity } from 'api/types/entity';
import { FileUpload, Stack } from 'libs/ui';

import { useFormContext } from '../../../forms/onboardingTaxResidencyForm';

import { Header, Fields, Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  enabledFiles?: boolean;
  files?: FileUpload[];
  onAddFiles?: (file: File[], index: number) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  countriesLoading,
  countries,
  files,
  onAddFiles,
  onRemoveFile,
  enabledFiles,
}: FormContentProps) => {
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.taxValues.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24} spacingMobile={16}>
            <Header index={index} />

            <Fields
              countries={countries}
              countriesLoading={countriesLoading}
              enabledFiles={enabledFiles}
              files={files}
              index={index}
              value={value}
              onAddFiles={onAddFiles}
              onRemoveFile={onRemoveFile}
            />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
