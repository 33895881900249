import {
  CountryEntity,
  RegistrationAddressEntity,
  ResidentialAddressEntity,
} from 'api/types/entity';
import { FileUpload } from 'hooks';
import {
  BaseOnboardingRegistrationAddressFormType,
  OnboardingRegistrationAddressFormType,
} from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import { Maybe } from 'types/maybe';

interface Props {
  placeOfBirth?: Maybe<{
    country: Maybe<CountryEntity>;
    locality: string;
  }>;
  registrationAddress?: Maybe<RegistrationAddressEntity>;
  residentialAddress?: Maybe<ResidentialAddressEntity>;
}

export const getBaseInitialData = (
  registrationAddress?: Maybe<RegistrationAddressEntity>,
  residentialAddress?: Maybe<ResidentialAddressEntity>
) => {
  const values: BaseOnboardingRegistrationAddressFormType = {
    countryRegistration: registrationAddress?.country,
    localityRegistration: registrationAddress?.locality,
    regionRegistration: registrationAddress?.region,
    areaRegistration: registrationAddress?.district || undefined,
    streetRegistration: registrationAddress?.street,
    houseNumberRegistration: registrationAddress?.house,
    housingRegistration: registrationAddress?.building || undefined,
    apartmentRegistration: registrationAddress?.apartment || undefined,
    postcodeRegistration: registrationAddress?.postalCode,
    residentialAddressIsSame: !residentialAddress,
  };

  values.countryResidence = residentialAddress?.country;
  values.localityResidence = residentialAddress?.locality;
  values.regionResidence = residentialAddress?.region;
  values.areaResidence = residentialAddress?.district || undefined;
  values.streetResidence = residentialAddress?.street;
  values.houseNumberResidence = residentialAddress?.house;
  values.housingResidence = residentialAddress?.building || undefined;
  values.apartmentResidence = residentialAddress?.apartment || undefined;
  values.postcodeResidence = residentialAddress?.postalCode;
  values.proofOfResidenceFileIds =
    residentialAddress?.proofOfResidenceFiles?.map((v) => v.id);

  const files: FileUpload[] | undefined =
    residentialAddress?.proofOfResidenceFiles?.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  return { values, files };
};

export const getInitialData = (client: Props) => {
  if (!client.placeOfBirth) {
    return undefined;
  }

  const baseValues = getBaseInitialData(
    client.registrationAddress,
    client.residentialAddress
  );

  const values: OnboardingRegistrationAddressFormType = {
    ...baseValues.values,
    countryOfBirth: client.placeOfBirth.country,
    localityOfBirth: client.placeOfBirth.locality,
  };

  return { values, files: baseValues.files };
};
