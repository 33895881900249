import { RelationType } from 'api/types/entity';
import { SavePepConnectionParam } from 'api/types/params';

import { OnboardingPepConnectionFormType } from '../../forms/onboardingPepConnectionForm';

export const getSubmitData = (
  values: OnboardingPepConnectionFormType,
  notRequestInformation?: { notRequestInformation: boolean }
): SavePepConnectionParam => ({
  connectedPepInfo:
    !notRequestInformation && values.relation?.value === RelationType.yesSpouses
      ? {
          employerName: values.position!,
          firstName: values.firstname!,
          jobTitle: values.workName!,
          middleName: values.surname,
          lastName: values.lastname!,
        }
      : null,
  relation: values.relation?.value!,
});
