import { DialogContent } from 'components';
import { useTranslation } from 'libs/i18n';
import { Dialog, DialogStateProps, Pressed, Stack } from 'libs/ui';

import { Item } from './Item';
import { ContentStyled, LineStyled } from './styles';

interface Props extends DialogStateProps {
  onClickPerson: () => void;
  onClickLegal: () => void;
}

export const HeadAddDialog = ({
  isOpen,
  onClose,
  onClickPerson,
  onClickLegal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth isOpen={isOpen} maxWidth="lg" onClose={onClose}>
      <DialogContent
        spacingContent={48}
        title={t('onboarding.legalClientHeads.addDialog.title')}
        onClose={onClose}
      >
        <ContentStyled>
          <Stack spacing={24}>
            <Pressed datax="headsDialog-addPerson" onClick={onClickPerson}>
              <Item title={t('onboarding.legalClientHeads.addDialog.person')} />
            </Pressed>
            <LineStyled />
            <Pressed datax="headsDialog-addLegal" onClick={onClickLegal}>
              <Item title={t('onboarding.legalClientHeads.addDialog.legal')} />
            </Pressed>
          </Stack>
        </ContentStyled>
      </DialogContent>
    </Dialog>
  );
};
