// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_AuthController_Register_Input_Gender,
  MutationInput_AuthController_Register_Input_Type,
  RegisterDto_Input,
} from 'api/generated/graphql';
import { RegistrationParam, RegistrationUserType } from 'api/types/params';
import { formatToISODate } from 'helpers';

const userType: Record<
  RegistrationUserType,
  MutationInput_AuthController_Register_Input_Type
> = {
  [RegistrationUserType.Client]:
    MutationInput_AuthController_Register_Input_Type.Client,
  [RegistrationUserType.ClientRepresentative]:
    MutationInput_AuthController_Register_Input_Type.ClientRepresentative,
  [RegistrationUserType.LegalClientRepresentative]:
    MutationInput_AuthController_Register_Input_Type.LegalClientRepresentative,
};

export const parseParams = (params: RegistrationParam): RegisterDto_Input => ({
  ...params,
  gender: params.gender
    ? MutationInput_AuthController_Register_Input_Gender[params.gender]
    : undefined,
  type: userType[params.type],
  dateOfBirth: formatToISODate(params.dateOfBirth),
});
