import { useRef } from 'react';

import { useClientSaveRiskProfile } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';

import { RiskProfileFormType } from '../forms/riskProfileForm';

export const useClientRiskProfile = (client: ClientEntity) => {
  const {
    loading: submitLoading,
    error,
    saveRiskProfile: saveRiskProfileApi,
  } = useClientSaveRiskProfile();

  const initialValues = useRef(
    client.riskProfile ? { riskProfile: client.riskProfile } : undefined
  ).current;

  const saveRiskProfile = async (values: RiskProfileFormType) => {
    await saveRiskProfileApi(client.id, values);
  };

  useServerErrorNotify(error);

  return {
    saveRiskProfile,
    initialValues,
    submitLoading,
  };
};
