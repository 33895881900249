import { KYCStatus } from 'api/types/data';
import {
  ClientEntity,
  ClientOnboardingStatusType,
  OnboardingStatus,
} from 'api/types/entity';

import { isGlobalProject } from '../../../../config';
import { assertNever } from '../../../../helpers';

export const useClientOnboardingStatus = (
  client: ClientEntity | null,
  type: 'client' | 'clientWithRepresentative'
): OnboardingStatus | undefined => {
  const onboardingStatus = client?.onboardingStatus;
  const kycStatusIsError = client?.kyc?.status === KYCStatus.error;

  if (kycStatusIsError) return 'rejected';

  switch (onboardingStatus) {
    case ClientOnboardingStatusType.AccountOpened:
      return 'success';

    case ClientOnboardingStatusType.AccountOpening:
      if (isGlobalProject()) {
        return 'openingProcess';
      }
      return 'inProcess';

    case ClientOnboardingStatusType.AwaitingAccountOpening:
    case ClientOnboardingStatusType.AwaitingValidation:
    case ClientOnboardingStatusType.BoardReview:
    case ClientOnboardingStatusType.AmlCftCheck:
    case ClientOnboardingStatusType.AwaitingAmlCftCheck:
    case ClientOnboardingStatusType.AwaitingComplianceCheck:
    case ClientOnboardingStatusType.ValidationCheck:
    case ClientOnboardingStatusType.ComplianceCheck:
    case ClientOnboardingStatusType.PendingBoardReview:
      return 'inProcess';

    case ClientOnboardingStatusType.SigningDocuments:
    case ClientOnboardingStatusType.SigningQuestionnaire:
      if (type === 'clientWithRepresentative') {
        return 'inProcess';
      }
      return undefined;

    case ClientOnboardingStatusType.AccountOpeningDenied:
      return 'rejected';

    case ClientOnboardingStatusType.FillingOut:
      return undefined;

    case ClientOnboardingStatusType.AwaitingPaymentConfirmation:
      return 'awaitingPayment';

    default:
      if (onboardingStatus) {
        return assertNever('Unexpected onboardingStatus', onboardingStatus);
      }
      return undefined;
  }
};
