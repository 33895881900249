import { useTranslation } from 'libs/i18n';

import {
  KnowledgeAndExperienceFormType,
  FormErrors,
  getSchema,
} from '../../forms/knowledgeAndExperienceForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: KnowledgeAndExperienceFormType;
  onSubmit: (values: KnowledgeAndExperienceFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
  subTitle?: string;
}

export const KnowledgeAndExperience = ({
  initialValues,
  canGoBack,
  submitLoading,
  formError,
  onGoBack,
  onSubmit,
  clientType,
  subTitle,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.knowledgeAndExperience.subtitle')}
      title={t('onboarding.knowledgeAndExperience.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent />
    </FormBodyWrapper>
  );
};
