import { RegistrationUSTaxStatus } from '../components/RegistrationUSTaxStatus/RegistrationUSTaxStatus';
import { useRegistrationUSTaxStatus } from '../hooks';
import { RegistrationStepProps } from '../types';

export const RegistrationUSTaxStatusStep = ({
  canGoBack,
  onGoBack,
  onGoNext,
  onRejected,
}: RegistrationStepProps) => {
  const { onSubmit } = useRegistrationUSTaxStatus({
    onGoNext,
    onRejected,
  });

  return (
    <RegistrationUSTaxStatus
      canGoBack={canGoBack}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    />
  );
};
