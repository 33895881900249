import { formatPrice, parsePrice } from 'helpers';
import { InputProps, NumberInput } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface TextFieldProps
  extends FieldType,
    Omit<InputProps, 'name' | 'onChange' | 'onChangeText' | 'value'> {}

/**
 * Amount field.
 */
export const AmountField = ({
  name,
  allowSubmit,
  ...props
}: TextFieldProps) => {
  const { field, helpers, inputProps } = useField<number | undefined>(name);

  const isError = inputProps.error;
  const { helperText } = inputProps;

  return (
    <NumberInput
      {...props}
      fullWidth
      error={isError}
      format={formatPrice}
      helperText={helperText}
      name={name}
      parse={parsePrice}
      value={field.value}
      onBlur={field.onBlur(name)}
      onChange={() => {
        helpers.setError('');
      }}
      onChangeText={(v) => {
        helpers.setValue(v);
      }}
      onClear={() => helpers.setValue(undefined)}
    />
  );
};
