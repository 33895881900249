// eslint-disable-next-line no-restricted-imports
import { OnboardingClientFragment } from 'api/generated/graphql';
import { ClientEntity, RiskProfileType } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseBankAccounts } from './parseBankAccounts';
import { parseBiographyEntity } from './parseBiographyEntity';
import { parseBusinessRelationshipEntity } from './parseBusinessRelationshipEntity';
import { parseDebtsEntity } from './parseClientDebtsEntity';
import { parseClientOnboardingStatus } from './parseClientOnboardingStatus';
import { parseCountryEntity } from './parseCountryEntity';
import { parseFileEntity } from './parseFileEntity';
import { parseFinancialPositionEntity } from './parseFinancialPositionEntity';
import { parseFinancingSourcesEntity } from './parseFinancingSourcesEntity';
import { parseInvoice } from './parseInvoice';
import { parseInvoiceCurrency } from './parseInvoiceCurrency';
import { parseKnowledgeAndExperienceEntity } from './parseKnowledgeAndExperienceEntity';
import { parseKyc } from './parseKyc';
import { parseOnboardingProcessEntity } from './parseOnboardingProcessEntity';
import { parseOrderSubmissionContactsEntity } from './parseOrderSubmissionContactsEntity';
import { parsePepConnectionEntity } from './parsePepConnectionEntity';
import { parseResidentialAddressEntity } from './parseResidentialAddressEntity';
import { parseSourcesOfIncomeEntity } from './parseSourcesOfIncomeEntity';
import { parseTaxResidenceEntity } from './parseTaxResidenceEntity';
import { parseW8BENFormEntity } from './parseW8BENFormEntity';
import { parseWithdrawalAccountsEntity } from './parseWithdrawalAccountsEntity';

export const parseClientEntity = (
  data: OnboardingClientFragment
): ClientEntity => ({
  id: data.id,
  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  deletedAt: data.deletedAt,

  placeOfBirth: data.placeOfBirth,
  registrationAddress: data.registrationAddress,
  residentialAddress: parseResidentialAddressEntity(data.residentialAddress),

  biography: data.biography ? parseBiographyEntity(data.biography) : undefined,

  onboarding:
    data.representative?.onboarding || data.onboarding
      ? {
          process: parseOnboardingProcessEntity(
            data.representative?.onboarding?.process ??
              data.onboarding?.process!
          ),
        }
      : undefined,

  bankAccounts: parseBankAccounts(data.bankAccounts),
  financingSources: data.financingSources
    ? parseFinancingSourcesEntity(data.financingSources)
    : undefined,

  sourcesOfIncome: data.sourcesOfIncome
    ? parseSourcesOfIncomeEntity(data.sourcesOfIncome)
    : undefined,

  pepConnection: parsePepConnectionEntity(data),

  debts: parseDebtsEntity(data.debts),

  withdrawalAccounts: parseWithdrawalAccountsEntity(data.withdrawalAccounts),

  signaturesCardFiles: data.signaturesCardFiles
    ? lodash.compact(data.signaturesCardFiles).map(parseFileEntity)
    : undefined,

  isDataAccuracyConfirmed: data.isDataAccuracyConfirmed ?? false,

  orderSubmissionContacts: parseOrderSubmissionContactsEntity(
    data.orderSubmissionContacts
  ),

  riskProfile: data.riskProfile ? RiskProfileType[data.riskProfile] : undefined,

  kyc: parseKyc(data.kyc),

  onboardingStatus: parseClientOnboardingStatus(data.onboardingStatus),

  businessRelationship: data.businessRelationship
    ? parseBusinessRelationshipEntity(data.businessRelationship)
    : undefined,

  financialPosition: data.financialPosition
    ? parseFinancialPositionEntity(data.financialPosition)
    : undefined,
  W8BENForm: data.W8BENForm ? parseW8BENFormEntity(data.W8BENForm) : undefined,

  knowledgeAndExperience: data.knowledgeAndExperience
    ? parseKnowledgeAndExperienceEntity(data.knowledgeAndExperience)
    : undefined,

  invoice: data.invoice ? parseInvoice(data.invoice) : undefined,

  invoiceCurrency: data.invoiceCurrency
    ? parseInvoiceCurrency(data.invoiceCurrency)
    : undefined,
});
