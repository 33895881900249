import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  Field,
  KnowledgeAndExperienceFormType,
  useFormContext,
  yearOfFirstInvestmentYears,
} from 'modules/onboarding/common/forms/knowledgeAndExperienceForm';

interface Props {
  type: keyof KnowledgeAndExperienceFormType['financialInstruments'];
}

const Section = ({ type }: Props) => {
  const { t } = useTranslation();

  const { values } = useFormContext();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={16}>
        <Typography variant="subtitle1" variantMobile="captionBold">
          {t(
            `onboarding.knowledgeAndExperience.financialInstruments.${type}.title`
          )}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={16}
        >
          <Typography
            variant="bodyMedium"
            variantMobile="caption"
            whiteSpace="nowrap"
          >
            {t(
              'onboarding.knowledgeAndExperience.financialInstruments.switchLabel'
            )}
          </Typography>
          <Field.Switch name={`financialInstruments.${type}.enabled`} />
        </Stack>
      </Stack>

      {values.financialInstruments?.[type]?.enabled && (
        <>
          <Field.Autocomplete
            label={t(
              `onboarding.knowledgeAndExperience.financialInstruments.yearOfFirstInvestmentField.label`
            )}
            name={`financialInstruments.${type}.yearOfFirstInvestment`}
            optionLabelKey="label"
            options={yearOfFirstInvestmentYears.map((v) => ({
              label: v.toString(),
              value: v,
            }))}
            optionValueKey="value"
            placeholder={t(
              `onboarding.knowledgeAndExperience.financialInstruments.yearOfFirstInvestmentField.placeholder`
            )}
            variant="select"
          />
          <Field.Amount
            fullWidth
            label={t(
              `onboarding.knowledgeAndExperience.financialInstruments.investmentAmountField.label`
            )}
            name={`financialInstruments.${type}.investmentAmount`}
            placeholder={t(
              `onboarding.knowledgeAndExperience.financialInstruments.investmentAmountField.placeholder`
            )}
          />
          <Field.Amount
            fullWidth
            label={t(
              `onboarding.knowledgeAndExperience.financialInstruments.transactionCountField.label`
            )}
            name={`financialInstruments.${type}.transactionCount`}
            placeholder={t(
              `onboarding.knowledgeAndExperience.financialInstruments.transactionCountField.placeholder`
            )}
          />
        </>
      )}
    </>
  );
};

export const FinancialInstrumentsFields = () => (
  <>
    <Section type="stocks" />
    <Section type="bonds" />
    <Section type="mutualFunds" />
    <Section type="structuredProducts" />
    <Section type="derivatives" />
  </>
);
