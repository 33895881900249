import { isGlobalProject } from 'config';
import { useTranslation } from 'libs/i18n';

import { RegistrationPersonalData } from '../components/RegistrationPersonalData';
import { useRegistrationContext } from '../components/RegistrationProvider';
import { RegistrationPersonalDataFormType } from '../forms/registrationPersonalDataForm';
import { RegistrationStepProps, RegistrationType } from '../types';

export const RegistrationPersonalDataStep = ({
  canGoBack,
  onGoBack,
  onGoNext,
}: RegistrationStepProps) => {
  const { t } = useTranslation();

  const {
    changeContextData,
    registrationType,
    contextData: {
      firstname,
      lastname,
      surname,
      dateOfBirth,
      representative,
      gender,
    },
  } = useRegistrationContext();

  const onSubmit = async (values: RegistrationPersonalDataFormType) => {
    changeContextData({ ...values, gender: values.gender?.value });
    onGoNext();
  };

  const isGlobal = isGlobalProject();

  return (
    <RegistrationPersonalData
      canGoBack={canGoBack}
      initialValues={{
        firstname,
        lastname,
        surname,
        dateOfBirth,
        representative,
        gender:
          isGlobal && gender
            ? {
                label: t(`auth.registration.personalData.gender.${gender}`),
                value: gender,
              }
            : undefined,
      }}
      shownGender={isGlobal}
      shownRepresentativeSwitch={registrationType !== RegistrationType.legal}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    />
  );
};
