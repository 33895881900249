import { InvoiceConfirmForPaymentFormType } from '../../common/forms/invoiceConfirmForPaymentForm';
import { useClientInvoiceConfirmForPayment } from '../../common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerInvoiceConfirmForPayment = () => {
  const { data, goToBack, goToNext } = useOnboardingContext();
  const {
    initialValues,
    saveClientInvoiceConfirmForPayment,
    submitLoading,
    files,
    invoiceLink,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  } = useClientInvoiceConfirmForPayment(data.ownerClient!);

  const onSubmit = async (values: InvoiceConfirmForPaymentFormType) => {
    await saveClientInvoiceConfirmForPayment(values);
    goToNext();
  };

  return {
    onSubmit,
    files,
    invoiceLink,
    onRemoveFile,
    onAddFiles,
    goToBack,
    submitDisabled,
    initialValues,
    submitLoading,
  };
};
