import { KYCStatus } from 'api/types/data';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
  LegalClientOnboardingStatusType,
  OnboardingStatus,
} from 'api/types/entity';
import { assertNever } from 'helpers';

export const useLegalClientOnboardingStatus = (
  legalClient?: LegalClientEntity | null,
  currentClient?: LegalClientRepresentativeEntity | null
): OnboardingStatus | undefined => {
  const onboardingStatus = legalClient?.status;
  const kycStatusIsError = currentClient?.kyc?.status === KYCStatus.error;

  if (kycStatusIsError) return 'rejected';

  switch (onboardingStatus) {
    case LegalClientOnboardingStatusType.AccountOpened:
      return 'success';

    case LegalClientOnboardingStatusType.AccountOpening:
    case LegalClientOnboardingStatusType.AwaitingAccountOpening:
    case LegalClientOnboardingStatusType.AwaitingValidation:
    case LegalClientOnboardingStatusType.BoardReview:
    case LegalClientOnboardingStatusType.AmlCftCheck:
    case LegalClientOnboardingStatusType.AwaitingAmlCftCheck:
    case LegalClientOnboardingStatusType.AwaitingComplianceCheck:
    case LegalClientOnboardingStatusType.ValidationCheck:
    case LegalClientOnboardingStatusType.ComplianceCheck:
    case LegalClientOnboardingStatusType.PendingBoardReview:
    case LegalClientOnboardingStatusType.SigningDocuments:
      return 'inProcess';

    case LegalClientOnboardingStatusType.SigningQuestionnaire:
      return undefined;

    case LegalClientOnboardingStatusType.AccountOpeningDenied:
      return 'rejected';

    case LegalClientOnboardingStatusType.AwaitingPaymentConfirmation:
      return 'awaitingPayment';

    case LegalClientOnboardingStatusType.FillingOut:
      return undefined;

    default:
      if (onboardingStatus) {
        return assertNever('Unexpected onboardingStatus', onboardingStatus);
      }
      return undefined;
  }
};
