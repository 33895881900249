import { SaveW8BENFormParam } from 'api/types/params';
import { isYes } from 'helpers';

import { W8BenFormFormType } from '../../forms/w8BenFormForm';

export const getSubmitData = (
  values: W8BenFormFormType
): SaveW8BENFormParam => {
  const isUSSecuritiesInvestmentPlanned = isYes(
    values.isUSSecuritiesInvestmentPlanned!
  );
  return {
    isUSSecuritiesInvestmentPlanned,
    fileIds: isUSSecuritiesInvestmentPlanned ? values.fileIds! : [],
  };
};
