import { useCallback, useState } from 'react';

import {
  useClientCompleteOnboarding,
  useClientConfirmDataAccuracy,
  useOnboardingClientLazy,
} from 'api/requests';

import { isFinanceProject } from '../../../../config';

export const useClientConfirmationData = (clientId: string) => {
  const { confirmData, error } = useClientConfirmDataAccuracy();
  const { completeOnboarding } = useClientCompleteOnboarding();
  const { getClient } = useOnboardingClientLazy();

  const [loading, setLoading] = useState(false);

  const confirm = useCallback(async () => {
    setLoading(true);
    try {
      await confirmData(clientId);
      if (isFinanceProject()) {
        await completeOnboarding(clientId);
      }
      await getClient(clientId);
    } catch (error) {
      setLoading(false);
      throw error;
    }

    setLoading(false);
  }, [clientId, completeOnboarding, confirmData, getClient]);

  return { confirm, loading, error };
};
