// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientController_SavePep_Input_PepConnectionType as PepConnectionTypeApi1,
  MutationInput_ClientRepresentativeController_SavePep_Input_PepConnectionType as PepConnectionTypeApi2,
  SaveClientPepDto_Input,
  SavePepDto_Input,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { SavePepConnectionParam } from 'api/types/params';

type PepConnectionTypeApi = PepConnectionTypeApi1 | PepConnectionTypeApi2;

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi1.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi1.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi1.Self,
};

export const parsePepConnectionParam = (
  params: SavePepConnectionParam
): SavePepDto_Input => ({
  connectedPepInfo: params.connectedPepInfo,
  pepConnectionType: relationType[params.relation] as PepConnectionTypeApi2,
});

export const parseClientPepConnectionParam = (
  params: SavePepConnectionParam
): SaveClientPepDto_Input => ({
  connectedPepInfo: params.connectedPepInfo,
  pepConnectionType: relationType[params.relation] as PepConnectionTypeApi1,
});
