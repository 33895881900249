import { useRef } from 'react';

import { useLegalClientRepresentativeSaveCompanyAddresses } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyAddressFormType } from '../../forms/companyAddressForm';
import {
  getInitialData,
  getSubmitData,
} from '../../helpers/companyRegistrationAddress';
import { useOnboardingContext } from '../useOnboardingContext';

export const useLegalRepresentativeCompanyRegistrationAddress = () => {
  const {
    error,
    loading: submitLoading,
    saveAddresses,
  } = useLegalClientRepresentativeSaveCompanyAddresses();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.currentClient.company!)?.values
  ).current;

  const onSubmit = async (values: CompanyAddressFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveAddresses(data.currentClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    initialValues,
    countries,
    countriesLoading,
    submitLoading,
    goToBack,
  };
};
