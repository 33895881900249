import {
  BusinessRelationshipGoalsType,
  BusinessRelationshipInvestmentGoalType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  useFormContext,
  Field,
} from 'modules/onboarding/common/forms/businessRelationshipForm';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isOtherBusinessRelationshipGoal =
    values.businessRelationshipGoals?.find(
      (v) => v?.value === BusinessRelationshipGoalsType.Other
    ) !== undefined;

  const isOtherInvestmentGoal =
    values.investmentGoals?.find(
      (v) => v?.value === BusinessRelationshipInvestmentGoalType.Other
    ) !== undefined;

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Autocomplete
        multiple
        label={t(
          'onboarding.businessRelationship.businessRelationshipGoals.label'
        )}
        name="businessRelationshipGoals"
        optionLabelKey="label"
        options={Object.values(BusinessRelationshipGoalsType).map((v) => ({
          label: t(
            `onboarding.businessRelationship.businessRelationshipGoals.types.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'onboarding.businessRelationship.businessRelationshipGoals.placeholder'
        )}
        variant="select"
      />

      {isOtherBusinessRelationshipGoal && (
        <Field.Text
          label={t(
            'onboarding.businessRelationship.otherBusinessRelationshipGoal.label'
          )}
          name="otherBusinessRelationshipGoal"
          placeholder={t(
            'onboarding.businessRelationship.otherBusinessRelationshipGoal.placeholder'
          )}
        />
      )}

      <Field.Autocomplete
        multiple
        label={t('onboarding.businessRelationship.investmentGoals.label')}
        name="investmentGoals"
        optionLabelKey="label"
        options={Object.values(BusinessRelationshipInvestmentGoalType).map(
          (v) => ({
            label: t(
              `onboarding.businessRelationship.investmentGoals.types.${v}`
            ),
            value: v,
          })
        )}
        optionValueKey="value"
        placeholder={t(
          'onboarding.businessRelationship.investmentGoals.placeholder'
        )}
        variant="select"
      />

      {isOtherInvestmentGoal && (
        <Field.Text
          label={t('onboarding.businessRelationship.otherInvestmentGoal.label')}
          name="otherInvestmentGoal"
          placeholder={t(
            'onboarding.businessRelationship.otherInvestmentGoal.placeholder'
          )}
        />
      )}
    </Stack>
  );
};
