import { useEffect, useRef, useState } from 'react';

import {
  useFileDownload,
  useLegalClientGetQuestionnaireFile,
  useLegalClientSaveSignedQuestionnaireFiles,
} from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { FileUpload } from 'hooks/useFilesUpload';

import { SigningQuestionnaireFormType } from '../forms/signingQuestionnaireForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useSigningQuestionnaire = () => {
  const { getFileLink } = useFileDownload();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const { questionnaireFile } = useLegalClientGetQuestionnaireFile(
    data.legalClient?.id!
  );

  const {
    error,
    loading: submitLoading,
    saveSignedQuestionnaireFiles,
  } = useLegalClientSaveSignedQuestionnaireFiles();

  const [questionnaireFileHref, setDuestionnaireDocumentHref] = useState<
    string | null
  >(null);

  const initialFiles = useRef(
    data.legalClient?.signedQuestionnaireFiles?.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    )
  ).current;

  const initialValues: SigningQuestionnaireFormType = useRef({
    fileIds: data.legalClient?.signedQuestionnaireFiles?.map((v) => v.id) ?? [],
  }).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialFiles);

  const onSubmit = async (values: SigningQuestionnaireFormType) => {
    await saveSignedQuestionnaireFiles(data.legalClient?.id!, values.fileIds);
    goToNext();
  };

  useEffect(() => {
    if (questionnaireFile?.id) {
      getFileLink(questionnaireFile.id).then((link) => {
        if (link) {
          setDuestionnaireDocumentHref(link);
        }
      });
    }
  }, [getFileLink, questionnaireFile?.id]);

  useServerErrorNotify(error);

  return {
    initialValues,
    files,
    submitLoading,
    submitDisabled,
    questionnaireFileHref,
    onSubmit,
    goToBack,
    onAddFiles,
    onRemoveFile,
  };
};
