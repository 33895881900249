import {
  LegalClientOnboardingStatusType,
  OnboardingProcessStepNameType,
} from 'api/types/entity';
import { LoadingLayout } from 'components';
import { config } from 'config';
import { useInterval } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';
import {
  OnboardingLayout,
  OnboardingStatus,
} from 'modules/onboarding/common/components';
import { STATUS_UPDATE_INTERVAL } from 'modules/onboarding/common/constants';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { WaitingParticipantsKyc } from '../components/WaitingParticipantsKyc';
import { getInnerComponent } from '../helpers/getInnerComponent';
import { useLegalClientOnboardingStatus } from '../hooks';
import { useOnboarding } from '../hooks/useOnboarding';
import { useParticipantsWaitingKyc } from '../hooks/useParticipantsWaitingKyc';
import { pagesMap } from '../route';

interface Props {
  clientId: string;
}

export const LegalContainer = ({ clientId }: Props) => {
  const { t } = useTranslation();

  usePageTitle(t('onboarding.pageTitle'));

  const {
    currentClient,
    legalClient,
    loading,
    innerState,
    process,
    refetchCurrentClient,
    refetchLegalClient,
    onGoBack,
  } = useOnboarding(clientId);

  const {
    isWaitingKyc,
    users,
    formErrorSaveEmail,
    loadingSaveEmail,
    onEditEmail,
    onResendCode,
    getLegalClientParticipants,
  } = useParticipantsWaitingKyc(currentClient, legalClient);

  const onboardingStatus = useLegalClientOnboardingStatus(
    legalClient,
    currentClient
  );

  useInterval(
    () => {
      refetchCurrentClient();
      refetchLegalClient();
      if (legalClient && isWaitingKyc) {
        getLegalClientParticipants(legalClient.id);
      }
    },
    onboardingStatus || isWaitingKyc ? STATUS_UPDATE_INTERVAL : null
  );

  if (!currentClient || loading) {
    return <LoadingLayout />;
  }

  if (onboardingStatus) {
    return <OnboardingStatus onboardingStatus={onboardingStatus} />;
  }

  if (isWaitingKyc) {
    return (
      <WaitingParticipantsKyc
        email={config.EMAIL_ONBOARDING ?? ''}
        initialEmailEditErrors={formErrorSaveEmail}
        loadingEmailEdit={loadingSaveEmail}
        users={users}
        onEditEmail={onEditEmail}
        onResend={onResendCode}
      />
    );
  }

  if (
    process?.currentStep.name ===
      OnboardingProcessStepNameType.LegalClientSigningQuestionnaire &&
    legalClient?.status !== LegalClientOnboardingStatusType.SigningQuestionnaire
  ) {
    return <LoadingLayout />;
  }

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={{ currentClient, legalClient, innerState }}
      pagesMap={pagesMap}
      process={process!}
      renderChild={({ route }) => {
        const InnerComponent = getInnerComponent({
          currentClient,
          legalClient,
          innerState,
          currentPageName: route.pageKey as OnboardingProcessStepNameType,
        });

        if (InnerComponent) {
          return InnerComponent;
        }
        const Component = route.page.component;
        return (
          <OnboardingLayout
            key={route.pageKey}
            countSteps={process?.totalStepCount}
            currentStepIndex={route.pageNumber}
          >
            <Component {...route.page.componentProps} />
          </OnboardingLayout>
        );
      }}
      onGoBack={onGoBack}
    />
  );
};
