import { useRef } from 'react';

import { useClientSaveInvoice } from '../../../../api/requests';
import { ClientEntity } from '../../../../api/types/entity';
import { useServerErrorNotify } from '../../../../hooks';
import { InvoiceForPaymentFormType } from '../forms/invoiceForPayment';

export const useClientInvoiceForPayment = (client: ClientEntity) => {
  const { saveInvoice, error, loading: submitLoading } = useClientSaveInvoice();

  const initialValues = useRef<InvoiceForPaymentFormType>({
    currency: client?.invoiceCurrency
      ? {
          label: client.invoiceCurrency,
          value: client.invoiceCurrency,
        }
      : null,
  }).current;

  const saveClientInvoice = async (values: InvoiceForPaymentFormType) => {
    if (values.currency) {
      await saveInvoice(client!.id, {
        currency: values.currency.value,
      });
    }
  };

  useServerErrorNotify(error);

  return {
    saveClientInvoice,
    initialValues,
    submitLoading,
  };
};
