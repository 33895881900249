import {
  ClientEntity,
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { SourcesOfIncomeFormType } from '../../forms/sourcesOfIncomeForm';

export const SourcesOfIncomeSourcePath: Record<
  SourcesOfIncomeSourceType,
  TranslationPath
> = {
  [SourcesOfIncomeSourceType.Salary]:
    'onboarding.sourcesOfIncome.sourceType.Salary',
  [SourcesOfIncomeSourceType.Savings]:
    'onboarding.sourcesOfIncome.sourceType.Savings',
  [SourcesOfIncomeSourceType.Inheritance]:
    'onboarding.sourcesOfIncome.sourceType.Inheritance',
  [SourcesOfIncomeSourceType.BusinessActivity]:
    'onboarding.sourcesOfIncome.sourceType.BusinessActivity',
  [SourcesOfIncomeSourceType.Other]:
    'onboarding.sourcesOfIncome.sourceType.Other',
};

export const SourcesOfIncomeOccupationPath: Record<
  SourcesOfIncomeOccupationType,
  TranslationPath
> = {
  [SourcesOfIncomeOccupationType.Employee]:
    'onboarding.sourcesOfIncome.occupationType.Employee',
  [SourcesOfIncomeOccupationType.IndividualEntrepreneur]:
    'onboarding.sourcesOfIncome.occupationType.IndividualEntrepreneur',
  [SourcesOfIncomeOccupationType.BusinessOwner]:
    'onboarding.sourcesOfIncome.occupationType.BusinessOwner',
  [SourcesOfIncomeOccupationType.Student]:
    'onboarding.sourcesOfIncome.occupationType.Student',
  [SourcesOfIncomeOccupationType.Retiree]:
    'onboarding.sourcesOfIncome.occupationType.Retiree',
  [SourcesOfIncomeOccupationType.Unemployed]:
    'onboarding.sourcesOfIncome.occupationType.Unemployed',
  [SourcesOfIncomeOccupationType.Other]:
    'onboarding.sourcesOfIncome.occupationType.Other',
};

export const getInitialData = (client: ClientEntity, t: TFunc) => {
  const values: SourcesOfIncomeFormType | undefined = client.sourcesOfIncome
    ? {
        ...client.sourcesOfIncome,
        incomeSources: client.sourcesOfIncome.incomeSources?.map((v) => ({
          value: v,
          label: t(SourcesOfIncomeSourcePath[v]),
        })),
        occupation: {
          value: client.sourcesOfIncome.occupation,
          label: t(
            SourcesOfIncomeOccupationPath[client.sourcesOfIncome.occupation]
          ),
        },
      }
    : undefined;

  return { values };
};
